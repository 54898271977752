import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import {ROUTES} from '../../routesList';




import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './Dashboard';



import UsersList from './users/UsersList';
import UsersCreate from './users/UsersCreate';

import UserDetail from './users/UserDetail';

import RolesList from './roles/RolesList';
import RolesAdd from './roles/RolesAdd';


import ChangePassword from './ChangePassword';

import AddCity from './space_management/AddCity';
import CitiesListing from './space_management/CitiesListing';

import AddLocality from './space_management/AddLocality';
import LocalitiesListing from './space_management/LocalitiesListing';

import AddBuilding from './space_management/AddBuilding';
import BuildingsListing from './space_management/BuildingsListing';
import BuildingManagePictureGallery from './space_management/BuildingManagePictureGallery';
import BuildingManageAmenities from './space_management/BuildingManageAmenities';

import AddBlock from './space_management/AddBlock';
import BlocksListing from './space_management/BlocksListing';

import AddFlat from './space_management/AddFlat';
import EditFlat from './space_management/EditFlat';
import FlatsListing from './space_management/FlatsListing';

import RoomsListing from './space_management/RoomsListing';

import ManageFlatRooms from './space_management/ManageFlatRooms';

import BedsListing from './space_management/BedsListing';

import PlansListing from './plans/PlansListing';
import AddPlan from './plans/AddPlan';

import LocationWiseManagePlans from './plans/LocationWiseManagePlans';

import CallbackRequestsListing from './callback_requests/CallbackRequestsListing';

import NewsListing from './news/NewsListing';
import AddNews from './news/AddNews';

import EventsListing from './events/EventsListing';
import AddEvent from './events/AddEvent';

import BookingsListing from './bookings/BookingsListing';
import BookingDetail from './bookings/BookingDetail';

import CustomersList from './customers/CustomersList';
import CustomerDetail from './customers/CustomerDetail';

import ReviewsListing from './reviews/ReviewsListing';
import EditReview from './reviews/EditReview';

import FlatTypesListing from './space_management/FlatTypesListing';
import AddFlatType from './space_management/AddFlatType';

import AmenitiesListing from './space_management/AmenitiesListing';
import AddAmenity from './space_management/AddAmenity';

import TaxListing from './taxes/TaxListing';
import AddTax from './taxes/AddTax';

import BannersListing from './banners/BannersListing';
import AddBanner from './banners/AddBanner';

import ComfortsListing from './comforts/ComfortsListing';
import AddComfort from './comforts/AddComfort';

import LandmarksListing from './space_management/LandmarksListing';
import AddLandmark from './space_management/AddLandmark';

import ManagePage from './pages/ManagePage';
import WebsiteWideSettings from './pages/WebsiteWideSettings';

import BedsReport from './reports/BedsReport';
import BookingsReport from './reports/BookingsReport';
import CustomersReport from './reports/CustomersReport';




import Logout from './Logout';



class Layout extends Component {
  constructor(props){
    super(props);
    this.state = {
      permissions: props.permissions,
      user: props.user
    }
  }

  componentWillMount() {
  }


	render() {
    return (
      <>
        <ToastContainer autoClose={5000} position = "bottom-center" hideProgressBar={true}/>
        <header>
	        <div className="container-fluid">
             <Header permissions = {this.state.permissions} />
             <LeftNavigation  permissions = {this.state.permissions}  user = {this.state.user} />
          </div>
        </header>
        <div id="wrapper">
          <div className="container-fluid">
            <Route exact path={ROUTES.INDEX.URL} component = {Dashboard}/>
            <Route exact path={ROUTES.DASHBOARD.URL} component = {Dashboard}/>
            <Route exact path={ROUTES.USERS_LIST.URL} component = {UsersList} />
            <Route exact path={ROUTES.ROLES_LIST.URL} component = {RolesList} />
            <Route exact path={ROUTES.ROLES_CREATE.URL} component = {RolesAdd} />
            <Route exact path={ROUTES.ROLES_EDIT.URL} component = {RolesAdd} />
            <Route exact path={ROUTES.USERS_CREATE.URL} component = {UsersCreate} />
            <Route exact path={ROUTES.USERS_EDIT.URL} component = {UsersCreate} />

            <Route exact path={ROUTES.CUSTOMERS_LIST.URL} component = {CustomersList} />
            <Route exact path={ROUTES.CUSTOMER_DETAIL.URL} component = {CustomerDetail} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_CITIES_ADD.URL} component = {AddCity} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_CITIES_EDIT.URL} component = {AddCity} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_CITIES_LISTING.URL} component = {CitiesListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_CITIES_MANAGE_PLANS.URL} component = {LocationWiseManagePlans} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_LOCALITIES_ADD.URL} component = {AddLocality} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_LOCALITIES_EDIT.URL} component = {AddLocality} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_LOCALITIES_LISTING.URL} component = {LocalitiesListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_LOCALITIES_MANAGE_PLANS.URL} component = {LocationWiseManagePlans} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_BUILDINGS_ADD.URL} component = {AddBuilding} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BUILDINGS_EDIT.URL} component = {AddBuilding} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BUILDINGS_LISTING.URL} component = {BuildingsListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BUILDINGS_MANAGE_PICTURE_GALLERY.URL} component = {BuildingManagePictureGallery} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BUILDINGS_MANAGE_AMENITIES.URL} component = {BuildingManageAmenities} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_BLOCKS_ADD.URL} component = {AddBlock} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BLOCKS_EDIT.URL} component = {AddBlock} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_BLOCKS_LISTING.URL} component = {BlocksListing} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLATS_ADD.URL} component = {AddFlat} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLATS_EDIT.URL} component = {EditFlat} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLATS_LISTING.URL} component = {FlatsListing} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLATS_MANAGE_ROOMS.URL} component = {ManageFlatRooms} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_ROOMS_LISTING.URL} component = {RoomsListing} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_BEDS_LISTING.URL} component = {BedsListing} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLAT_TYPES_LISTING.URL} component = {FlatTypesListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLAT_TYPES_ADD.URL} component = {AddFlatType} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_FLAT_TYPES_EDIT.URL} component = {AddFlatType} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_AMENITIES_LISTING.URL} component = {AmenitiesListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_AMENITIES_ADD.URL} component = {AddAmenity} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_AMENITIES_EDIT.URL} component = {AddAmenity} />

            <Route exact path={ROUTES.SPACE_MANAGEMENT_LANDMARKS_LISTING.URL} component = {LandmarksListing} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_LANDMARKS_ADD.URL} component = {AddLandmark} />
            <Route exact path={ROUTES.SPACE_MANAGEMENT_LANDMARKS_EDIT.URL} component = {AddLandmark} />

            <Route exact path={ROUTES.PLANS_LISTING_ADD.URL} component = {AddPlan} />
            <Route exact path={ROUTES.PLANS_LISTING_EDIT.URL} component = {AddPlan} />
            <Route exact path={ROUTES.PLANS_LISTING.URL} component = {PlansListing} />

            <Route exact path={ROUTES.CALLBACK_REQUESTS_LISTING.URL} component = {CallbackRequestsListing} />

            <Route exact path={ROUTES.NEWS_LISTING.URL} component = {NewsListing} />
            <Route exact path={ROUTES.NEWS_LISTING_ADD.URL} component = {AddNews} />
            <Route exact path={ROUTES.NEWS_LISTING_EDIT.URL} component = {AddNews} />

            <Route exact path={ROUTES.EVENTS_LISTING.URL} component = {EventsListing} />
            <Route exact path={ROUTES.EVENTS_LISTING_ADD.URL} component = {AddEvent} />
            <Route exact path={ROUTES.EVENTS_LISTING_EDIT.URL} component = {AddEvent} />

            <Route exact path={ROUTES.BOOKINGS_LISTING.URL} component = {BookingsListing} />
            <Route exact path={ROUTES.BOOKING_DETAIL.URL} component = {BookingDetail} />


            <Route exact path={ROUTES.REVIEWS_LISTING.URL} component = {ReviewsListing} />
            <Route exact path={ROUTES.REVIEWS_LISTING_EDIT.URL} component = {EditReview} />

            <Route exact path={ROUTES.SETTINGS_TAXES_LISTING.URL} component = {TaxListing} />
            <Route exact path={ROUTES.SETTINGS_TAXES_LISTING_ADD.URL} component = {AddTax} />
            <Route exact path={ROUTES.SETTINGS_TAXES_LISTING_EDIT.URL} component = {AddTax} />

            <Route exact path={ROUTES.SETTINGS_BANNERS_LISTING.URL} component = {BannersListing} />
            <Route exact path={ROUTES.SETTINGS_BANNERS_LISTING_ADD.URL} component = {AddBanner} />
            <Route exact path={ROUTES.SETTINGS_BANNERS_LISTING_EDIT.URL} component = {AddBanner} />

            <Route exact path={ROUTES.SETTINGS_COMFORTS_LISTING.URL} component = {ComfortsListing} />
            <Route exact path={ROUTES.SETTINGS_COMFORTS_LISTING_ADD.URL} component = {AddComfort} />
            <Route exact path={ROUTES.SETTINGS_COMFORTS_LISTING_EDIT.URL} component = {AddComfort} />

            <Route exact path={ROUTES.SETTINGS_STATIC_PAGES.URL} component = {ManagePage} />
            <Route exact path={ROUTES.SETTINGS_WEBSITE_WIDE_SETTINGS.URL} component = {WebsiteWideSettings} />

            <Route exact path={ROUTES.REPORTS_BEDS_LIST.URL} component = {BedsReport} />
            <Route exact path={ROUTES.REPORTS_BOOKINGS_LIST.URL} component = {BookingsReport} />
            <Route exact path={ROUTES.REPORTS_CUSTOMERS_LIST.URL} component = {CustomersReport} />

            <Route  path={ROUTES.CHANGE_PASSWORD.URL} component={ChangePassword} />
            <Route  path={ROUTES.LOGOUT.URL} component={Logout} />
          </div>
        </div>
      </>
    );
	}
}
export default Layout;
