import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {normalizeAmountField} from '../../../constants';
import {addNews, fetchNewsById} from '../../../actions/news';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {uploadFile} from '../../../actions/common';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.title || values.title.trim() === '') {
    errors.title = "Enter news title";
    hasErrors = true;
  }
  if (!values.source || values.source.trim() === '') {
    errors.source = "Enter news source";
    hasErrors = true;
  }
  if (!values.url || values.url.trim() === '') {
    errors.url = "Enter news link";
    hasErrors = true;
  }
  if (!values.imageId || values.imageId === '') {
    errors.imageId = "Upload image";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class AddNews extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.newsId,
      newsDetail: null,
      arrayLocalImages: [],
    }
    this.onDrop = this.onDrop.bind(this);
  }

  submitForm(values, dispatch, props){

    return this.props.addNews(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.id){
      this.fetchNewsById(this.state.id);
    }
  }



  fetchNewsById(newsId){
		this.props.fetchNewsById({id: newsId}).then((response) => {
      let newsDetail = response.value.data.data;
      this.props.initialize({
        id: newsDetail.id,
        title: newsDetail.title,
        imageId: newsDetail.imageId,
        source: newsDetail.source,
        url: newsDetail.url,
        imageId: newsDetail.image?newsDetail.image.id:null
      });
      let arrayLocalImages = [];
      if(newsDetail.image && newsDetail.image.id){
        arrayLocalImages.push(newsDetail.image);
      }

      this.setState({newsDetail, arrayLocalImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 2097152){ //2097152
        alert("Max file size allowed is 2MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:"NEWS_IMAGE"}).then((response) => {
        this.setState({imageUploading: false, arrayLocalImages: []});
        var payload = response.value.data.data;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					this.props.change('imageId', payload.id);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/news/list">News</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} News</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-12">
                <Field
                  name="title"
                  component={renderField}
                  type="text"
                  label="News title"
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  name="source"
                  component={renderField}
                  type="text"
                  label="News source"
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  name="url"
                  component={renderField}
                  type="text"
                  label="News link"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-12">
              <ImageUploader
								className={this.state.imageUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText='Add Image (48x48, ratio: 1x1 & less than 2MB)'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="imageId"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayLocalImages.length > 0 &&
                this.state.arrayLocalImages.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                      <figure className="img"><img src={obj.original} alt=""/></figure>
  									</div>
                  )
                })
              }
            </div>


            </div>

            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNews: (data) => {
      return dispatch(addNews(data));
    },
    fetchNewsById: (data) => {
      return dispatch(fetchNewsById(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    }
  }
}

AddNews = connect(null, mapDispatchToProps)(AddNews);

AddNews = reduxForm({
  form: 'AddNews',
  validate,
  //enableReinitialize: true
})(AddNews);

export default AddNews;
