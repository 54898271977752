import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_PLANS = "FETCH_PLANS";
export const FETCH_PLAN = 'FETCH_PLAN';
export const ADD_PLAN = 'ADD_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const FETCH_CITY_PLANS = 'FETCH_CITY_PLANS';

export const FETCH_LOCALITY_PLANS = 'FETCH_LOCALITY_PLANS';

export const ADD_CITY_PLAN = 'ADD_CITY_PLAN';
export const ADD_LOCALITY_PLAN = 'ADD_LOCALITY_PLAN';

export function fetchPlans(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getPlans`
  });

  return {
    type: FETCH_PLANS,
    payload: request
  };
}
export function fetchPlan(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getPlanById`
  });

  return {
    type: FETCH_PLAN,
    payload: request
  };
}

export function addPlan(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addPlan`
  });
  return {
    type: ADD_PLAN,
    payload: request
  };
}

export function deletePlan(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deletePlan`
  });
  return {
    type: DELETE_PLAN,
    payload: request
  };
}

export function fetchCityPlans(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCityPlans`
  });
  return {
    type: FETCH_CITY_PLANS,
    payload: request
  };
}

export function fetchLocalityPlans(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalityPlans`
  });
  return {
    type: FETCH_LOCALITY_PLANS,
    payload: request
  };
}

export function addCityPlans(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addCityPlan`
  });
  return {
    type: ADD_CITY_PLAN,
    payload: request
  };
}

export function addLocalityPlans(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addLocalityPlan`
  });
  return {
    type: ADD_LOCALITY_PLAN,
    payload: request
  };
}
