import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchBlocks, deleteBlock, fetchBlockTypes} from '../../../actions/space_management';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Confirm from 'react-confirm-bootstrap';
import $ from 'jquery';

class BlocksListing extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/space-management/blocks/list',
			records: null,
			roles: [],
			blockTypes: [],
			filters: [
				'buildingName',
				'localityName',
				'cityName',
				'buildingBlockName',
				'buildingId',
				'buildingBlockId',
				'blockTypeId'
			]
		};
  }

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		//this.props.initialize(this.state.params);
		this.fetchBlocks(this.state.params);
		this.updateFilterForm(this.state.params);
		this.fetchBlockTypes();
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				if(obj == 'blockTypeId'){
					formValues[obj] = parseInt(params[obj]);
				}else{
					formValues[obj] = params[obj];
				}

			}
		});
		this.props.initialize(formValues);
	}

	fetchBlockTypes(){
		this.props.fetchBlockTypes({isAll: 1}).then((response) => {
      let blockTypes = response.value.data.data.blockTypes;
      this.setState({blockTypes});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



	fetchBlocks(params){
		params = Object.assign(params, {getAll: 0});
		this.setState({fetchingRecords: true});
    this.props.fetchBlocks(params).then((response) => {
      var records = response.value.data.data;
      this.setState({records, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchBlocks(params);
		}
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){

		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}



	deleteRecord(recordid){
    this.props.deleteBlock({id: recordid}).then((response) => {
			toast.success(response.value.data.message);
      this.fetchBlocks(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
			<>

        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/space-management/blocks/list?pageNumber=1">Blocks</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Blocks</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
							<Link to="/admin/space-management/blocks/list/add" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Block</Link>
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingName"
										component={renderField}
										label="Building"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="localityName"
										component={renderField}
										label="Locality"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="cityName"
										component={renderField}
										label="City"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingBlockName"
										component={renderField}
										label="Block"
									/>
								</div>

								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingId"
										component={renderField}
										label="Building ID"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingBlockId"
										component={renderField}
										label="Block ID"
									/>
								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Block Type"
		                name='blockTypeId'
		                optionLabel='name'
		                optionValue='id'
		                options={this.state.blockTypes}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>


							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div class="custom-table mt-0">
					<div class="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table class="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Block</th>
									<th>Floors</th>
									<th>Type</th>
									<th>Building</th>
									<th>Locality</th>
									<th>City</th>
									<th>Country</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.buildingBlocks && records.buildingBlocks.length > 0 &&
							records.buildingBlocks.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.name}</td>
										<td>{obj.floors}</td>
										<td>{obj.blockType.name}</td>
										<td>{obj.building.name}</td>
										<td>{obj.locality.name}</td>
										<td>{obj.city.name}</td>
										<td>{obj.country.name}</td>
                    <td>
											<span class="dropdown threeDots">
                        <a href="javascript://" class="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/space-management/blocks/list/edit/"+obj.id} >Edit</Link></li>
													<li><Confirm className="confirmPopup"
															onConfirm={() => this.deleteRecord(obj.id)}
															body="Are you sure you want to delete this?"
															confirmText="Confirm Delete"
															title="Delete!">
															<button className="dropdown-item">Delete</button>
													</Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
      {
				records && records.buildingBlocks && records.buildingBlocks.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

BlocksListing = reduxForm({
  form: 'BlocksListingFilter'
})(BlocksListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBlocks: (data) => {
      return dispatch(fetchBlocks(data));
    },
    deleteBlock: (data) => {
      return dispatch(deleteBlock(data));
    },
		fetchBlockTypes: (data) => {
      return dispatch(fetchBlockTypes(data));
    },
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BlocksListing));
