import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, formValueSelector, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import queryString from 'query-string';
import { fetchBuildingGalleryImages, addBuildingGalleryImages } from '../../../actions/space_management';
import { fetchPlans } from '../../../actions/plans';

import { uploadFile } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';


class BuildingManagePictureGallery extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		let buildingName = queryParams.buildingName || "";
		this.state = {
			buildingName: buildingName,
			id: props.match.params.buildingId,
			dataLoaded: false,
			campusImagesToBeUploadedCount: 0,
			campusImagesUploadedCount: 0,
			roomImagesToBeUploadedCount: 0,
			roomImagesUploadedCount: 0,
			plans: []
		}
		this.onDrop = this.onDrop.bind(this);
		//this.myRef = React.createRef();
		this.campusImageRef = React.createRef();
		this.roomImageRef = React.createRef();
		this.renderRoomImages = this.renderRoomImages.bind(this);
		this.renderCampusImages = this.renderCampusImages.bind(this);
	}

	submitForm(values, dispatch, props) {

		values = Object.assign(values, { buildingId: parseInt(this.state.id) });


		return this.props.addBuildingGalleryImages(values).then((response) => {
			toast.success(response.value.data.message);
			window.history.back();
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
				if (error.response.data.errors) {
					throw new SubmissionError(error.response.data.errors);
				}
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentDidMount() {
		this.fetchPlans();
		this.fetchBuildingGalleryImages(this.state.id);
	}

	fetchPlans(params) {
		this.props.fetchPlans({ isAll: 1 }).then((response) => {
			var plans = response.value.data.data && response.value.data.data.plans ? response.value.data.data.plans : [];
			this.setState({ plans });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}



	fetchBuildingGalleryImages(buildingId) {
		this.setState({ fetchingRecords: true });
		this.props.fetchBuildingGalleryImages({ buildingId }).then((response) => {
			let dataCampusImages = response.value.data.data.campusImages;
			let dataRoomImages = response.value.data.data.roomImages;
			let campusImages = [];
			let roomImages = [];
			dataCampusImages.forEach((member, memberIndex) => {
				campusImages.push(member);
			});
			dataRoomImages.forEach((member, memberIndex) => {
				roomImages.push(member);
			});
			this.setState({ fetchingRecords: false });
			this.props.change('campusImages', campusImages);
			this.props.change('roomImages', roomImages);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}



	componentWillReceiveProps(nextProps) {
	}

	onDrop(pictures, type) {
		var campusImages = this.props.campusImages || [];
		var roomImages = this.props.roomImages || [];
		var objThis = this;
		for (var i = 0; i < pictures.length; i++) {
			var form_data = new FormData();
			var file_data = pictures[i];
			if (file_data && file_data.size > 2097152) { //2097152
				alert("Max file size allowed is 2MB");
				return;
			}
			form_data.append("file", file_data);
			setTimeout(() => {
				if (type == "BUILDING_CAMPUS_IMAGE") {
					this.setState({ campusImagesToBeUploadedCount: this.state.campusImagesToBeUploadedCount + 1 });
				} else {
					this.setState({ roomImagesToBeUploadedCount: this.state.roomImagesToBeUploadedCount + 1 });
				}
			}, 100);

			this.props.uploadFile(form_data, { fileType: type }).then((response) => {
				var payload = response.value.data.data;
				//let arrayLocalCampusImages = this.state.arrayLocalCampusImages?this.state.arrayLocalCampusImages:[];
				//let arrayLocalRoomImages = this.state.arrayLocalRoomImages?this.state.arrayLocalRoomImages:[];
				if (payload && payload.id) {
					if (type == "BUILDING_CAMPUS_IMAGE") {
						campusImages.push(payload);
						this.setState({ campusImagesUploadedCount: this.state.campusImagesUploadedCount + 1 });
					} else {
						roomImages.push(payload);
						this.setState({ roomImagesUploadedCount: this.state.roomImagesUploadedCount + 1 });
					}
					this.props.change('campusImages', campusImages);
					this.props.change('roomImages', roomImages);
				}
			}).catch((error) => {
				if (type == "BUILDING_CAMPUS_IMAGE") {
					this.setState({ campusImagesUploadedCount: this.state.campusImagesUploadedCount + 1 });
				} else {
					this.setState({ roomImagesUploadedCount: this.state.roomImagesUploadedCount + 1 });
				}
				if (error.response) {
					toast.error(error.response.data.message);
				} else if (error.request) {
					toast.error("Network error!");
				} else {
					toast.error(error.message);
				}
			});

		}
		if (type == "BUILDING_CAMPUS_IMAGE") {
			this.campusImageRef.current.state.pictures = [];
			this.campusImageRef.current.state.files = [];
		} else {
			this.roomImageRef.current.state.pictures = [];
			this.roomImageRef.current.state.files = [];
		}

	}


	updateImageArray(e, index, type) {
		if (e) {
			e.preventDefault();
		}
		var arrayLocalCampusImages = this.state.arrayLocalCampusImages;
		var arrayLocalRoomImages = this.state.arrayLocalRoomImages;



		var campusImageIds = this.props.campusImageIds;
		var roomImageIds = this.props.roomImageIds;

		if (type == "BUILDING_CAMPUS_IMAGE") {
			arrayLocalCampusImages.splice(index, 1);
			campusImageIds.splice(index, 1);


		} else {
			arrayLocalRoomImages.splice(index, 1);
			roomImageIds.splice(index, 1);
		}

		this.setState({ arrayLocalCampusImages, arrayLocalRoomImages });
		this.props.change('campusImageIds', campusImageIds);
		this.props.change('roomImageIds', roomImageIds);

	}



	renderCampusImages({
		fields,
		meta: {
			touched,
			error,
			submitFailed
		}
	}) {
		var objThis = this;
		return (
			<ul className="image-gallery">
				{
					fields.map((member, index) => {
						var data = fields.get(index);
						return (
							<li>
								<figure className="img"><a href={data.original} target="_blank"><img src={data.original} alt="" /></a></figure>
								{/* <button className="close-btn" onClick={(e) => this.updateImageArray(e, index, "BUILDING_ROOM_IMAGE")}></button> */}
								<button type = "button" className="close-btn" onClick={(e) => fields.remove(index)}></button>
							</li>
						)
					}
					)
				}
			</ul>)
	}

	renderRoomImages({
		fields,
		meta: {
			touched,
			error,
			submitFailed
		}
	}) {
		var objThis = this;

		return (
			<ul className="image-gallery room-images">
				{
					fields.map((member, index) => {
						var data = fields.get(index);
						return (
							<li style={{ maxWidth: "33%" }}>
								<span>
									<figure className="img"><a href={data.original} target="_blank"><img src={data.original} alt="" /></a></figure>
									{/* <button className="close-btn" onClick={(e) => this.updateImageArray(e, index, "BUILDING_ROOM_IMAGE")}></button> */}
									<button type = "button" className="close-btn" onClick={(e) => fields.remove(index)}></button>
								</span>
								<Field
									name={`${member}.planId`}
									optionLabel='name'
									optionValue='id'
									options={this.state.plans}
									component={renderReactSelect}
									placeholder="Select.."
									multi={false}
								/>
								<Field
									name={`${member}.lable`}
									component={renderField}

								/>
							</li>
						)
					}
					)
				}
			</ul>)
	}

	render() {
		const { handleSubmit, pristine, reset, submitting, change } = this.props;
		return (
			<>
				<div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
				<ol className="breadcrumb breadcrumb-v1">
					<li className="breadcrumb-item"><Link to="/admin/space-management/buildings/list?pageNumber=1">Buildings</Link></li>
					<li className="breadcrumb-item active">Manage Image Gallery</li>
				</ol>
				<h5 className="h5 mb-4">Manage Image Gallery {this.state.buildingName ? "(" + this.state.buildingName + ")" : ""}</h5>
				<section className="formBg">
					<form onSubmit={handleSubmit(this.submitForm.bind(this))} >
						<div className="row">
							<div className=" col-lg-6">
								<div className="row">
									<div className=" col-lg-12">
										<ImageUploader
											className={(this.state.campusImagesUploadedCount != this.state.campusImagesToBeUploadedCount) ? "loading" : ""}
											accept=".jpg, .jpeg, .png, .gif"
											singleImage={false}
											label=""
											withIcon={false}
											buttonText='Upload campus images (1200x800, ratio: 3:2 & less than 2MB)'
											onChange={(e) => this.onDrop(e, 'BUILDING_CAMPUS_IMAGE')}
											imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
											maxFileSize={10485760 * 100}
											ref={this.campusImageRef}
										/>
									</div>
									<div className=" col-lg-12">
										<FieldArray component={this.renderCampusImages} name="campusImages" />
										{/* <ul className="image-gallery">
										{
			                this.state.arrayLocalCampusImages && this.state.arrayLocalCampusImages.length > 0 &&
			                this.state.arrayLocalCampusImages.map((obj, index) => {
			                  return(
			                    <li key={index}>
			                      <figure className="img"><a href = {obj.original} target = "_blank"><img src={obj.original} alt=""/></a></figure>
														<button className="close-btn" onClick = {(e) => this.updateImageArray(e, index, "BUILDING_CAMPUS_IMAGE")}></button>
			  									</li>
			                  )
			                })
			              }
									</ul> */}
									</div>
								</div>
							</div>
							<div className=" col-lg-6">
								<div className="row">
									<div className=" col-lg-12">
										<ImageUploader
											className={(this.state.roomImagesUploadedCount != this.state.roomImagesToBeUploadedCount) ? "loading" : ""}
											accept=".jpg, .jpeg, .png, .gif"
											singleImage={false}
											label=""
											withIcon={false}
											buttonText='Upload room images (1200x800, ratio: 3:2 & less than 2MB)'
											onChange={(e) => this.onDrop(e, 'BUILDING_ROOM_IMAGE')}
											imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
											maxFileSize={10485760 * 100}
											ref={this.roomImageRef}
										/>
									</div>
									<div className=" col-lg-12">
										<FieldArray component={this.renderRoomImages} name="roomImages" />
										{/* <ul className="image-gallery">
										{
			                this.state.arrayLocalRoomImages && this.state.arrayLocalRoomImages.length > 0 &&
			                this.state.arrayLocalRoomImages.map((obj, index) => {
			                  return(
			                    <li key={index}>
			                      <figure className="img"><a href = {obj.original} target = "_blank"><img src={obj.original} alt=""/></a></figure>
														<button className="close-btn" onClick = {(e) => this.updateImageArray(e, index, "BUILDING_ROOM_IMAGE")}></button>
			  									</li>
			                  )
			                })
			              }
									</ul> */}
									</div>
								</div>
							</div>
						</div>
						<div className="form-btn-group">
							<button className="btn btn-secondary hvr-shadow" onClick={(e) => { e.preventDefault(); window.history.back(); }}>Cancel</button>
							<button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Save</button>&nbsp;
          </div>
					</form>
				</section>
			</>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addBuildingGalleryImages: (data, apiPath, method) => {
			return dispatch(addBuildingGalleryImages(data, apiPath, method));
		},
		fetchBuildingGalleryImages: (data) => {
			return dispatch(fetchBuildingGalleryImages(data));
		},
		uploadFile: (data) => {
			return dispatch(uploadFile(data));
		},
		fetchPlans: (data) => {
			return dispatch(fetchPlans(data));
		}

	}
}
BuildingManagePictureGallery = reduxForm({
	form: 'BuildingManagePictureGallery',
	//validate,
	//enableReinitialize: true
})(BuildingManagePictureGallery);
const selector = formValueSelector('BuildingManagePictureGallery');
const mapStatesToProps = (state, ownProps) => {
	const campusImages = selector(state, 'campusImages');
	const roomImages = selector(state, 'roomImages');
	return {
		campusImages: campusImages,
		roomImages: roomImages
	}
}

BuildingManagePictureGallery = connect(mapStatesToProps, mapDispatchToProps)(BuildingManagePictureGallery);



export default withRouter(BuildingManagePictureGallery);
