import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addLocality, fetchCities, fetchLocality} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter locality name";
    hasErrors = true;
  }
  if (!values.countryId) {
    errors.countryId = "Select country";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class AddLocality extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.localityId,
      localityDetail: null,
      cities: []
    }
  }

  submitForm(values, dispatch, props){
    return this.props.addLocality(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      if(this.state.id){
        window.history.back();
      }
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    this.fetchCities();
    if(this.state.id){
      this.fetchLocality(this.state.id);
    }
  }

  fetchCities(){
		this.setState({fetchingRecords: true});
    this.props.fetchCities({getAll: 1}).then((response) => {
      let cities = response.value.data.data.cities;
      cities.forEach((obj, index) => {
        //alert(cities[index].)
        cities[index] = Object.assign(cities[index], {label: cities[index].name+" ("+ cities[index].country.name +")"})
      })
      this.setState({fetchingRecords: false, cities: cities});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchLocality(localityId){
		this.props.fetchLocality({id: localityId}).then((response) => {
      let localityDetail = response.value.data.data;
      this.props.initialize({id: localityDetail.id, name: localityDetail.name, cityId: localityDetail.city.id});
      this.setState({localityDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/localities/list">Localities</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Locality</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-8">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Locality"
                />
              </div>
              <div className=" col-lg-8">
                <Field
                  label="Select city"
                  name='cityId'
                  optionLabel='label'
                  optionValue='id'
                  options={this.state.cities}
                  component={renderReactSelect}
                  placeholder="Select city"
                  multi={false}
                 />
               </div>
             </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addLocality: (data) => {
      return dispatch(addLocality(data));
    },
    fetchLocality: (data) => {
      return dispatch(fetchLocality(data));
    },
    fetchCities: (data) => {
      return dispatch(fetchCities(data));
    }
  }
}

AddLocality = connect(null, mapDispatchToProps)(AddLocality);

AddLocality = reduxForm({
  form: 'AddLocality',
  validate,
  //enableReinitialize: true
})(AddLocality);

export default AddLocality;
