import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {fetchFlat, manageFlatRooms, fetchFlatRooms, fetchPlans} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeNumber} from '../../../constants';
import LoaderForm from '../../../components/LoaderForm';

import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter flat name";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class ManageFlatRooms extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.flatId,
      flatDetail: null,
      flatInfo: null,
      showLoader: false,
      plans: []
    }
    this.renderRooms = this.renderRooms.bind(this);
  }

  submitForm(data, dispatch, props){
    data = Object.assign(data, {flatId: this.state.id});
    return this.props.manageFlatRooms(data).then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchPlans();
    if(this.state.id){
      this.fetchFlatRooms(this.state.id);
    }
  }

  fetchPlans(flatId){
		this.props.fetchPlans({isAll: 1}).then((response) => {
      let plans = response.value.data.data.plans;
      this.setState({plans});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchFlat(flatId){
		this.props.fetchFlat({id: flatId}).then((response) => {
      let flatDetail = response.value.data.data;
      let roomsArray = [];
      if(parseInt(flatDetail.rooms) > 0){
        for(var i=0; i < parseInt(flatDetail.rooms); i++){
          roomsArray.push({});
        }
      }
      this.props.change('rooms', roomsArray);
      this.setState({flatDetail, showLoader: false});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchFlatRooms(flatId){
    this.setState({showLoader: true});
		this.props.fetchFlatRooms({flatId: flatId, isAll: 1}).then((response) => {
      var data = response.value.data.data.rooms;
      var flatInfo = '';
      if(data && data.length > 0){
        let roomsArray = [];
        data.forEach((obj, index) => {
          if(index == 0){
            flatInfo = "#"+obj.flat.number+", "+obj.building.name+", "+obj.locality.name;
          }
          roomsArray.push({id: obj.id, bathroomAttached: obj.bathroomAttached, balcony: obj.balcony, beds: obj.beds, planId: obj.plan.id});
        });
        this.props.change('rooms', roomsArray);
        this.setState({showLoader: false});
      }else{
        this.fetchFlat(this.state.id);
      }
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }

  renderRooms({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;
    return (
      <>{
        fields.map((member, index) =>{

          var roomId = fields.get(index).id;
          //var addOns = fields.get(index).addOns;
          return(
              <div className="row" key = {index}>
                <div className='col-md-2'>
                  <label style = {{marginTop: index == 0?"38px":'14px'}}>{roomId?"Room ID - "+roomId:"Room "+(index + 1)}</label>
                </div>
                <div className='col-md-2'>
                  <Field name={`${member}.beds`}  component={renderField} label={index == 0?"No. of beds":""}   normalize = {normalizeNumber}/>
                </div>
                <div className='col-md-3'>
                  <Field
                    label={index == 0?"Has balcony?":''}
                    name={`${member}.balcony`}
                    optionLabel='name'
                    optionValue='id'
                    options={[{id: 0, name: 'No'}, {id: 1, name: 'Yes'}]}
                    component={renderReactSelect}
                    placeholder="Select.."
                    multi={false}
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    label={index == 0?"Attached bathroom?":''}
                    name={`${member}.bathroomAttached`}
                    optionLabel='name'
                    optionValue='id'
                    options={[{id: 0, name: 'No'}, {id: 1, name: 'Yes'}]}
                    component={renderReactSelect}
                    placeholder="Select.."
                    multi={false}
                  />
                </div>
                <div className='col-md-2'>
                  <Field
                    label={index == 0?"Plan":''}
                    name={`${member}.planId`}
                    optionLabel='name'
                    optionValue='id'
                    options={this.state.plans}
                    component={renderReactSelect}
                    placeholder="Select.."
                    multi={false}
                  />
                </div>
              </div>
        )}

        )
      }
    </>)
  }









  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/space-management/flats/list">Flats</Link></li>
          <li className="breadcrumb-item active">Manage Rooms</li>
        </ol>
        <h5 className="h5 mb-4">Manage Rooms</h5>
        <section className="formBg" style={{maxWidth:"872px", position:'relative'}}>
          {
						this.state.showLoader == true && <LoaderForm />
					}
          {
            this.state.showLoader == false &&
            <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
              <FieldArray component={this.renderRooms} name="rooms"/>

              <div className="form-btn-group">
                <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
                <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
              </div>
            </form>
          }

        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFlat: (data) => {
      return dispatch(fetchFlat(data));
    },
    manageFlatRooms: (data) => {
      return dispatch(manageFlatRooms(data));
    },
    fetchFlatRooms: (data) => {
      return dispatch(fetchFlatRooms(data));
    },
    fetchPlans: (data) => {
      return dispatch(fetchPlans(data));
    }
  }
}

ManageFlatRooms = connect(null, mapDispatchToProps)(ManageFlatRooms);
ManageFlatRooms = reduxForm({
  form: 'ManageFlatRooms',
  validate,
  //enableReinitialize: true
})(ManageFlatRooms);


export default ManageFlatRooms;
