import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_CALLBACK_REQUESTS = "FETCH_CALLBACK_REQUESTS";
export const UPDATE_CALLBACK_REQUEST = 'UPDATE_CALLBACK_REQUEST';



export function fetchCallbackRequests(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCallbackRequests`
  });

  return {
    type: FETCH_CALLBACK_REQUESTS,
    payload: request
  };
}
export function updateCallbackRequestStatus(data) {
  const request = axios({
    method: 'PUT',
    data: data,
    url: `${API_ROOT}/serviceProvider/setCallbackStatus`
  });

  return {
    type: UPDATE_CALLBACK_REQUEST,
    payload: request
  };
}
