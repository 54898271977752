import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_TAXES = "FETCH_TAXES";
export const FETCH_TAX = 'FETCH_TAX';
export const ADD_TAX = 'ADD_TAX';
export const DELETE_TAX = 'DELETE_TAX';

export function fetchTaxes(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getTaxes`
  });

  return {
    type: FETCH_TAXES,
    payload: request
  };
}
export function fetchTax(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getTaxById`
  });

  return {
    type: FETCH_TAX,
    payload: request
  };
}

export function addTax(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addTax`
  });
  return {
    type: ADD_TAX,
    payload: request
  };
}

export function deleteTax(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteTax`
  });
  return {
    type: DELETE_TAX,
    payload: request
  };
}
