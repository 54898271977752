import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addBuilding, fetchLocalities, fetchBuilding} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Map from '../../../maps/MapWithMarker';
import { withScriptjs } from "react-google-maps";
import GooglePlacesAutocomplete from '../../../maps/GooglePlacesAutocomplete';
import {GOOGLE_MAP_API_KEY, normalizeFloorsField} from "../../../constants";
import { toast } from 'react-toastify';

const MapLoader = withScriptjs(Map);

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.localityId) {
    errors.localityId = "Select locality";
    hasErrors = true;
  }
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter building name";
    hasErrors = true;
  }
  if(!values.floors){
    errors.floors = "Enter floors";
    hasErrors = true;
  }
  if(!values.address){
    errors.address = "Enter formal address";
    hasErrors = true;
  }




  return hasErrors && errors;
}

class AddBuilding extends Component {
	constructor(props){
    super(props);
    this.state = {
      showMap: false,
      id: props.match.params.buildingId,
      buildingDetail: null,
      localities: [],
      mapAddress: null,
      mapLatLng:null,
      updateMapTime: null,
    }
    this.updateMapAddress = this.updateMapAddress.bind(this);
    this.updateLatLng = this.updateLatLng.bind(this);
  }

  updateMapAddress(value){
    this.setState({mapAddress: value});
    this.props.change('mapAddress', value);
  }

  updateLatLng(value){

    this.setState({mapLatLng: value, updateMapTime: new Date().getTime()});
    this.props.change('longitude', value.lng);
    this.props.change('latitude', value.lat);
  }

  submitForm(values, dispatch, props){
    if(!values.mapAddress){
      toast.error("Select location on map");
      return false;
    }
    return this.props.addBuilding(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      if(this.state.id){
        window.history.back();
      }
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    this.fetchLocalities();
    if(this.state.id){
      this.fetchBuilding(this.state.id);
    }
    setTimeout(() =>{
      this.setState({showMap: true});
    }, 1000);
  }

  fetchLocalities(){
		this.setState({fetchingRecords: true});
    this.props.fetchLocalities({getAll: 1}).then((response) => {
      let localities = response.value.data.data.localities;
      localities.forEach((obj, index) => {
        //alert(localities[index].)
        localities[index] = Object.assign(localities[index], {label: localities[index].name+" ("+localities[index].city.name+"->"+localities[index].country.name +")"})
      })
      this.setState({fetchingRecords: false, localities: localities});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchBuilding(buildingId){
		this.props.fetchBuilding({id: buildingId}).then((response) => {
      let buildingDetail = response.value.data.data;
      this.props.initialize({
        id: buildingDetail.id,
        name: buildingDetail.name,
        localityId: buildingDetail.locality.id,
        floors: buildingDetail.floors,
        mapAddress: buildingDetail.mapAddress,
        longitude: buildingDetail.longitude,
        latitude: buildingDetail.latitude,
        address: buildingDetail.address
      });
      this.setState({buildingDetail, mapAddress: buildingDetail.mapAddress, mapLatLng: {lat: buildingDetail.latitude, lng: buildingDetail.longitude}});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/localities/list">Buildings</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Building</h5>
        <section className="formBg" >
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  label="Select locality"
                  name='localityId'
                  optionLabel='label'
                  optionValue='id'
                  options={this.state.localities}
                  component={renderReactSelect}
                  placeholder="Select locality"
                  multi={false}
                />
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Building name"
                />
                <Field
                  name="floors"
                  component={renderField}
                  type="text"
                  label="Floors (comma separated)"
                  normalize={normalizeFloorsField}
                />
                <Field
                  name="address"
                  component={renderField}
                  type="text"
                  label="Formal address"
                />

              </div>
              <div className=" col-lg-6">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Google map address</label>
                    <GooglePlacesAutocomplete
                      updateFormattedAddress={this.updateMapAddress}
                      updateLatLng={this.updateLatLng}
                      placeholder="Search address.."
                      address={this.state.mapAddress}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                {
                    this.state.showMap &&
                    <MapLoader
                      origin={this.state.mapLatLng}
                      updateMapTime={this.state.updateMapTime}
                      googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+GOOGLE_MAP_API_KEY+"&v=3.exp&libraries=geometry,drawing,places"}
                      loadingElement={<div style={{ height: `100%` }} />}
                      mapHeight="350px"
                      mapWidth="100%"

                    />
                  }
                </div>
              </div>
            </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBuilding: (data) => {
      return dispatch(addBuilding(data));
    },
    fetchBuilding: (data) => {
      return dispatch(fetchBuilding(data));
    },
    fetchLocalities: (data) => {
      return dispatch(fetchLocalities(data));
    }
  }
}

AddBuilding = connect(null, mapDispatchToProps)(AddBuilding);

AddBuilding = reduxForm({
  form: 'AddBuilding',
  validate,
  //enableReinitialize: true
})(AddBuilding);

export default AddBuilding;
