import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addAmenity, fetchAmenity} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_COUNTRY_ID, AMENITY_TYPES} from '../../../constants';
import ImageUploader from 'react-images-upload';
import {uploadFile} from '../../../actions/common';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter amenity";
    hasErrors = true;
  }

  if (!values.type || values.type === '') {
    errors.type = "Select amenity type";
    hasErrors = true;
  }


  return hasErrors && errors;
}

class AddAmenity extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.amenityId,
      amenityDetail: null,
      arrayLocalImages: [],
    }
    this.onDrop = this.onDrop.bind(this);
  }

  submitForm(values, dispatch, props){
    return this.props.addAmenity(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    if(this.state.id){
      this.fetchAmenity(this.state.id);
    }
  }




  fetchAmenity(id){
		this.props.fetchAmenity({id}).then((response) => {
      let amenityDetail = response.value.data.data;
      this.props.initialize({id: amenityDetail.id, name: amenityDetail.name, type: amenityDetail.type, imageId: amenityDetail.image?amenityDetail.image.id:null});
      let arrayLocalImages = [];
      if(amenityDetail.image && amenityDetail.image.id){
        arrayLocalImages.push(amenityDetail.image);
      }
      this.setState({amenityDetail, arrayLocalImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:"AMENITY_IMAGE"}).then((response) => {
        this.setState({imageUploading: false, arrayLocalImages: []});
        var payload = response.value.data.data;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					this.props.change('imageId', payload.id);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/cities/list">Amenities</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Amenity</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-12">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Amenity"
                />
              </div>

              <div className=" col-lg-8">
                <Field
                  label="Amenity Type"
                  name='type'
                  optionLabel='name'
                  optionValue='id'
                  options={AMENITY_TYPES}
                  component={renderReactSelect}
                  placeholder="Amenity Type"
                  multi={false}
                 />
               </div>
            </div>
            <div className="row">
              <div className=" col-lg-8">
              <ImageUploader
								className={this.state.imageUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText='Add Image'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="imageId"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayLocalImages.length > 0 &&
                this.state.arrayLocalImages.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                      <figure className="img"><img src={obj.original} alt=""/></figure>
  									</div>
                  )
                })
              }
            </div>


            </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAmenity: (data) => {
      return dispatch(addAmenity(data));
    },
    fetchAmenity: (data) => {
      return dispatch(fetchAmenity(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    }
  }
}

AddAmenity = connect(null, mapDispatchToProps)(AddAmenity);

AddAmenity = reduxForm({
  form: 'AddAmenity',
  validate,
  //enableReinitialize: true
})(AddAmenity);

export default AddAmenity;
