import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchCallbackRequests, updateCallbackRequestStatus} from '../../../actions/callback_requests';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Confirm from 'react-confirm-bootstrap';
import {currencyFormat, CURRENCY_SYMBOL} from '../../../constants';
import moment from 'moment';
import $ from 'jquery';

class CallbackRequestsListing extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/callback-requests/list',
			records: null,
			roles: [],
			filters: [
				'fullName',
				'email',
				'phoneNumber',
				'attendedBy',
				'status'
			]
		};
  }

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		//this.props.initialize(this.state.params);
		this.fetchCallbackRequests(this.state.params);
		//this.updateFilterForm(this.state.params);
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});
		this.props.initialize(formValues);
	}



	fetchCallbackRequests(params){
		params = Object.assign(params, {isAll: 0});
		this.setState({fetchingRecords: true});
    this.props.fetchCallbackRequests(params).then((response) => {
      this.setState({fetchingRecords: false});
      var records = response.value.data.data;
      this.setState({records});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCallbackRequests(params);
		}
	}

	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){

		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}


	updateCallbackRequestStatus(data){
    this.props.updateCallbackRequestStatus(data).then((response) => {
			toast.success(response.value.data.message);
      this.fetchCallbackRequests(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/callback-requests/list?pageNumber=1">Callback Requests</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Callback Requests</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="fullName"
										component={renderField}
										label="Name Contains"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="email"
										component={renderField}
										label="Email Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="phoneNumber"
										component={renderField}
										label="Phone (Without Country code)"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="attendedBy"
										component={renderField}
										label="Attended By"
									/>
                </div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Status"
		                name='status'
		                optionLabel='name'
		                optionValue='id'
		                options={[{id: 0, name: 'Unattended'}, {id: 1, name: 'Attended'}]}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div class="custom-table mt-0">
					<div class="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table class="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Status</th>
									<th>Requested On</th>
									<th>Attended On</th>

								</tr>
							</thead>
						<tbody>
						{
							records && records.callbackRequests && records.callbackRequests.length > 0 &&
							records.callbackRequests.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.fullName}</td>
										<td>{obj.email}</td>
										<td>+{obj.countryCode} {obj.phoneNumber}</td>
										<td>{obj.status == 1?<>Attended by <strong>{obj.attendedBy.fullName}</strong></>:<Confirm className="confirmPopup"
												onConfirm={() => this.updateCallbackRequestStatus({id: obj.id, status: 1})}
												body="Are you sure you want to mark this?"
												confirmText="Confirm Marking"
												title="Mark!"><button className="btn btn-secondary hvr-shadow">Mark</button></Confirm>}</td>
										<td>{moment(obj.createdAt).format("DD MMM, Y")}</td>
										<td>{obj.status == 1?moment(obj.updatedAt).format("DD MMM, Y"):"N.A."}</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.callbackRequests && records.callbackRequests.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

CallbackRequestsListing = reduxForm({
  form: 'CallbackRequestsListingFilter'
})(CallbackRequestsListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCallbackRequests: (data) => {
      return dispatch(fetchCallbackRequests(data));
    },
		updateCallbackRequestStatus: (data) => {
      return dispatch(updateCallbackRequestStatus(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(CallbackRequestsListing));
