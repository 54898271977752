import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {normalizeAmountField} from '../../../constants';
import {addEvent, fetchEventById, fetchEventTags} from '../../../actions/events';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import renderDatePicker from '../../FormFields/renderDatePicker';
import {uploadFile} from '../../../actions/common';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';
import { WithContext as ReactTags } from 'react-tag-input';
import moment from 'moment';

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.title || values.title.trim() === '') {
    errors.title = "Enter event title";
    hasErrors = true;
  }
  if (!values.eventDate || values.eventDate === '') {
    errors.eventDate = "Enter event date";
    hasErrors = true;
  }

  if (!values.mainImageId || values.mainImageId === '') {
    errors.mainImageId = "Upload event image";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class AddEvent extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.eventId,
      eventDetail: null,
      arrayLocalImages: [],
      arrayLocalGalleryImages: [],
      suggestions: [],
      //tags: [],
      galleryImagesToBeUploadedCount: 0,
      galleryImagesUploadedCount: 0
    }
    this.onDrop = this.onDrop.bind(this);
    //this.handleDelete = this.handleDelete.bind(this);
    //this.handleAddition = this.handleAddition.bind(this);
    this.galleryImagesRef = React.createRef();
    this.mainImageRef = React.createRef();
  }

  submitForm(values, dispatch, props){

    if(values.eventDate){
      values = Object.assign(values, {eventDate: moment(values.eventDate).format("YYYY-MM-DD")});
    }
    //values = Object.assign(values, {eventTags: this.state.tags});
    //console.log('jjjjjjj', this.state.tags);
    //console.log('jjjjjjj', values); return;
    return this.props.addEvent(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    //this.fetchEventTags();
    if(this.state.id){
      this.fetchEventById(this.state.id);
    }
  }

  /*fetchEventTags(){
		this.props.fetchEventTags({isAll: 1}).then((response) => {
      let eventTags = response.value.data.data.eventTags;
      eventTags.forEach((obj, index) => {
        eventTags[index] = {id: obj.id.toString(), name: obj.name};
      });
      this.setState({suggestions: eventTags});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}*/



  fetchEventById(newsId){
		this.props.fetchEventById({id: newsId}).then((response) => {
      let eventDetail = response.value.data.data;
      let galleryImageIds = [];
      let arrayLocalGalleryImages = [];
      let arrayLocalImages = [];
      if(eventDetail && eventDetail.galleryImages && eventDetail.galleryImages.length > 0){
        eventDetail.galleryImages.forEach((obj, index) => {
          galleryImageIds.push(obj.id);
          arrayLocalGalleryImages.push(obj);
        });
      }
      if(eventDetail && eventDetail.mainImage && eventDetail.mainImage.id){
        arrayLocalImages.push(eventDetail.mainImage);
      }
      this.props.initialize({
        id: eventDetail.id,
        title: eventDetail.title,
        mainImageId: eventDetail.mainImage.id,
        eventDate: moment(eventDetail.eventDate).format('YYYY-MM-DD'),
        description: eventDetail.description,
        galleryImageIds: galleryImageIds
      });

      /*if(eventDetail.eventTags && eventDetail.eventTags.length > 0){
        eventDetail.eventTags.forEach((obj, index) => {
          eventDetail.eventTags[index] = {id: obj.id.toString(), name: obj.name};
        });
      }*/


      //this.setState({eventDetail, arrayLocalGalleryImages, arrayLocalImages, tags: eventDetail.eventTags});
      this.setState({eventDetail, arrayLocalGalleryImages, arrayLocalImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 2097152){ //2097152
        alert("Max file size allowed is 2MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:"EVENT_MAIN_IMAGE"}).then((response) => {
        this.setState({imageUploading: false, arrayLocalImages: []});
        var payload = response.value.data.data;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					this.props.change('mainImageId', payload.id);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
    this.mainImageRef.current.state.pictures = [];
    this.mainImageRef.current.state.files = [];
  }

  /*handleDelete(i) {
      const { tags } = this.state;
      this.setState({
       tags: tags.filter((tag, index) => index !== i),
      });
  }*/

  /*handleAddition(tag) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
  }*/

    uploadGalleryImages(pictures) {
  		var galleryImageIds = this.props.galleryImageIds?this.props.galleryImageIds:[];
      var objThis = this;
  		for (var i = 0; i < pictures.length; i++) {
        var form_data = new FormData();
        var file_data = pictures[i];
        if(file_data && file_data.size > 2097152){ //2097152
          alert("Max file size allowed is 2MB");
          return;
        }

        form_data.append("file", file_data);
        setTimeout(() => {
  				this.setState({galleryImagesToBeUploadedCount: this.state.galleryImagesToBeUploadedCount + 1});
  			}, 100);

        this.props.uploadFile(form_data, {fileType:"EVENT_GALLERY_IMAGE"}).then((response) => {
  				var payload = response.value.data.data;
  				let arrayLocalGalleryImages = this.state.arrayLocalGalleryImages?this.state.arrayLocalGalleryImages:[];

          if(payload && payload.id){
  					galleryImageIds.push(payload.id);
  					arrayLocalGalleryImages.push(payload);
  					this.setState({arrayLocalGalleryImages, galleryImagesUploadedCount: this.state.galleryImagesUploadedCount + 1});
  					this.props.change('galleryImageIds', galleryImageIds);
          }
        }).catch((error)=>{
          this.setState({galleryImagesUploadedCount: this.state.galleryImagesUploadedCount + 1});
          if (error.response) {
            toast.error(error.response.data.message);
          } else if (error.request) {
            toast.error("Network error!");
          } else {
            toast.error(error.message);
          }
        });

      }
  		this.galleryImagesRef.current.state.pictures = [];
  		this.galleryImagesRef.current.state.files = [];


    }


  updateGalleryImageArray(e, index){
		if(e){
			e.preventDefault();
		}
		var arrayLocalGalleryImages = this.state.arrayLocalGalleryImages;
    var galleryImageIds =  this.props.galleryImageIds;
    arrayLocalGalleryImages.splice(index, 1);
		galleryImageIds.splice(index,1);
    this.setState({arrayLocalGalleryImages});
		this.props.change('galleryImageIds', galleryImageIds);
  }




  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    //const { tags, suggestions } = this.state;
    const { suggestions } = this.state;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/event/list">Events</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Event</h5>
        <section className="formBg" style={{maxWidth:"1000px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-5">
                <div className="row">
                  <div className=" col-lg-12">
                    <Field
                      name="title"
                      component={renderField}
                      type="text"
                      label="Event title"
                    />
                  </div>
                  <div className=" col-lg-12">
                    <Field
                      name="eventDate"
                      component={renderDatePicker}
                      label="Event date"
                    />
                  </div>
                  <div className=" col-lg-12">
                    <Field
                      name="description"
                      component={renderField}
                      type="text"
                      label="Description"
                    />
                  </div>
                  {/* <div className=" col-lg-12">
                    <div className="form-group">
                      <label>Tags</label>
                      <ReactTags
                        tags={tags}
                        suggestions={suggestions}
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        delimiters={delimiters}
                        labelField="name"
                      />
                    </div>
                  </div> */}
                  <div className=" col-lg-12">
                    <ImageUploader
      								className={this.state.imageUploading?"loading":""}
                      accept=".jpg, .jpeg, .png, .gif"
                      singleImage={true}
                      label=""
                      withIcon={false}
                      buttonText='Add Main Image (348x232, ratio: 3:2 & less than 2MB)'
                      onChange={this.onDrop}
                      imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                      maxFileSize={10485760 * 100}
                      ref={this.mainImageRef}
      							/>
                    <Field
                      name="mainImageId"
                      component={renderField}
                      type="hidden"
                      label=""
                    />
                    {
                      this.state.arrayLocalImages.length > 0 &&
                      this.state.arrayLocalImages.map((obj, index) => {
                        return(
                          <div className="product-img" key={index}>
                            <figure className="img"><img src={obj.original} alt=""/></figure>
        									</div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className=" col-lg-7">
                <div className=" col-lg-12">
                  <ImageUploader
                    className={this.state.galleryImagesUploadedCount != this.state.galleryImagesToBeUploadedCount?"loading":""}
                    accept=".jpg, .jpeg, .png, .gif"
                    singleImage={false}
                    label=""
                    withIcon={false}
                    buttonText='Add Event Gallery Images (348x232, ratio: 3:2 & less than 2MB)'
                    onChange={(e) => this.uploadGalleryImages(e)}
                    imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                    maxFileSize={10485760 * 100}
                    ref={this.galleryImagesRef}
                  />

                </div>
                <div className=" col-lg-12">
									<ul className="image-gallery">
										{
			                this.state.arrayLocalGalleryImages && this.state.arrayLocalGalleryImages.length > 0 &&
			                this.state.arrayLocalGalleryImages.map((obj, index) => {
			                  return(
			                    <li key={index}>
			                      <figure className="img"><a href = {obj.original} target = "_blank"><img src={obj.original} alt=""/></a></figure>
														<button className="close-btn" onClick = {(e) => this.updateGalleryImageArray(e, index)}></button>
			  									</li>
			                  )
			                })
			              }
									</ul>
								</div>
              </div>
            </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addEvent: (data) => {
      return dispatch(addEvent(data));
    },
    fetchEventById: (data) => {
      return dispatch(fetchEventById(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    },
    fetchEventTags: (data) => {
      return dispatch(fetchEventTags(data));
    }
  }
}



AddEvent = reduxForm({
  form: 'AddEvent',
  validate,
  //enableReinitialize: true
})(AddEvent);

const selector = formValueSelector('AddEvent');
const mapStatesToProps = (state, ownProps) => {
  const galleryImageIds = selector(state, 'galleryImageIds');
	const mainImageId = selector(state, 'mainImageId');
  return {
    galleryImageIds: galleryImageIds,
		mainImageId: mainImageId
  }
}
AddEvent = connect(mapStatesToProps, mapDispatchToProps)(AddEvent);

export default AddEvent;
