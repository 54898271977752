import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {Link, NavLink} from 'react-router-dom';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import {jsUcfirst} from '../../constants';
import renderReactSelect from '../FormFields/renderReactSelectForForm';
import {fetchNotifications} from '../../actions/users';
import {SOCKET_URL} from '../../constants';
import io from 'socket.io-client';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton';

class Header extends Component {
	constructor(props) {
    super(props);
		this.socket = io.connect(`${SOCKET_URL}?token=${window.localStorage.jwtToken || ' '}`, {transports: [ 'polling', 'websocket']});
    this.state = {
      isOpen: false,
      user: props.user,
      searchResults: [],
      fetchingSearchResults: false
    };
  }

  componentDidMount(){
		this.websocketStart();
    $(document).click(function(e){
      if(!$(e.target).parents('#search-results-dropdown').length){
        $('#search-results-dropdown').removeClass('active');
      };
    });

		$(".nav-hamburger").click(function(){
			$(".leftmenu").toggleClass('open');
			$(this).toggleClass('active');
		})

		var objThis = this;
		setTimeout(() => {
      $('.notifications-parent').on('click', (e) => {
        this.fetchNotifications({pageNumber: 1}, false);
      });
    }, 500);

		$('.notification-drop.dropdown-menu').on('click', function(event){
        if(event.target.className === 'show-all' || event.target.className === 'show-all loading'){
          objThis.loadMoreNotifications(event);
          event.stopPropagation();
          //objThis.readNotification($(event.target).data('id'));
        }else{
          /*if($(event.target).hasClass('load_more_notifications')){
            event.preventDefault();
            objThis.loadMore();

          }*/

          //event.stopPropagation();
        }
      });
  }

	websocketStart(){
		this.socket.on("connect", message => {
    	console.log('tttttttconnect',message);
   	});
		this.socket.on("error", message => {
    	console.log('ttttttterror3333',message);
   	});
   	this.socket.on("disconnect", message => {
     	console.log('tttttttdisconnect3333',message);
   	});
   	this.socket.on("connecting", message => {
     	console.log('tttttttconnecting3333',message);
   	});
   	this.socket.on("errorfromserver", message => {
     	console.log('ttttttterror3333',message);
   	});
	 	this.socket.on("adminNotification", message => {
     	console.log('ttttttt', message);
     	this.setState({notificationCount: message.count});
   	});
 }

 fetchNotifications(params, append){
    if(!params){
      params = {pageNumber:1};
    }
    this.setState({fetchingNotifications: true, notificationCount: 0});
    this.props.fetchNotifications(params).then((response) => {
      this.setState({fetchingNotifications: false});
      var payload = response.value.data.data;
      var pageNumber = parseInt(payload.pageNumber);
      if(payload.notifications.length > 0){
        pageNumber = parseInt(payload.pageNumber) + 1;
      }
      if(append){
        if(this.state.notifications && this.state.notifications.notifications){
           var notifications = this.state.notifications;
           var updatedData = notifications.notifications.concat(payload.notifications);
           notifications = Object.assign(notifications, {notifications: updatedData});
           this.setState({notifications: notifications, pageNumber});
        }else{

          this.setState({notifications: payload, pageNumber});
        }

      }else{

        this.setState({notifications: payload, pageNumber});
      }

    }).catch((error)=>{
        this.setState({fetchingNotifications: false});
        if (error.response) {
          //toast.error(error.response.data.flash_message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });
    }

    loadMoreNotifications(e){
      e.preventDefault();
      if(this.state.fetchingNotifications){
        return;
      }
      var pageNumber = this.state.pageNumber;
      var params = {pageNumber: pageNumber};
      this.fetchNotifications(params, true);
    }



  render() {
		return (
			<div className="row">
      <div className="col-md-3 col-3">
        <a className="logo" href="javascript://">
          <img width="200" className="i" src="/assets/img/logo_header.png" alt="img"/>
        </a>
      </div>
      <div className="col-md-9 col-9 pl-0">
				<div id="topNav">
          <nav className="navbar navbar-expand-sm justify-content-end p-0">
            <ul className="navbar-nav align-items-center">

							<li className="notification notifications-parent" >
	         			<a id = "noti-anchor" href="javascript:void(0);"   data-toggle="dropdown" className={this.state.notificationCount > 0?"notify drp-down":'drp-down'}><i><img src="/assets/img/school-bell.svg" alt=""/></i></a>
	         			<div className="notification-drop dropdown-menu dropdown-menu-xl py-0">
	           			<ul>
		               {

		                 this.state.fetchingNotifications &&
		                 <li key={0}>
		                   <Skeleton count={2} height={25} />
		                 </li>
		               }
		                {
		                  this.state.notifications && this.state.notifications.notifications && this.state.notifications.notifications.length > 0 &&
		                  this.state.notifications.notifications.map((obj, index) => {
												var notiLink = "";
												if(obj.notificationType == 1){ //NEW_ORDER
													notiLink = "/admin/bookings/list/detail/"+obj.data.orderId;
												}else if(obj.notificationType == 2){//EXTEND_ORDER
													notiLink = "/admin/bookings/list/detail/"+obj.data.orderId;
												}else if(obj.notificationType == 3){//CANCEL_ORDER
													notiLink = "/admin/bookings/list/detail/"+obj.data.orderId;
												}else if(obj.notificationType == 4){//CALLBACK_REQUEST
													notiLink = "/admin/callback-requests/list?pageNumber=1";
												}
		                    return(
		                      <li key = {obj._id}>
		                         <a href={notiLink} target = "_blank" >
		                            <div className="notification-content">
		                               <h6 className={`${obj.isRead?'notification_read':''}`} dangerouslySetInnerHTML={{__html:obj.message}} />
		                               <span className="time">{moment(obj.createdAt).fromNow()}</span>
		                            </div>
		                         </a>
		                      </li>
		                    )
		                  })
		                }


		                {
		                  !this.state.fetchingNotifications && this.state.notifications && this.state.notifications.notifications.length <= 0 &&
		                  <li>
		                    No notifications found.
		                  </li>
		                }

	            </ul>
	            {
	              this.state.notifications && (this.state.notifications.pages > this.state.notifications.pageNumber) &&
	              <div className="show-all">
	                 <a className={this.state.fetchingNotifications === true?'show-all btn-loader':'show-all'} href="#" onClick = {(e) => this.loadMoreNotifications(e)} >Load More</a>
	              </div>
	            }
	         </div>
	      </li>

							<li className="nav-item">
                <span href="javascript://" className="dropdown profile-dropdown">
                  <button type="button" className="dropdown-toggle icon-arrow-down" data-toggle="dropdown">

										<img className="rounded-circle" src="/assets/img/default_user.png" alt="img" width="32" height="32" />
                    <span>{jsUcfirst(this.state.user.name)}</span>
                  </button>
                  <ul className="dropdown-menu">

                    <li>
                      <Link className="dropdown-item" to="/admin/change-password">Change Password</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/logout">Logout</Link>
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>

		</div>


		);
  	}
}
const mapStatesToProps = (state, ownProps) => {
  return {
    user: state.user.user,
	};
}

const mapDispatchToProps = (dispatch) => {
  return {
		fetchNotifications: (data) => {
      return dispatch(fetchNotifications(data));
    }
	}
}

Header = reduxForm({
  form: 'LangDrpForm'
})(Header);


export default connect(mapStatesToProps, mapDispatchToProps)(Header);
