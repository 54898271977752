import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {normalizeAmountField} from '../../../constants';
import {addPlan, fetchPlan} from '../../../actions/plans';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {uploadFile} from '../../../actions/common';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter plan name";
    hasErrors = true;
  }
  if (!values.subName || values.subName.trim() === '') {
    errors.subName = "Enter sub title for plan";
    hasErrors = true;
  }
  if (!values.description || values.description.trim() === '') {
    errors.description = "Enter description";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class AddPlan extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.planId,
      planDetail: null,
      arrayLocalImages: [],
    }
    this.onDrop = this.onDrop.bind(this);
  }

  submitForm(values, dispatch, props){
    return this.props.addPlan(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.id){
      this.fetchPlan(this.state.id);
    }
  }

  fetchPlan(planId){
		this.props.fetchPlan({id: planId}).then((response) => {
      let planDetail = response.value.data.data;
      this.props.initialize({
        id: planDetail.id,
        name: planDetail.name,
        subName: planDetail.subName,
        description: planDetail.description,
        perPersonMonthlyPrice: planDetail.perPersonMonthlyPrice,
        extraBalconyCost: planDetail.extraBalconyCost,
        extraBathroomCost: planDetail.extraBathroomCost,
        imageId: planDetail.image && planDetail.image.id?planDetail.image.id:null,
        threeDImageUrl: planDetail.threeDImageUrl
      });

      let arrayLocalImages = [];
      if(planDetail.image && planDetail.image.id){
        arrayLocalImages.push(planDetail.image);
      }
      this.setState({planDetail, arrayLocalImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:"NEWS_IMAGE"}).then((response) => {
        this.setState({imageUploading: false, arrayLocalImages: []});
        var payload = response.value.data.data;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					this.props.change('imageId', payload.id);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/plans/list">Plans</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Plan</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Plan title"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="subName"
                  component={renderField}
                  type="text"
                  label="Sub title"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-12">
                <Field
                  name="description"
                  component={renderTextarea}
                  label="Description"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <Field
                  name="perPersonMonthlyPrice"
                  component={renderField}
                  type="text"
                  label="Per Person Price"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-4">
                <Field
                  name="extraBathroomCost"
                  component={renderField}
                  type="text"
                  label="Attached bathroom cost"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-4">
                <Field
                  name="extraBalconyCost"
                  component={renderField}
                  type="text"
                  label="Attached balcony cost"
                  normalize={normalizeAmountField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Field
                  name="threeDImageUrl"
                  component={renderField}
                  type="text"
                  label="3D image url (Do verify the url before adding)"

                />
              </div>
              <div className=" col-lg-6">
                <ImageUploader
  								className={this.state.imageUploading?"loading":""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText='Add Image'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
  							/>
                <Field
                  name="imageId"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImages.length > 0 &&
                  this.state.arrayLocalImages.map((obj, index) => {
                    return(
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.original} alt=""/></figure>
    									</div>
                    )
                  })
                }
              </div>
          </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addPlan: (data) => {
      return dispatch(addPlan(data));
    },
    fetchPlan: (data) => {
      return dispatch(fetchPlan(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    }
  }
}

AddPlan = connect(null, mapDispatchToProps)(AddPlan);

AddPlan = reduxForm({
  form: 'AddPlan',
  validate,
  //enableReinitialize: true
})(AddPlan);

export default AddPlan;
