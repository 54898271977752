import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_COMFORTS = "FETCH_COMFORTS";
export const FETCH_COMFORT = 'FETCH_COMFORT';
export const ADD_COMFORT = 'ADD_COMFORT';
export const DELETE_COMFORT = 'DELETE_COMFORT';

export function fetchComforts(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getDeliveryComforts`
  });

  return {
    type: FETCH_COMFORTS,
    payload: request
  };
}
export function fetchComfort(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getDeliveryComfortById`
  });

  return {
    type: FETCH_COMFORT,
    payload: request
  };
}

export function addComfort(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addDeliveryComfort`
  });
  return {
    type: ADD_COMFORT,
    payload: request
  };
}

export function deleteComfort(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteDeliveryComfort`
  });
  return {
    type: DELETE_COMFORT,
    payload: request
  };
}
