import axios from 'axios';

import { API_ROOT } from '../constants';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_CURRENT_ANALYTICS = 'FETCH_CURRENT_ANALYTICS';
export const FETCH_BASIC_ANALYTICS = 'FETCH_BASIC_ANALYTICS';
export const FETCH_BASIC_REVENUE_ANALYTICS = 'FETCH_BASIC_REVENUE_ANALYTICS';

export function uploadFile(formValues, queryParams) {
  const request = axios({
    method: 'POST',
    params: queryParams,
    data: formValues,
    url: `${API_ROOT}/common/uploadFile`
  });
  return {
    type: UPLOAD_FILE,
    payload: request
  };
}




export function fetchCountries(formValues) {

  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/serviceProvider/getCountryList`
  });
  return {
    type: FETCH_COUNTRIES,
    payload: request
  };
}


export function updateSettings(formValues) {
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${API_ROOT}/serviceProvider/updateSettings`
  });
  return {
    type: UPDATE_SETTINGS,
    payload: request
  };
}

export function fetchSettings(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/serviceProvider/getSettings`
  });
  return {
    type: FETCH_SETTINGS,
    payload: request
  };
}

export function fetchCurrentAnalytics(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/dashboard/getCurrentAnalytics`,
  });
  return {
    type: FETCH_CURRENT_ANALYTICS,
    payload: request
  };
}

export function fetchBasicOrderAnalytics(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/dashboard/getBasicOrderAnalytics`,
  });
  return {
    type: FETCH_BASIC_ANALYTICS,
    payload: request
  };
}

export function fetchBasicRevenueAnalytics(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/dashboard/getBasicRevenueAnalytics`,
  });
  return {
    type: FETCH_BASIC_REVENUE_ANALYTICS,
    payload: request
  };
}
