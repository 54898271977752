import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addLandmark, fetchLandmark, fetchLocalities, fetchLandmarkTypes} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_COUNTRY_ID, AMENITY_TYPES} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.title || values.title.trim() === '') {
    errors.title = "Enter duration to landmark";
    hasErrors = true;
  }

  if (!values.subTitle || values.subTitle.trim() === '') {
    errors.subTitle = "Enter landmark";
    hasErrors = true;
  }

  if (!values.type || values.type === '') {
    errors.type = "Select landmark type";
    hasErrors = true;
  }


  return hasErrors && errors;
}

class AddLandmark extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.landmarkId,
      landmarkDetail: null,
      localities: [],
      landmarkTypes: []
    }
  }

  submitForm(values, dispatch, props){
    return this.props.addLandmark(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchLandmarkTypes();
    this.fetchLocalities();
    if(this.state.id){
      this.fetchLandmark(this.state.id);
    }
  }






  fetchLandmark(id){
		this.props.fetchLandmark({id}).then((response) => {
      let landmarkDetail = response.value.data.data;
      this.props.initialize({
        id: landmarkDetail.id,
        title: landmarkDetail.title,
        subTitle: landmarkDetail.subTitle,
        localityId: landmarkDetail.locality.id,
        localityLandmarkTypeId: landmarkDetail.localityLandmarkType?landmarkDetail.localityLandmarkType.id:null
      });


      this.setState({landmarkDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  fetchLocalities(){
		this.setState({fetchingRecords: true});
    this.props.fetchLocalities({getAll: 1}).then((response) => {
      let localities = response.value.data.data.localities;
      localities.forEach((obj, index) => {
        //alert(localities[index].)
        localities[index] = Object.assign(localities[index], {label: localities[index].name+" ("+localities[index].city.name+"->"+localities[index].country.name +")"})
      })
      this.setState({fetchingRecords: false, localities: localities});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchLandmarkTypes(){
		this.setState({fetchingRecords: true});
    this.props.fetchLandmarkTypes({getAll: 1}).then((response) => {
      let landmarkTypes = response.value.data.data.localityLandmarkTypes;
      this.setState({fetchingRecords: false, landmarkTypes: landmarkTypes});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/landmarks/list?pageNumber=1">Landmarks</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Landmark</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-12">
                <Field
                  label="Select locality"
                  name='localityId'
                  optionLabel='label'
                  optionValue='id'
                  options={this.state.localities}
                  component={renderReactSelect}
                  placeholder="Select locality"
                  multi={false}
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  name="title"
                  component={renderField}
                  type="text"
                  label="Time to landmark (e.g. 60 mins)"
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  name="subTitle"
                  component={renderField}
                  type="text"
                  label="Landmark"
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  label="Select landmark type"
                  name='localityLandmarkTypeId'
                  optionLabel='name'
                  optionValue='id'
                  options={this.state.landmarkTypes}
                  component={renderReactSelect}
                  placeholder="Select landmark type"
                  multi={false}
                />
              </div>


            </div>

            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addLandmark: (data) => {
      return dispatch(addLandmark(data));
    },
    fetchLandmark: (data) => {
      return dispatch(fetchLandmark(data));
    },
    fetchLocalities: (data) => {
      return dispatch(fetchLocalities(data));
    },
    fetchLandmarkTypes: (data) => {
      return dispatch(fetchLandmarkTypes(data));
    }
  }
}

AddLandmark = connect(null, mapDispatchToProps)(AddLandmark);

AddLandmark = reduxForm({
  form: 'AddLandmark',
  validate,
  //enableReinitialize: true
})(AddLandmark);

export default AddLandmark;
