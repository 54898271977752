import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {fetchTax, addTax} from '../../../actions/taxes';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeAmountField} from '../../../constants';
import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if(!values.rate || values.rate == ''){
    errors.rate = "Enter rate";
    hasErrors = true;
  }

  if(!values.title || values.title.trim() == ''){
    errors.title = "Enter title";
    hasErrors = true;
  }

  if(!values.amountFrom || values.amountFrom == ''){
    errors.amountFrom = "Enter amount from";
    hasErrors = true;
  }else if(parseFloat(values.amountFrom) <= 0){
    errors.amountFrom = "Amout must be greater than 0";
    hasErrors = true;
  }
  if(!values.amountTo || values.amountTo == ''){
    errors.amountTo = "Enter amount to";
    hasErrors = true;
  }else if(parseFloat(values.amountTo) <= 0){
    errors.amountTo = "Amout must be greater than 0";
    hasErrors = true;
  }

  if(values.amountTo && values.amountFrom && parseFloat(values.amountTo) <= parseFloat(values.amountFrom)){
    errors.amountFrom = "Amount from should be less than amout to";
    hasErrors = true;
  }





  return hasErrors && errors;
}

class AddTax extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.taxId?props.match.params.taxId:null
    }
  }



  submitForm(values, dispatch, props){
    this.setState({submitting: true});
    return this.props.addTax(values)
    .then((response) => {
      this.setState({submitting: false});
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      this.setState({submitting: false});
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }





  componentDidMount(){
    if(this.state.id){
      this.fetchTax(this.state.id);
    }
  }

  fetchTax(id){
		this.setState({fetchingRecords: true});
    this.props.fetchTax({id}).then((response) => {
      let taxGridData = response.value.data.data;
      this.props.initialize({id: taxGridData.id, amountFrom: taxGridData.amountFrom, amountTo: taxGridData.amountTo, rate: taxGridData.rate, title: taxGridData.title});
      this.setState({fetchingRecords: false});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const {submitting} = {...this.state};
    const { handleSubmit, pristine, reset, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/settings/taxes/list">Tax Grid</Link></li>
        <li className="breadcrumb-item active">Add Tax</li>
      </ol>
        <h5 className="h5 mb-4">Add Tax</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  name="rate"
                  component={renderField}
                  label="Rate"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="title"
                  component={renderField}
                  label="Title"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="amountFrom"
                  component={renderField}
                  type="text"
                  label="Amount From"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="amountTo"
                  component={renderField}
                  type="text"
                  label="Amout To"
                  normalize={normalizeAmountField}
                />
              </div>

            </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTax: (data) => {
      return dispatch(fetchTax(data));
    },
    addTax: (data) => {
      return dispatch(addTax(data));
    }
  }
}

AddTax = connect(null, mapDispatchToProps)(AddTax);

AddTax = reduxForm({
  form: 'AddTax',
  validate,
  //enableReinitialize: true
})(AddTax);

export default AddTax;
