import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import {fetchTaxes, deleteTax} from '../../../actions/taxes';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat} from '../../../constants';
import Confirm from 'react-confirm-bootstrap';

import $ from 'jquery';

class TaxListing extends Component {
	constructor(props){
    super(props);
		this.state = {

		};

  }

	componentDidMount(){
    this.fetchTaxes(this.state.params);
  }

	fetchTaxes(params){
		this.setState({fetchingRecords: true});
    this.props.fetchTaxes(params).then((response) => {
			this.setState({records: response.value.data.data, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	deleteRecord(recordid){
    this.props.deleteTax({id: recordid}).then((response) => {
			toast.success(response.value.data.message);
      this.fetchTaxes();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/settings/taxes/list?pageNumber=1">Taxes</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Tax grid</h5>
					</div>
					<div className="col-md-8">
            <div className="jobs-act-btns">
							<Link to="/admin/settings/taxes/list/add" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Tax</Link>
						</div>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
                  <th>Amount from</th>
                  <th>Amount to</th>
									<th>Rate</th>
									<th>Tax title</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.taxes && records.taxes.length > 0 &&
							records.taxes.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td className="nowrap">{obj.id}</td>
										<td className="nowrap">{currencyFormat(parseFloat(obj.amountFrom).toFixed(2))}</td>
                    <td className="nowrap">{currencyFormat(parseFloat(obj.amountTo).toFixed(2))}</td>
										<td className="nowrap">{obj.rate}%</td>
										<td>{obj.title}</td>
                    <td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/settings/taxes/list/edit/"+obj.id} >Edit</Link></li>
													<li><Confirm className="confirmPopup"
															onConfirm={() => this.deleteRecord(obj.id)}
															body="Are you sure you want to delete this?"
															confirmText="Confirm Delete"
															title="Delete!">
															<button className="dropdown-item">Delete</button>
													</Confirm></li>

												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>

			</>

    );
	}
}

TaxListing = reduxForm({
  form: 'TaxListingFilter'
})(TaxListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTaxes: (data) => {
      return dispatch(fetchTaxes(data));
    },
		deleteTax: (data) => {
			return dispatch(deleteTax(data));
		}
  }
}


export default withRouter(connect(null, mapDispatchToProps)(TaxListing));
