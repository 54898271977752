/*global google*/
import React, { Component } from "react";
import {
  Marker,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  DirectionsRenderer
} from "react-google-maps";

class MapWithMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directions: null,
      origin: props.origin?props.origin:null,

      mapWidth: props.mapWidth?props.mapWidth:'500px',
      mapHeight: props.mapHeight?props.mapHeight:'500px'
    };
  }


  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    if(this.props.updateMapTime != nextProps.updateMapTime){
      if(nextProps.origin){
        this.setState({origin: nextProps.origin});
        //this.updateMap(nextProps.origin);
      }
    }
  }







  render() {
    console.log('aaaaa', this.state.origin);
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={this.state.origin?this.state.origin:{ lat: 25.276987, lng: 55.296249 }}
        defaultZoom={13}
        
      >

        {
          this.state.origin &&
          <Marker
            position={this.state.origin}
            icon={{url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", size: {width: 50, height: 50}}}
          />
        }

      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: this.state.mapHeight, width: this.state.mapWidth }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default MapWithMarker;
