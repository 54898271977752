import React, { Component, Fragment } from 'react';
import { Route, Link, NavLink, withRouter } from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {ROUTES} from '../../routesList';


class LeftNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: props.user,
      currrentUrl: props.location.pathname,
      permissions: props.user.user.permissions
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.pathname != nextProps.location.pathname){
      this.setState({currrentUrl: nextProps.location.pathname});
    }
  }

  componentDidMount(){
  }

  render() {
    console.log('ooooo', this.state.user.user);
    return (
      <div id="sideNav">
        <nav className="navbar navbar-expand-md navbar-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav custScroll">
              <li className={(this.state.currrentUrl == '/')?'active nav-item': 'nav-item'}><Link to="/" className="nav-link"><span className="icon icon-ic-dashboard"></span><span className="nav-txt">Dashboard</span></Link></li>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "USER_MANAGEMENT"]}
                oneperm
              >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/user-management") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#user-management"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Users Management</span></a>
                  <ul id="user-management" className={(this.state.currrentUrl.indexOf("/user-management") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/user-management/users/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/user-management/users/list?pageNumber=1" className="nav-link"><span className="nav-txt">Users</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/user-management/roles/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/user-management/roles/list" className="nav-link"><span className="nav-txt">Roles</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "CUSTOMER_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf('/admin/customers/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/customers/list?pageNumber=1" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Customers</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "BOOKINGS_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/bookings/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/bookings/list?pageNumber=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Bookings</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "REVIEW_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/rating-reviews/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/rating-reviews/list?pageNumber=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Reviews</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "SPACE_MANAGEMENT"]}
                oneperm
              >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/space-management") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'} href="javascript:;" data-toggle="collapse" data-target="#space-management"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Space Management</span></a>
                  <ul id="space-management" className={(this.state.currrentUrl.indexOf("/space-management") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/cities/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/cities/list?pageNumber=1" className="nav-link"><span className="nav-txt">Cities</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/localities/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/localities/list?pageNumber=1" className="nav-link"><span className="nav-txt">Localities</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/buildings/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/buildings/list?pageNumber=1" className="nav-link"><span className="nav-txt">Buildings</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/blocks/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/blocks/list?pageNumber=1" className="nav-link"><span className="nav-txt">Blocks</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/flats/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/flats/list?pageNumber=1" className="nav-link"><span className="nav-txt">Flats</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/rooms/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/rooms/list?pageNumber=1" className="nav-link"><span className="nav-txt">Rooms</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/beds/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/beds/list?pageNumber=1" className="nav-link"><span className="nav-txt">Beds</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/flatTypes/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/flatTypes/list?pageNumber=1" className="nav-link"><span className="nav-txt">Flat Type</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/amenities/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/amenities/list?pageNumber=1" className="nav-link"><span className="nav-txt">Amenities</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/space-management/landmarks/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/space-management/landmarks/list?pageNumber=1" className="nav-link"><span className="nav-txt">Landmarks</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "PLAN_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/plans/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/plans/list" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Manage Plans</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "CALLBACK_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/callback-requests/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/callback-requests/list?pageNumber=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Callback Requests</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "EVENT_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/events/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/events/list?pageNumber=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Events</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "NEWS_MANAGEMENT"]}
                oneperm
              >
                <li className={(this.state.currrentUrl.indexOf("/admin/news/list") !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/news/list?pageNumber=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">News</span></Link></li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "SETTINGS_MANAGEMENT"]}
                oneperm
              >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/admin/settings") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'} href="javascript:;" data-toggle="collapse" data-target="#settings"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Settings</span></a>
                  <ul id="settings" className={(this.state.currrentUrl.indexOf("/admin/settings") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/taxes/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/taxes/list?pageNumber=1" className="nav-link"><span className="nav-txt">Taxes</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/banners/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/banners/list?pageNumber=1" className="nav-link"><span className="nav-txt">Banners</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/comforts/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/comforts/list?pageNumber=1" className="nav-link"><span className="nav-txt">Comforts</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/page/terms-conditions') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/page/terms-conditions" className="nav-link"><span className="nav-txt">Terms & Conditions</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/page/privacy-policy') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/page/privacy-policy" className="nav-link"><span className="nav-txt">Privacy Policy</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/page/about-us') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/page/about-us" className="nav-link"><span className="nav-txt">About us</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/page/kyc-policy') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/page/kyc-policy" className="nav-link"><span className="nav-txt">KYC Policy</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/page/how-it-works') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/page/how-it-works" className="nav-link"><span className="nav-txt">How It Works</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/settings/websiteSettings/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings/websiteSettings" className="nav-link"><span className="nav-txt">Website Wide Settings</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
              <PermissibleRender
                userPermissions={this.state.permissions}
                requiredPermissions={["SERVICEPROVIDER", "REPORT_MANAGEMENT"]}
                oneperm
              >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/admin/reports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'} href="javascript:;" data-toggle="collapse" data-target="#reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Reports</span></a>
                  <ul id="reports" className={(this.state.currrentUrl.indexOf("/admin/reports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/reports/beds/list') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/beds/list?pageNumber=1" className="nav-link"><span className="nav-txt">Beds</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/reports/bookings/list') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/bookings/list?pageNumber=1" className="nav-link"><span className="nav-txt">Bookings</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/reports/customers/list') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/customers/list?pageNumber=1" className="nav-link"><span className="nav-txt">Customers</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
              <li className="nav-item"><Link to="/logout" className="nav-link"><span className="icon icon-ic-logout"></span><span className="nav-txt">Logout</span></Link></li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default withRouter(LeftNavigation);
