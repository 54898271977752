import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {fetchCurrentAnalytics, fetchBasicOrderAnalytics, fetchBasicRevenueAnalytics} from '../../actions/common';
import {CURRENCY, currencyFormat} from '../../constants';
import {toast} from 'react-toastify';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



class Dashboard extends Component {
	constructor(props){
    super(props);
		this.state = {
			permissions: props.user && props.user.user?props.user.user.permissions:[],
      dataLoaded: false,
			selectedTab: 2,
			days: 7,
			analyticsData: null,
			basicAnalyticsData: null,
			basicRevenueAnalyticsData: null,
			revenueGraphOptions: null,
		}
  }

	selectTab(e, tab, days){
		if(e){
			e.preventDefault();
		}
		this.fetchBasicOrderAnalytics(days);
		let revenueDetails = this.state.basicRevenueAnalyticsData.revenueDetails['day_'+days].dataToSend;
		this.prepareDataForRevenueGraph(revenueDetails);

		this.setState({selectedTab: tab, days})
	}


	componentDidMount(){

		if(this.state.permissions.indexOf("SERVICEPROVIDER") !== -1 || this.state.permissions.indexOf("DASHBOARD_VIEW") !== -1){
			this.fetchCurrentAnalytics();
			this.fetchBasicOrderAnalytics(this.state.days);
			this.fetchBasicRevenueAnalytics();
		}


	}

	fetchCurrentAnalytics(){
		this.setState({fetchingRecords: true});
    this.props.fetchCurrentAnalytics().then((response) => {
			let data = response.value.data.data;
      this.setState({fetchingRecords: false, analyticsData: data});
		}).catch((error) => {
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchBasicOrderAnalytics(dayz){
		this.setState({fetchingRecords: true});
    this.props.fetchBasicOrderAnalytics({dayz}).then((response) => {
			let data = response.value.data.data;
      this.setState({fetchingRecords: false, basicAnalyticsData: data});
		}).catch((error) => {
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchBasicRevenueAnalytics(){
		this.setState({fetchingRecords: true});
    this.props.fetchBasicRevenueAnalytics().then((response) => {
			let data = response.value.data.data;
			let revenueDetails = data.revenueDetails['day_'+this.state.days].dataToSend;
			this.prepareDataForRevenueGraph(revenueDetails);

      this.setState({fetchingRecords: false, basicRevenueAnalyticsData: data});
		}).catch((error) => {
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	prepareDataForRevenueGraph(revenueDetails){
		var dayz = [];
		var revenue = [];
		var completedorder = [];
		//console.log('rrrrrrrr', revenueDetails);

		if(revenueDetails && revenueDetails.length > 0){
			revenueDetails.map((obj, index) => {
				revenue.push(parseFloat(obj.revenue));
				completedorder.push(obj.completedorder);
				dayz.push(obj._id);
			});
		}

		//console.log('rrrrrrrr', revenue, completedorder, dayz);
		var revenueGraphOptions = {
			chart: {
				type: 'areaspline'
			},
			title: {
					text: undefined
			},
			legend: {
					layout: 'vertical',
					align: 'left',
					verticalAlign: 'top',
					x: 150,
					y: 100,
					floating: true,
					borderWidth: 1,
					backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
			},
			xAxis: {
				categories: dayz
			},

			yAxis: [{
					min: 0,
					title: {
							text: 'Revenue'
					}
			}, {
					title: {
							text: 'Bookings'
					},
					opposite: true
			}],

			tooltip: {
					shared: true
			},
			credits: {
					enabled: false
			},
			plotOptions: {
					areaspline: {
							fillOpacity: 0.5
					}
			},
			series: [{
					name: 'Revenue',
					color: 'rgba(165,170,217,1)',
					data: revenue,
					tooltip: {
							valuePrefix: CURRENCY+" "
					}
			},
			{
					name: 'Bookings',
					color: 'rgba(248,161,63,1)',
					data: completedorder,
					yAxis: 1
			}]


		};

		this.setState({revenueGraphOptions});
	}


	render() {
		const {analyticsData, basicAnalyticsData} = {...this.state};
		return (
      <>
				<h5 className="mb-3 marginT20">Dashboard</h5>
				{
					(!(this.state.permissions.indexOf("SERVICEPROVIDER") !== -1 || this.state.permissions.indexOf("DASHBOARD_VIEW") !== -1))
					?
					<></>
					:
					<>
					<section className="formBg marginB20" >
						<div style={{position: "relative", float: "none"}}>
							<div className="row">
								<div className="col-md-6 col-xl-4">
									<Link to = "/admin/bookings/list?pageNumber=1">
			            	<div className="card mb-3 widget-content bg-arielle-smile">
			                <div className="widget-content-wrapper text-white">
			                	<div className="widget-content-left">
			                  	<div className="widget-heading">New Booking Requests</div>
												</div>
			                  <div className="widget-content-right">
			                  	<div className="widget-numbers text-white"><span>{analyticsData && analyticsData.newBookingRequests}</span></div>
			                  </div>
			                </div>
			              </div>
									</Link>
		            </div>
		            <div className="col-md-6 col-xl-4">
									<Link to = "/admin/callback-requests/list?pageNumber=1">
			              <div className="card mb-3 widget-content bg-midnight-bloom">
			                <div className="widget-content-wrapper text-white">
			                  <div className="widget-content-left">
			                    <div className="widget-heading">Callback Requests</div>
												</div>
			                  <div className="widget-content-right">
			                  	<div className="widget-numbers text-white"><span>{analyticsData && analyticsData.callbackRequests}</span></div>
			                  </div>
			                </div>
			              </div>
									</Link>
		            </div>
							</div>
							<div className="row">
								<div className="col-md-6 col-xl-4">
									<Link to = "/admin/bookings/list?pageNumber=1">
									<div className="card mb-3 widget-content bg-midnight-bloom">
										<div className="widget-content-wrapper text-white">
											<div className="widget-content-left">
												<div className="widget-heading">Active Bookings</div>
											</div>
											<div className="widget-content-right">
												<div className="widget-numbers text-white"><span>{analyticsData && analyticsData.activeBooking}</span></div>
											</div>
										</div>
									</div>
								</Link>
							</div>
		          <div className="col-md-6 col-xl-4">
								<Link to = "/admin/space-management/beds/list?pageNumber=1">
			          	<div className="card mb-3 widget-content bg-arielle-smile">
										<div className="widget-content-wrapper text-white">
											<div className="widget-content-left">
												<div className="widget-heading">Total Beds</div>
											</div>
											<div className="widget-content-right">
												<div className="widget-numbers text-white"><span>{analyticsData && analyticsData.beds}</span></div>
											</div>
										</div>
									</div>
								</Link>
							</div>
							<div className="col-md-6 col-xl-4">
								<Link to = "/admin/space-management/beds/list?pageNumber=1">
								<div className="card mb-3 widget-content bg-grow-early">
									<div className="widget-content-wrapper text-white">
										<div className="widget-content-left">
											<div className="widget-heading">Assigned Beds</div>
										</div>
										<div className="widget-content-right">
											<div className="widget-numbers text-white"><span>{analyticsData && analyticsData.assignedBeds}</span></div>
										</div>
									</div>
								</div>
							</Link>
						</div>
						{
							analyticsData && analyticsData.plans && analyticsData.plans.length > 0 &&
							analyticsData.plans.map((obj, index) => {
								return(
									<div className="col-md-6 col-xl-4" key = {index}>
										<Link to = "/admin/bookings/list?pageNumber=1">
				            	<div className={`${"card mb-3 widget-content"} ${index % 3 == 0?"bg-arielle-smile":index % 3 == 1?"bg-grow-early":index % 3 == 2?"bg-midnight-bloom":''}`}>
				              	<div className="widget-content-wrapper text-white">
				                	<div className="widget-content-left">
				                  	<div className="widget-heading">{obj.plan.title}</div>
														<div class="widget-subheading">Active bookings</div>
														<div class="widget-subheading">Total beds</div>
														<div class="widget-subheading">Assigned beds</div>
				                  </div>
				                  <div className="widget-content-right">
														<div className="widget-numbers text-white small-text">&nbsp;</div>
				                  	<div className="widget-numbers text-white small-text"><span>{obj.activeBooking}</span></div>
														<div className="widget-numbers text-white small-text"><span>{obj.beds}</span></div>
														<div className="widget-numbers text-white small-text"><span>{obj.assignedBeds}</span></div>
				                  </div>
				                </div>
				             	</div>
										</Link>
			            </div>
								)
							})
						}
						</div>
					</div>

					</section>
					<section className="formBg" >
						<div style={{position: "relative", float: "none"}}>
							<div className="steps">
								<ul style = {{paddingLeft: 0}}>
									<li className={`${"step-2"} ${this.state.selectedTab == 2?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 2, 7)}><span>Last 7 Days</span></a></li>
									<li className={`${"step-3"} ${this.state.selectedTab == 3?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 3, 30)}><span>Last 30 Days</span></a></li>
									<li className={`${"step-3"} ${this.state.selectedTab == 4?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 4, 365)}><span>Last 1 Year</span></a></li>
								</ul>
							</div>
							{/* <h5 className="mb-3">Order Analytics</h5> */}
							<div className="row">
		            <div className="col-md-6 col-xl-4">
									<Link to = "/admin/transactions/list?pageNumber=1">
			              <div className="card mb-3 widget-content bg-midnight-bloom">
			                <div className="widget-content-wrapper text-white">
			                  <div className="widget-content-left">
			                    <div className="widget-heading">Total Revenue</div>
			                    <div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":"In last 30 days"}</div>
			                  </div>
			                  <div className="widget-content-right">
			                  	<div className="widget-numbers text-white"><span>{basicAnalyticsData != null && <><span style={{fontSize: '20px'}}>{CURRENCY}</span> {currencyFormat(parseFloat(basicAnalyticsData.revenue).toFixed(0))}</>}</span></div>
			                  </div>
			                </div>
			              </div>
									</Link>
		            </div>
		            <div className="col-md-6 col-xl-4">
									<Link to = "/admin/bookings/list?pageNumber=1">
		              	<div className="card mb-3 widget-content bg-arielle-smile">
		                	<div className="widget-content-wrapper text-white">
		                  	<div className="widget-content-left">
		                    	<div className="widget-heading">Completed Bookings</div>
		                      	<div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":this.state.selectedTab == 3?"In last 30 days":"In last one year"}</div>
		                      </div>
		                      <div className="widget-content-right">
		                       <div className="widget-numbers text-white"><span>{basicAnalyticsData != null && <>{basicAnalyticsData.completedBookings}</>}</span></div>
		                     	</div>
		                   	</div>
		                </div>
									</Link>
		            </div>
								<div className="col-md-6 col-xl-4">
									<Link to = "/admin/bookings/list?pageNumber=1">
								    <div className="card mb-3 widget-content bg-grow-early">
								    	<div className="widget-content-wrapper text-white">
								      	<div className="widget-content-left">
								        	<div className="widget-heading">Cancelled Bookings</div>
								          <div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":this.state.selectedTab == 3?"In last 30 days":"In last one year"}</div>
								         	</div>
								         <div className="widget-content-right">
								         	<div className="widget-numbers text-white"><span>{basicAnalyticsData != null && <>{basicAnalyticsData.canceledBookings}</>}</span></div>
								         </div>
								       </div>
								   </div>
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12">
								<div className="mb-3 card">
									{
										this.state.revenueGraphOptions != null &&
										<HighchartsReact
									    highcharts={Highcharts}
									    options={this.state.revenueGraphOptions}
									  />
									}

								</div>
							</div>
						</div>
						</div>
					</section>
					</>

				}

			</>
    );
	}
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCurrentAnalytics: (data) => {
      return dispatch(fetchCurrentAnalytics(data));
    },
		fetchBasicOrderAnalytics: (data) => {
			return dispatch(fetchBasicOrderAnalytics(data));
		},
		fetchBasicRevenueAnalytics: (data) => {
			return dispatch(fetchBasicRevenueAnalytics(data));
		}

  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
