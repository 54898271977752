import axios from 'axios';
import { API_ROOT } from '../constants';

export const BEDS_REPORT = "BEDS_REPORT";
export const BOOKINGS_REPORT = 'BOOKINGS_REPORT';
export const CUSTOMERS_REPORT = 'CUSTOMERS_REPORT';

export function bedsReport(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBedsReporting`
  });

  return {
    type: BEDS_REPORT,
    payload: request
  };
}

export function bookingsReport(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBookingsReporting`
  });

  return {
    type: BOOKINGS_REPORT,
    payload: request
  };
}

export function customersReport(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCustomersReporting`
  });

  return {
    type: CUSTOMERS_REPORT,
    payload: request
  };
}
