import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchBookings, deleteBooking, assignBed} from '../../../actions/bookings';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Confirm from 'react-confirm-bootstrap';
import {currencyFormat, CURRENCY, BOOKINGS_STATUSES} from '../../../constants';
import {fetchPlans} from '../../../actions/plans';
import moment from 'moment';
import CancelBookingPopup from './CancelBookingPopup';
import AssignBedManuallyPopup from './AssignBedManuallyPopup';
import RefundPopup from './RefundPopup';

import $ from 'jquery';

class BookingsListing extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/bookings/list',
			records: null,
			plans: [],
			openCancelBookingPopup: false,
			openAssignBedManuallyPopup: false,
			openRefundPopup: false,
			bookingData: null,
			filters: [
				'orderId',
				'status',
				'planId',
				'customerName',
				'phoneNumber',
				'securityRefunded'
			]
		};

		this.openCancelBookingPopup = this.openCancelBookingPopup.bind(this);
    this.closeCancelBookingPopup = this.closeCancelBookingPopup.bind(this);

		this.openRefundPopup = this.openRefundPopup.bind(this);
    this.closeRefundPopup = this.closeRefundPopup.bind(this);

		this.openAssignBedManuallyPopup = this.openAssignBedManuallyPopup.bind(this);
    this.closeAssignBedManuallyPopup = this.closeAssignBedManuallyPopup.bind(this);

		this.reloadBookings = this.reloadBookings.bind(this);
  }

	reloadBookings(){
		this.fetchBookings(this.state.params);
	}

	openCancelBookingPopup(bookingData){
    this.setState({openCancelBookingPopup: true, bookingData});
  }

  closeCancelBookingPopup(){
    this.setState({openCancelBookingPopup: false, bookingData: null});
  }

	openAssignBedManuallyPopup(bookingData){
    this.setState({openAssignBedManuallyPopup: true, bookingData});
  }

  closeAssignBedManuallyPopup(){
    this.setState({openAssignBedManuallyPopup: false, bookingData: null});
  }

	openRefundPopup(bookingData){
    this.setState({openRefundPopup: true, bookingData});
  }

  closeRefundPopup(){
    this.setState({openRefundPopup: false, bookingData: null});
  }

	assignBed(orderId){
		this.setState({fetchingRecords: true});
    this.props.assignBed({orderId}).then((response) => {
      this.setState({fetchingRecords: false});
      this.fetchBookings(this.state.params);
		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	getStatusOption(status){
		for(var i = 0; i < BOOKINGS_STATUSES.length; i++){
			if(status == BOOKINGS_STATUSES[i].id){
				return BOOKINGS_STATUSES[i].name;
			}
		}
	}

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		this.fetchPlans();
		this.fetchBookings(this.state.params);
		this.updateFilterForm(this.state.params);
	}

	fetchPlans(){
		let params = {isAll: 1};
		this.setState({fetchingRecords: true});
    this.props.fetchPlans(params).then((response) => {
      this.setState({fetchingRecords: false});
      var plans = response.value.data.data.plans;
			this.setState({plans});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				if(obj == 'planId' || obj == 'status' || obj == 'securityRefunded'){
					formValues[obj] = parseInt(params[obj]);
				}else{
					formValues[obj] = params[obj];
				}
			}
		});
		this.props.initialize(formValues);
	}



	fetchBookings(params){
		params = Object.assign(params, {isAll: 0});
		this.setState({fetchingRecords: true});
    this.props.fetchBookings(params).then((response) => {
      this.setState({fetchingRecords: false});
      var records = response.value.data.data;
      this.setState({records});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchBookings(params);
		}
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){

		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}

	cancelBooking(orderId){

	}




	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
			<>

        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/bookings/list?pageNumber=1">Bookings</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Bookings</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>

            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="orderId"
										component={renderField}
										label="Booking ID"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="customerName"
										component={renderField}
										label="Customer Name"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="phoneNumber"
										component={renderField}
										label="Phone"
									/>
								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Booking Status"
		                name='status'
		                optionLabel='name'
		                optionValue='id'
		                options={BOOKINGS_STATUSES}
		                component={renderReactSelect}
		                placeholder="Select status"
		                multi={false}
		               />
		          	</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Plan"
										name='planId'
										optionLabel='name'
										optionValue='id'
										options={this.state.plans}
										component={renderReactSelect}
										placeholder="Select plan.."
										multi={false}
									/>

								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Security refunded?"
		                name='securityRefunded'
		                optionLabel='name'
		                optionValue='id'
		                options={[{id: 1, name:'Yes'}, {id: 0, name:'No'}]}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>Booking ID</th>
									<th>Name</th>
									<th>Phone</th>
									<th>Email</th>
									<th>Status</th>
									<th>Plan</th>
									<th>Checkin</th>
									<th>Checkout</th>
									<th>Toal Amount</th>
									<th>Security</th>
									<th>Bathroom</th>
									<th>Balcony</th>

									<th>Locality</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.orders && records.orders.length > 0 &&
							records.orders.map((obj, index) => {
								return(
									<tr key={obj.orderId}>
										<td><Link to = {"/admin/bookings/list/detail/"+obj.orderId}>{obj.orderId}</Link></td>
										<td className="nowrap">{obj.customer.fullName}</td>
										<td className="nowrap">+{obj.customer.countryCode} {obj.customer.phoneNumber}</td>
										<td>{obj.customer.email}</td>
										<td className="nowrap">
											{
												obj.order.status == 0
												?
												<button className="btn-add ml-1  hvr-shadow" onClick = {() => this.assignBed(obj.orderId)}>Assign Bed</button>
												:
												(
													obj.order.status == 4
													||
													obj.order.status == 5
													||
													obj.order.status == 7
												) && (obj.order.security && !obj.order.security.isRefunded)
												?
												<button className="btn-add ml-1  hvr-shadow" onClick = {() => this.openRefundPopup(obj)}>Refund Security</button>
												:
												this.getStatusOption(obj.order.status)
											}
										</td>
										<td>{obj.planDetails.title}</td>
										<td className="nowrap">{moment(obj.order.checkinDate).format("DD MMM, YY")}</td>
										<td className="nowrap">{moment(obj.order.checkoutDate).format("DD MMM, YY")}</td>
										<td className="nowrap">{CURRENCY} {currencyFormat(parseFloat(obj.order.totalPrice).toFixed(2))}</td>
										<td className="nowrap">{obj.order && obj.order.security && obj.order.security.amount ? <>{CURRENCY} {currencyFormat(parseFloat(obj.order.security.amount).toFixed(2))}</>:'' }</td>
										<td>{obj.order.isAttachedBathroomRequired?"Yes":"No"}</td>
										<td>{obj.order.isAttachedBalconyRequired?"Yes":"No"}</td>
										<td className="nowrap">{obj.order.locality.name} [{obj.order.city.name}]</td>
										<td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="View" to = {"/admin/bookings/list/detail/"+obj.orderId} >Booking Detail</Link></li>
													{
														(
															obj.order.status == 0
															||
															obj.order.status == 1
															||
															obj.order.status == 3
														)
														?
														<li>
															<button className="dropdown-item" onClick = {() => this.openCancelBookingPopup(obj)}>Cancel Booking</button>
														</li>
														:
														<></>
													}
													{
														(
															obj.order.status == 0
															||
															obj.order.status == 1
															||
															obj.order.status == 3
														)
														?
														<li>
															<button className="dropdown-item" onClick = {() => this.openAssignBedManuallyPopup(obj)}>Assign/Reassign Bed</button>
														</li>
														:
														<></>
													}

												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.orders && records.orders.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			{
				this.state.openCancelBookingPopup &&
				<CancelBookingPopup
					bookingData={this.state.bookingData}
					openPopup={this.state.openCancelBookingPopup}
					closePopup = {this.closeCancelBookingPopup}
					callback = {this.reloadBookings}
				/>
			}
			{
				this.state.openAssignBedManuallyPopup &&
				<AssignBedManuallyPopup
					bookingData={this.state.bookingData}
					openPopup={this.state.openAssignBedManuallyPopup}
					closePopup = {this.closeAssignBedManuallyPopup}
					callback = {this.reloadBookings}
				/>
			}
			{
				this.state.openRefundPopup &&
				<RefundPopup
					bookingData={this.state.bookingData}
					openPopup={this.state.openRefundPopup}
					closePopup = {this.closeRefundPopup}
					callback = {this.reloadBookings}
				/>
			}
			</>
		);
	}
}

BookingsListing = reduxForm({
  form: 'BookingsListingFilter'
})(BookingsListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBookings: (data) => {
      return dispatch(fetchBookings(data));
    },
    deleteBooking: (data) => {
      return dispatch(deleteBooking(data));
    },
		assignBed: (data) => {
			return dispatch(assignBed(data));
		},
		fetchPlans: (data) => {
			return dispatch(fetchPlans(data));
		},
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BookingsListing));
