import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchReviews, updateReviewStatus} from '../../../actions/bookings';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat, USER_STATUS} from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';

import $ from 'jquery';

class ReviewsListing extends Component {
	constructor(props){
    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}
		if(!queryParams.sortBy){
			queryParams.sortBy = 'id';
		}
		if(!queryParams.sortOrder){
			queryParams.sortOrder = 'desc';
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/rating-reviews/list',
			records: null,
			filters: [
				'id',
        'fullName',
        'phoneNumber'
			]
		};
  }



	componentDidMount(){
    this.fetchReviews(this.state.params);
  }

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchReviews(params){
		this.setState({fetchingRecords: true});

		this.props.fetchReviews(params).then((response) => {
			this.setState({records: response.value.data.data, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchReviews(params);
		}
	}



	submitFilterForm(values, dispatch, props){
		var params = values;
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	sortResults(e, sortBy, sortOrder){
		if(e){
			e.preventDefault();
		}
		let params = this.state.params;
		params = Object.assign(params, {sortBy: sortBy, sortOrder: sortOrder});
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}

  updateReviewStatus(id, isFeatured){
    this.setState({fetchingRecords: true});
    this.props.updateReviewStatus({id, isFeatured}).then((response) => {
      this.setState({fetchingRecords: false});
      this.fetchReviews(this.state.params);
			toast.success(response.value.data.message);
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records, params} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/rating-reviews/list?pageNumber=1">Rating & Reviews</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Rating & Reviews</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="Review ID"
										component={renderField}
										label="Review ID"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="fullName"
										component={renderField}
										label="Customer Name"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="phoneNumber"
										component={renderField}
										label="Phone Number"
									/>
                </div>

              </div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th><a href = "javascript:;" className = {params.sortBy == 'id'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'id', params.sortOrder == 'desc'?'asc':'desc')}>ID</a></th>
                  <th><a href = "javascript:;" className = {params.sortBy == 'fullName'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'fullName', params.sortOrder == 'desc'?'asc':'desc')}>Name</a></th>
									<th><a href = "javascript:;" className = {params.sortBy == 'rating'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'rating', params.sortOrder == 'desc'?'asc':'desc')}>Rating</a></th>
                  <th>Review</th>
									<th><a href = "javascript:;" className = {params.sortBy == 'isFeatured'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'isFeatured', params.sortOrder == 'desc'?'asc':'desc')}>Published?</a></th>
									<th><a href = "javascript:;" className = {params.sortBy == 'orderId'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'orderId', params.sortOrder == 'desc'?'asc':'desc')}>Booking&nbsp;ID</a></th>
									<th><a href = "javascript:;" className = {params.sortBy == 'planName'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'planName', params.sortOrder == 'desc'?'asc':'desc')}>Plan</a></th>
									<th>Phone</th>
									<th>Date</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.reviews && records.reviews.length > 0 &&
							records.reviews.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td className="nowrap">{obj.id}</td>
										<td>{obj.customer.fullName}</td>
										<td>{obj.rating}</td>
										<td>{obj.review}</td>
										<td>
											<label className="switch">
												<input type="checkbox" checked = {obj.isFeatured == 1?true:false} onChange = {() => this.updateReviewStatus(obj.id, obj.isFeatured == 1?2:1, index)} />
											  <span className="slider round"></span>
											</label>
										</td>
										<td><a href = {"/admin/bookings/list/detail/"+obj.orderId} target = "_blank">{obj.orderId}</a></td>
										<td>{obj.plan.name}</td>
                    <td className="nowrap">+{obj.customer.countryCode} {obj.customer.phoneNumber}</td>
                    <td className="nowrap">{moment(obj.createdAt).format('DD MMM, Y')}</td>
										<td>
											<span className="dropdown threeDots">
	                    	<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
	                      	<span></span> <span></span> <span></span>
	                      </a>
	                      <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/rating-reviews/list/edit/"+obj.id} >Edit</Link></li>
												</ul>
	                  	</span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.reviews && this.state.records.reviews.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}

ReviewsListing = reduxForm({
  form: 'ReviewsListingFilter'
})(ReviewsListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReviews: (data) => {
      return dispatch(fetchReviews(data));
    },
    updateReviewStatus: (data) => {
			return dispatch(updateReviewStatus(data));
		},
  }
}


export default withRouter(connect(null, mapDispatchToProps)(ReviewsListing));
