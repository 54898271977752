import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKING_BY_ID = 'FETCH_BOOKING_BY_ID';
export const ADD_BOOKING = 'ADD_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const ASSIGN_BED = 'ASSIGN_BED';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const UPDATE_REVIEW_STATUS = 'UPDATE_REVIEW_STATUS';
export const FETCH_REVIEW = 'FETCH_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const FETCH_BOOKING_SUBSCRIPTION_DETAILS = 'FETCH_BOOKING_SUBSCRIPTION_DETAILS';
export const FETCH_BOOKING_SUBSCRIPTION_SCHEDULE = 'FETCH_BOOKING_SUBSCRIPTION_SCHEDULE';
export const PAY_SUBSCRIPTION_INVOICE = 'PAY_SUBSCRIPTION_INVOICE';
export const REFUND_SECURITY = 'REFUND_SECURITY';
export const REASSIGN_BED = 'REASSIGN_BED';
export const FETCH_BES_FOR_REASSIGNING = 'FETCH_BES_FOR_REASSIGNING';

export function fetchBookings(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getAllBookings`
  });

  return {
    type: FETCH_BOOKINGS,
    payload: request
  };
}
export function fetchBookingById(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBookingById`
  });

  return {
    type: FETCH_BOOKING_BY_ID,
    payload: request
  };
}

export function addBooking(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBooking`
  });
  return {
    type: ADD_BOOKING,
    payload: request
  };
}

export function deleteBooking(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBooking`
  });
  return {
    type: DELETE_BOOKING,
    payload: request
  };
}

export function assignBed(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/assignBedsToBooking`
  });
  return {
    type: ASSIGN_BED,
    payload: request
  };
}

export function cancelBooking(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/cancelBooking`
  });
  return {
    type: CANCEL_BOOKING,
    payload: request
  };
}

export function refundSecurity(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/refundSecurity`
  });
  return {
    type: REFUND_SECURITY,
    payload: request
  };
}

export function fetchReviews(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getRateAndReviews`
  });

  return {
    type: FETCH_REVIEWS,
    payload: request
  };
}

export function updateReviewStatus(formValues) {
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${API_ROOT}/serviceProvider/setRateAndReviewsAsFeatured`
  });
  return {
    type: UPDATE_REVIEW_STATUS,
    payload: request
  };
}

export function fetchReview(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getRateAndReviewById`
  });

  return {
    type: FETCH_REVIEW,
    payload: request
  };
}

export function updateReview(formValues) {
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${API_ROOT}/serviceProvider/editRateAndReview`
  });
  return {
    type: UPDATE_REVIEW,
    payload: request
  };
}

export function fetchBookingSubscriptionDetails(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBookingSubscriptionDetails`
  });

  return {
    type: FETCH_BOOKING_SUBSCRIPTION_DETAILS,
    payload: request
  };
}

export function fetchBookingSubscriptionSchedule(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBookingSubscriptionSchedule`
  });

  return {
    type: FETCH_BOOKING_SUBSCRIPTION_SCHEDULE,
    payload: request
  };
}

export function paySubscriptionInvoice(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/paySubscriptionInvoice`
  });

  return {
    type: PAY_SUBSCRIPTION_INVOICE,
    payload: request
  };
}

export function fetchBedsForReassigningBooking(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBedsForReassignBooking`
  });

  return {
    type: FETCH_BES_FOR_REASSIGNING,
    payload: request
  };
}

export function assignBedManually(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/reAssignBedsToBooking`
  });
  return {
    type: REASSIGN_BED,
    payload: request
  };
}
