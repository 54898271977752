import axios from 'axios';

import { API_ROOT } from '../constants';

export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNIN_USER = 'SIGNIN_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const ADD_USER = 'ADD_USER';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const UPDATE_NOTIFICATION_CHECK_TIME = 'UPDATE_NOTIFICATION_CHECK_TIME';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const ADD_ROLE = 'ADD_ROLE';

export const FETCH_ROLES = 'FETCH_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';

export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';

export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNUP_USER = 'SIGNUP_USER';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMER_DETAIL = 'FETCH_CUSTOMER_DETAIL';

export const UPDATE_CUSTOMER_STATUS = 'UPDATE_CUSTOMER_STATUS';


const ROOT_URL = API_ROOT;

let token = null;



export function signInUser(formValues) {
  const request = axios.post(`${ROOT_URL}/serviceProvider/login`, formValues);

  return {
    type: SIGNIN_USER,
    payload: request
  };
}

export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}



export function forgotPassword(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/serviceProvider/forgotPassword`

  });
  return {
    type: FORGOT_PASSWORD,
    payload: request
  };
}

export function resetPassword(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/serviceProvider/resetForgotPassword`
  });
  return {
    type: RESET_PASSWORD,
    payload: request
  };
}


export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}


export function changePassword(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/serviceProvider/changePassword`
  });
  return {
    type: CHANGE_PASSWORD,
    payload: request
  };
}



export function deleteUser(params) {

  //alert(token);
  const request = axios({
    method: 'POST',
    data: params,
    url: `${ROOT_URL}/serviceProvider/deleteStaff`
  });

  return {
    type: DELETE_USER,
    payload: request
  };
}

export function fetchUsers(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/staffListing`
  });

  return {
    type: FETCH_USERS,
    payload: request
  };
}



export function fetchUser(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/staffDetailById`
  });

  return {
    type: FETCH_USER,
    payload: request
  };
}



export function updateUserStatus(formValues) {

  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/serviceProvider/setStaffStatus`
  });
  return {
    type: UPDATE_USER_STATUS,
    payload: request
  };
}



export function addUser(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/serviceProvider/addStaff`
  });
  return {
    type: ADD_USER,
    payload: request
  };
}

export function fetchNotifications(params) {

  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/getNotification`
  });

  return {
    type: FETCH_NOTIFICATIONS,
    payload: request
  };
}

export function fetchMessages(params) {

  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/communication/list`
  });

  return {
    type: FETCH_MESSAGES,
    payload: request
  };
}

export function updateNotificationCheckedTime(params) {

  const request = axios({
    method: 'post',
    data: params,
    url: `${ROOT_URL}/updateNotificationCheckedTime`
  });

  return {
    type: UPDATE_NOTIFICATION_CHECK_TIME,
    payload: request
  };
}

export function readNotification(params){

  const request = axios({
    method: 'POST',
    data: params,
    url: `${ROOT_URL}/readNotification`
  });
  return {
    type: READ_NOTIFICATION,
    payload: request
  };
}

export function addRole(formValues) {
  let apiPath = "/serviceProvider/addRole";
  const request = axios({
    method: "POST",
    data: formValues,
    url: `${ROOT_URL}${apiPath}`
  });
  return {
    type: ADD_ROLE,
    payload: request
  };
}

export function fetchRoles(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/roleListing`
  });

  return {
    type: FETCH_ROLES,
    payload: request
  };
}

export function fetchRole(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/roleDetailById`
  });

  return {
    type: FETCH_ROLE,
    payload: request
  };
}

export function deleteRole(params) {
  const request = axios({
    method: 'POST',
    data: params,
    url: `${ROOT_URL}/serviceProvider/deleteRole`
  });
  return {
    type: DELETE_ROLE,
    payload: request
  };
}


export function fetchPermissions(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/getAllPermissions`
  });

  return {
    type: FETCH_PERMISSIONS,
    payload: request
  };
}

export function fetchCustomers(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceProvider/getCustomers`
  });
  return {
    type: FETCH_CUSTOMERS,
    payload: request
  };
}

export function fetchCustomerDetail(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/customerDetail`
  });
  return {
    type: FETCH_CUSTOMER_DETAIL,
    payload: request
  };
}

export function updateCustomerStatus(formValues) {

  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${ROOT_URL}/serviceProvider/setCustomerStatus`
  });
  return {
    type: UPDATE_CUSTOMER_STATUS,
    payload: request
  };
}
