export const ROUTES = {
  INDEX: {
    URL: "/",
    PARENT: "Layout"
  },
  DASHBOARD: {
    URL: "/admin/dashboard",
    PARENT: "LeftNavigation"
  },
  USERS_LIST: {
    URL: "/admin/user-management/users/list",
    PARENT: "LeftNavigation"
  },
  USERS_CREATE: {
    URL: "/admin/user-management/users/list/create",
    PARENT: "LeftNavigation"
  },
  USERS_EDIT: {
    URL: "/admin/user-management/users/list/edit/:user_id",
    PARENT: "LeftNavigation"
  },
  ROLES_LIST: {
    URL: "/admin/user-management/roles/list",
    PARENT: "LeftNavigation"
  },

  ROLES_CREATE: {
    URL: "/admin/user-management/roles/list/create",
    PARENT: "LeftNavigation"
  },
  ROLES_EDIT: {
    URL: "/admin/user-management/roles/list/edit/:roleId",
    PARENT: "LeftNavigation"
  },
  CHANGE_PASSWORD: {
    URL: "/admin/change-password",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_CITIES_LISTING: {
    URL: "/admin/space-management/cities/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_CITIES_ADD: {
    URL: "/admin/space-management/cities/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_CITIES_EDIT: {
    URL: "/admin/space-management/cities/list/edit/:cityId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_CITIES_MANAGE_PLANS: {
    URL: "/admin/space-management/cities/list/manage-plans/:type/:id",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LOCALITIES_LISTING: {
    URL: "/admin/space-management/localities/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LOCALITIES_ADD: {
    URL: "/admin/space-management/localities/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LOCALITIES_EDIT: {
    URL: "/admin/space-management/localities/list/edit/:localityId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LOCALITIES_MANAGE_PLANS: {
    URL: "/admin/space-management/localities/list/manage-plans/:type/:id",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BUILDINGS_LISTING: {
    URL: "/admin/space-management/buildings/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BUILDINGS_ADD: {
    URL: "/admin/space-management/buildings/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BUILDINGS_EDIT: {
    URL: "/admin/space-management/buildings/list/edit/:buildingId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BUILDINGS_MANAGE_PICTURE_GALLERY: {
    URL: "/admin/space-management/buildings/list/managePictureGallery/:buildingId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BUILDINGS_MANAGE_AMENITIES: {
    URL: "/admin/space-management/buildings/list/manageAmenities/:buildingId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BLOCKS_LISTING: {
    URL: "/admin/space-management/blocks/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BLOCKS_ADD: {
    URL: "/admin/space-management/blocks/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BLOCKS_EDIT: {
    URL: "/admin/space-management/blocks/list/edit/:blockId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLATS_LISTING: {
    URL: "/admin/space-management/flats/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLATS_ADD: {
    URL: "/admin/space-management/flats/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLATS_EDIT: {
    URL: "/admin/space-management/flats/list/edit/:flatId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLATS_MANAGE_ROOMS: {
    URL: "/admin/space-management/flats/list/manageFlatRooms/:flatId",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_ROOMS_LISTING: {
    URL: "/admin/space-management/rooms/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_BEDS_LISTING: {
    URL: "/admin/space-management/beds/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLAT_TYPES_LISTING: {
    URL: "/admin/space-management/flatTypes/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLAT_TYPES_ADD: {
    URL: "/admin/space-management/flatTypes/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_FLAT_TYPES_EDIT: {
    URL: "/admin/space-management/flatTypes/list/edit/:flatTypeId",
    PARENT: "Layout"
  },

  SPACE_MANAGEMENT_AMENITIES_LISTING: {
    URL: "/admin/space-management/amenities/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_AMENITIES_ADD: {
    URL: "/admin/space-management/amenities/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_AMENITIES_EDIT: {
    URL: "/admin/space-management/amenities/list/edit/:amenityId",
    PARENT: "Layout"
  },


  SPACE_MANAGEMENT_LANDMARKS_LISTING: {
    URL: "/admin/space-management/landmarks/list",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LANDMARKS_ADD: {
    URL: "/admin/space-management/landmarks/list/add",
    PARENT: "Layout"
  },
  SPACE_MANAGEMENT_LANDMARKS_EDIT: {
    URL: "/admin/space-management/landmarks/list/edit/:landmarkId",
    PARENT: "Layout"
  },

  PLANS_LISTING: {
    URL: "/admin/plans/list",
    PARENT: "Layout"
  },
  PLANS_LISTING_ADD: {
    URL: "/admin/plans/list/add",
    PARENT: "Layout"
  },
  PLANS_LISTING_EDIT: {
    URL: "/admin/plans/list/edit/:planId",
    PARENT: "Layout"
  },

  CALLBACK_REQUESTS_LISTING: {
    URL: "/admin/callback-requests/list",
    PARENT: "Layout"
  },

  NEWS_LISTING: {
    URL: "/admin/news/list",
    PARENT: "Layout"
  },

  NEWS_LISTING_ADD: {
    URL: "/admin/news/list/add",
    PARENT: "Layout"
  },

  NEWS_LISTING_EDIT: {
    URL: "/admin/news/list/edit/:newsId",
    PARENT: "Layout"
  },


  EVENTS_LISTING: {
    URL: "/admin/events/list",
    PARENT: "Layout"
  },

  EVENTS_LISTING_ADD: {
    URL: "/admin/events/list/add",
    PARENT: "Layout"
  },

  EVENTS_LISTING_EDIT: {
    URL: "/admin/events/list/edit/:eventId",
    PARENT: "Layout"
  },

  BOOKINGS_LISTING: {
    URL: "/admin/bookings/list",
    PARENT: "Layout"
  },

  BOOKING_DETAIL: {
    URL: "/admin/bookings/list/detail/:bookingId",
    PARENT: "Layout"
  },
  CUSTOMERS_LIST: {
    URL: "/admin/customers/list",
    PARENT: "LeftNavigation"
  },
  CUSTOMER_DETAIL: {
    URL: "/admin/customers/list/detail/:userId",
    PARENT: "LeftNavigation"
  },
  REVIEWS_LISTING: {
    URL: "/admin/rating-reviews/list",
    PARENT: "LeftNavigation"
  },
  REVIEWS_LISTING_EDIT: {
    URL: "/admin/rating-reviews/list/edit/:reviewId",
    PARENT: "LeftNavigation"
  },
  SETTINGS_TAXES_LISTING: {
    URL: "/admin/settings/taxes/list",
    PARENT: "LeftNavigation"
  },
  SETTINGS_TAXES_LISTING_ADD: {
    URL: "/admin/settings/taxes/list/add",
    PARENT: "LeftNavigation"
  },
  SETTINGS_TAXES_LISTING_EDIT: {
    URL: "/admin/settings/taxes/list/edit/:taxId",
    PARENT: "LeftNavigation"
  },


  SETTINGS_BANNERS_LISTING: {
    URL: "/admin/settings/banners/list",
    PARENT: "LeftNavigation"
  },
  SETTINGS_BANNERS_LISTING_ADD: {
    URL: "/admin/settings/banners/list/add",
    PARENT: "LeftNavigation"
  },
  SETTINGS_BANNERS_LISTING_EDIT: {
    URL: "/admin/settings/banners/list/edit/:bannerId",
    PARENT: "LeftNavigation"
  },


  SETTINGS_COMFORTS_LISTING: {
    URL: "/admin/settings/comforts/list",
    PARENT: "LeftNavigation"
  },
  SETTINGS_COMFORTS_LISTING_ADD: {
    URL: "/admin/settings/comforts/list/add",
    PARENT: "LeftNavigation"
  },
  SETTINGS_COMFORTS_LISTING_EDIT: {
    URL: "/admin/settings/comforts/list/edit/:comfortId",
    PARENT: "LeftNavigation"
  },

  SETTINGS_STATIC_PAGES: {
    URL: "/admin/settings/page/:slug",
    PARENT: "LeftNavigation"
  },

  SETTINGS_WEBSITE_WIDE_SETTINGS: {
    URL: "/admin/settings/websiteSettings",
    PARENT: "LeftNavigation"
  },

  REPORTS_BEDS_LIST: {
    URL: "/admin/reports/beds/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_BOOKINGS_LIST: {
    URL: "/admin/reports/bookings/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_CUSTOMERS_LIST: {
    URL: "/admin/reports/customers/list",
    PARENT: "LeftNavigation"
  },


  LOGOUT: {
    URL:"/logout",
    PARENT: "Layout"
  },
};
