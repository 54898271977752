import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addBlock, fetchBuildings, fetchBlock, fetchBlockTypes, fetchAvailableFloors} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeFloorsField} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter block name";
    hasErrors = true;
  }
  if (!values.buildingId) {
    errors.buildingId = "Select building";
    hasErrors = true;
  }

  if(!values.blockTypeId) {
    errors.blockTypeId = "Select block type";
    hasErrors = true;
  }
  if(!values.floors) {
    errors.floors = "Enter floors";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class AddBlock extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.blockId,
      buildingId: props.buildingId?props.buildingId:null,
      blockDetail: null,
      buildings: [],
      blockTypes: [],
      availableFloors: []
    }
    this.fetchAvailableFloors = this.fetchAvailableFloors.bind(this);
  }

  submitForm(data, dispatch, props){
    let values = [data];
    return this.props.addBlock({blocks:values, buildingId: data.buildingId}).then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.buildingId){
      this.fetchAvailableFloors({id: this.state.buildingId});
    }
    this.fetchBlockTypes();
    this.fetchBuildings();
    if(this.state.id){
      this.fetchBlock(this.state.id);
    }
  }
  fetchAvailableFloors(obj){
    this.props.fetchAvailableFloors({buildingId: obj.id}).then((response) => {
      let data = response.value.data.data;
      this.setState({fetchingRecords: false, availableFloors: data.availableFloors});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchBuildings(){
		this.setState({fetchingRecords: true});
    this.props.fetchBuildings({isAll: 1}).then((response) => {
      let buildings = response.value.data.data.buildings;
      buildings.forEach((obj, index) => {
        //alert(buildings[index].)
        buildings[index] = Object.assign(buildings[index], {label: buildings[index].name+" ("+ buildings[index].locality.name +")"})
      })
      this.setState({fetchingRecords: false, buildings: buildings});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchBlock(blockId){
		this.props.fetchBlock({id: blockId}).then((response) => {
      let blockDetail = response.value.data.data;
      this.props.initialize({
        id: blockDetail.id,
        name: blockDetail.name,
        floors: blockDetail.floors,
        buildingId: blockDetail.building.id,
        blockTypeId: blockDetail.blockType.id
      });
      this.setState({blockDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchBlockTypes(){
		this.props.fetchBlockTypes({isAll: 1}).then((response) => {
      let blockTypes = response.value.data.data.blockTypes;
      this.setState({blockTypes});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/space-management/localities/list">Localities</Link></li>
          <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
        </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Block</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-8">
                <Field
                  label="Select building"
                  name='buildingId'
                  optionLabel='label'
                  optionValue='id'
                  options={this.state.buildings}
                  component={renderReactSelect}
                  placeholder="Select building"
                  parentCallback={this.fetchAvailableFloors}
                  multi={false}
                />
              </div>
              <div className=" col-lg-8">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Block name"
                />
              </div>
              <div className=" col-lg-8">
                <Field
                  label="Select block type"
                  name='blockTypeId'
                  optionLabel='name'
                  optionValue='id'
                  options={this.state.blockTypes}
                  component={renderReactSelect}
                  placeholder="Select block type"
                  multi={false}
                />
              </div>
              <div className=" col-lg-8">
                <Field
                  name="floors"
                  component={renderField}
                  type="text"
                  label="Floors (comma separated)"
                  normalize={normalizeFloorsField}

                />
              </div>
              <div className="col-lg-8">
                <div className="form-group">
                  <label>Available Floors</label>
                  <div>
                  {
                    this.state.availableFloors && this.state.availableFloors.length > 0 &&
                    this.state.availableFloors.map((obj, index) => {
                      return(
                        <span key = {index}>{index > 0?", ":''}{obj}</span>
                      )
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBlock: (data) => {
      return dispatch(addBlock(data));
    },
    fetchBlock: (data) => {
      return dispatch(fetchBlock(data));
    },
    fetchBuildings: (data) => {
      return dispatch(fetchBuildings(data));
    },
    fetchBlockTypes: (data) => {
      return dispatch(fetchBlockTypes(data));
    },
    fetchAvailableFloors: (data) => {
      return dispatch(fetchAvailableFloors(data));
    }
  }
}

AddBlock = connect(null, mapDispatchToProps)(AddBlock);
AddBlock = reduxForm({
  form: 'AddBlock',
  validate,
  //enableReinitialize: true
})(AddBlock);

const selector = formValueSelector('AddBlock');
AddBlock = connect(state => {
  // can select values individually
  const buildingId = selector(state, 'buildingId');
  return {
    buildingId,
  }
})(AddBlock)



export default AddBlock;
