import React, {Component} from 'react';
import {Route, Link, withRouter} from 'react-router-dom';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderTextarea from '../../FormFields/renderTextarea';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import {cancelBooking, fetchBedsForReassigningBooking, assignBedManually} from '../../../actions/bookings';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.cancelReason || values.cancelReason.trim() === '') {
    errors.cancelReason = "Enter reason for cancellation";
    hasErrors = true;
  }


  return hasErrors && errors;
}



class AssignBedManuallyPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingData: props.bookingData,
      modal: props.openPopup,
      records: null,
    };
    this.toggle = this.toggle.bind(this);
  }


  toggle(){
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }

  componentDidMount(){
    this.fetchBedsForReassigningBooking(this.state.bookingData.orderId);
  }









  fetchBedsForReassigningBooking(orderId){
    this.setState({fetchingRecords: true});
    return this.props.fetchBedsForReassigningBooking({orderId}).then((response) => {
      this.setState({records: response.value.data.data, fetchingRecords: false});
    }).catch((error) => {
      this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);

      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  assignBedManually(record){
		this.setState({submitting: true});
    var postData = {
      orderId: this.state.bookingData.orderId,
      planId: record.plan.id,
      buildingId: record.building.id,
      buildingBlockId: record.buildingBlock.id,
      flatId: record.flat.id,
      roomId: record.roomId,
      beds: record.beds,
      balcony: record.balcony,
      bathroomAttached: record.bathroomAttached
    };
    this.props.assignBedManually(postData).then((response) => {
      this.setState({submitting: false});
      toast.success(response.value.data.message);
      this.props.closePopup();
      if(this.props.callback){
        this.props.callback();
      }
		}).catch((error)=>{
			this.setState({submitting: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}





  render() {
    const {bookingData, records} = {...this.state};
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="sof-ui-block reassign-bed-popup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Assign/Reassign Booking (Booking ID: {this.state.bookingData.orderId})</ModalHeader>
        <div className="modal-body">
          <div className="scope-heading">
          </div>
          <div className="card-content-ui">
            <div className="custom-table mt-0">
    					<div className="table-responsive">
    					{
    						(this.state.fetchingRecords == true || this.state.submitting) && <Loader />
    					}
    						<table className="table mb-0" >
    							<thead>
    								<tr>
    									<th>Building</th>
    									<th>Block</th>
    									<th>Flat #</th>
    									<th>Plan</th>
    									<th>Room Id</th>
    									<th>Bed Id</th>
    									<th>Attached Bathroom</th>
    									<th>Attached Balcony</th>
    									<th></th>
    								</tr>
    							</thead>
    						<tbody>
    						{
    							records && records.length > 0 &&
    							records.map((obj, index) => {
    								return(
    									<tr key={index}>
    										<td>{obj.building.name}</td>
    										<td className="nowrap">{obj.buildingBlock.name}</td>
    										<td className="nowrap">{obj.flat.number}</td>
    										<td>{obj.plan.name}</td>
    										<td className="nowrap">{obj.roomId}</td>
    										<td>{obj.beds && obj.beds[0]?obj.beds[0]:""}</td>
    										<td className="nowrap">{obj.bathroomAttached?"Yes":"No"}</td>
    										<td className="nowrap">{obj.balcony?"Yes":"No"}</td>

    										<td className="nowrap"><button className="btn-add ml-1  hvr-shadow" onClick = {() => this.assignBedManually(obj)}>Assign Bed</button></td>

    									</tr>
    								)
    							})
    						}
    						</tbody>
    					</table>
    				</div>
    			</div>
          </div>
        </div>

      </Modal>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {

  };
}


const mapDispatchToProps = (dispatch) => {
  return {
    assignBedManually: (data) => {
      return dispatch(assignBedManually(data));
    },
    fetchBedsForReassigningBooking: (data) => {
      return dispatch(fetchBedsForReassigningBooking(data));
    }
  }
}

AssignBedManuallyPopup = connect(null, mapDispatchToProps)(AssignBedManuallyPopup);






export default withRouter(connect(null, mapDispatchToProps)(AssignBedManuallyPopup));
