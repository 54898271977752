import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_EVENT_BY_ID = 'FETCH_EVENT_BY_ID';
export const ADD_EVENT = 'ADD_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const FETCH_EVENT_TAGS = 'FETCH_EVENT_TAGS';

export function fetchEvents(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getEvents`
  });

  return {
    type: FETCH_EVENTS,
    payload: request
  };
}
export function fetchEventById(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getEventById`
  });

  return {
    type: FETCH_EVENT_BY_ID,
    payload: request
  };
}

export function addEvent(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addEvent`
  });
  return {
    type: ADD_EVENT,
    payload: request
  };
}

export function deleteEvent(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteEvent`
  });
  return {
    type: DELETE_EVENT,
    payload: request
  };
}

export function fetchEventTags(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getEventTags`
  });

  return {
    type: FETCH_EVENT_TAGS,
    payload: request
  };
}
