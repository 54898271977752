import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {normalizeAmountField} from '../../../constants';
import {addBanner, fetchBanner} from '../../../actions/banners';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import renderDatePicker from '../../FormFields/renderDatePicker';
import {uploadFile} from '../../../actions/common';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';
import { WithContext as ReactTags } from 'react-tag-input';
import moment from 'moment';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.title || values.title.trim() === '') {
    errors.title = "Enter title";
    hasErrors = true;
  }
  if (!values.subTitle || values.subTitle.trim() === '') {
    errors.subTitle = "Enter sub-title";
    hasErrors = true;
  }

  if (!values.imageId || values.imageId === '') {
    errors.imageId = "Upload banner image";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class AddBanner extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.bannerId,
      bannerDetail: null,
      arrayLocalImages: [],


    }
    this.onDrop = this.onDrop.bind(this);

    this.mainImageRef = React.createRef();
  }

  submitForm(values, dispatch, props){

    if(values.eventDate){
      values = Object.assign(values, {eventDate: moment(values.eventDate).format("YYYY-MM-DD")});
    }
    values = Object.assign(values, {eventTags: this.state.tags});
    //console.log('jjjjjjj', this.state.tags);
    //console.log('jjjjjjj', values); return;
    return this.props.addBanner(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.id){
      this.fetchBanner(this.state.id);
    }
  }





  fetchBanner(newsId){
		this.props.fetchBanner({id: newsId}).then((response) => {
      let bannerDetail = response.value.data.data;
      let arrayLocalImages = [];

      if(bannerDetail && bannerDetail.image && bannerDetail.image.id){
        arrayLocalImages.push(bannerDetail.image);
      }
      this.props.initialize({
        id: bannerDetail.id,
        title: bannerDetail.title,
        subTitle: bannerDetail.subTitle,
        imageId: bannerDetail.image.id,

      });



      this.setState({bannerDetail, arrayLocalImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:"EVENT_MAIN_IMAGE"}).then((response) => {
        this.setState({imageUploading: false, arrayLocalImages: []});
        var payload = response.value.data.data;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					this.props.change('imageId', payload.id);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
    this.mainImageRef.current.state.pictures = [];
    this.mainImageRef.current.state.files = [];
  }











  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const { tags, suggestions } = this.state;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/settings/banners/list?pageNumber=1">Banners</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Banner</h5>
        <section className="formBg" style={{maxWidth:"872px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-10">
                <Field
                  name="title"
                  component={renderField}
                  type="text"
                  label="Title"
                />
              </div>
              <div className=" col-lg-10">
                <Field
                  name="subTitle"
                  component={renderField}
                  type="text"
                  label="Sub-title"
                />
              </div>


              <div className=" col-lg-6">
                <ImageUploader
  								className={this.state.imageUploading?"loading":""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText='Banner Image (1600x732, ratio: 16:8 & less than 2MB)'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
                  ref={this.mainImageRef}
  							/>
                <Field
                  name="imageId"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImages.length > 0 &&
                  this.state.arrayLocalImages.map((obj, index) => {
                    return(
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.original} alt=""/></figure>
    									</div>
                    )
                  })
                }
              </div>
            </div>

            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBanner: (data) => {
      return dispatch(addBanner(data));
    },
    fetchBanner: (data) => {
      return dispatch(fetchBanner(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    }
  }
}



AddBanner = reduxForm({
  form: 'AddBanner',
  validate,
  //enableReinitialize: true
})(AddBanner);


AddBanner = connect(null, mapDispatchToProps)(AddBanner);

export default AddBanner;
