import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addFlatType, fetchFlatType} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_COUNTRY_ID} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter flat type";
    hasErrors = true;
  }


  return hasErrors && errors;
}

class AddFlatType extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.flatTypeId,
      flatTypeDetail: null
    }
  }

  submitForm(values, dispatch, props){
    return this.props.addFlatType(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    if(this.state.id){
      this.fetchFlatType(this.state.id);
    }
  }




  fetchFlatType(id){
		this.props.fetchFlatType({id}).then((response) => {
      let flatTypeDetail = response.value.data.data;
      this.props.initialize({id: flatTypeDetail.id, name: flatTypeDetail.name});
      this.setState({flatTypeDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/cities/list">Flat Types</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Flat Type</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-8">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Flat Type"
                />
              </div>
             </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFlatType: (data) => {
      return dispatch(addFlatType(data));
    },
    fetchFlatType: (data) => {
      return dispatch(fetchFlatType(data));
    }
  }
}

AddFlatType = connect(null, mapDispatchToProps)(AddFlatType);

AddFlatType = reduxForm({
  form: 'AddFlatType',
  validate,
  //enableReinitialize: true
})(AddFlatType);

export default AddFlatType;
