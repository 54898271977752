import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_CITIES = 'FETCH_CITIES';
export const ADD_CITY = 'ADD_CITY';
export const ADD_LOCALITY = 'ADD_LOCALITY';
export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_LOCALITY = 'FETCH_LOCALITY';
export const FETCH_LOCALITIES = 'FETCH_LOCALITIES';
export const DELETE_CITY = 'DELETE_CITY';
export const DELETE_LOCALITY = 'DELETE_LOCALITY';

export const FETCH_BUILDINGS = 'FETCH_BUILDINGS';
export const FETCH_BUILDING = 'FETCH_BUILDING';
export const DELETE_BUILDING = 'DELETE_BUILDING';
export const ADD_BUILDING = 'ADD_BUILDING';

export const FETCH_BLOCKS = 'FETCH_BLOCKS';
export const FETCH_BLOCK = 'FETCH_BLOCK';
export const DELETE_BLOCK = 'DELETE_BLOCK';
export const ADD_BLOCK = 'ADD_BLOCK';

export const FETCH_BLOCK_TYPES = 'FETCH_BLOCK_TYPES';

export const FETCH_AVAILABLE_FLOORS = 'FETCH_AVAILABLE_FLOORS';

export const FETCH_FLATS = 'FETCH_FLATS';
export const FETCH_FLAT = 'FETCH_FLAT';
export const DELETE_FLAT = 'DELETE_FLAT';
export const ADD_FLAT = 'ADD_FLAT';

export const FETCH_FLAT_TYPES = 'FETCH_FLAT_TYPES';
export const ADD_FLAT_ROOMS = 'ADD_FLAT_ROOMS';
export const MANAGE_FLAT_ROOMS = 'MANAGE_FLAT_ROOMS';
export const FETCH_FLAT_ROOMS = 'FETCH_FLAT_ROOMS';
export const DELETE_ROOM = 'DELETE_ROOM';

export const FETCH_BEDS = 'FETCH_BEDS';
export const DELETE_BED = 'DELETE_BED';

export const FETCH_ROOMS = 'FETCH_ROOMS';

export const FETCH_PLANS = 'FETCH_PLANS';

export const ADD_BUILDING_GALLERY_IMAGES = 'ADD_BUILDING_GALLERY_IMAGES';

export const FETCH_BUILDING_GALLERY_IMAGES = 'FETCH_BUILDING_GALLERY_IMAGES';


export const FETCH_FLAT_TYPE = 'FETCH_FLAT_TYPE';


export const ADD_FLAT_TYPE = 'ADD_FLAT_TYPE';

export const DELETE_FLAT_TYPE = 'DELETE_FLAT_TYPE';
export const UPDATE_FLAT_TYPE_STATUS = 'UPDATE_FLAT_TYPE_STATUS';


export const FETCH_AMENITIES = 'FETCH_AMENITIES';
export const FETCH_AMENITY = 'FETCH_AMENITY';
export const ADD_AMENITY = 'ADD_AMENITY';
export const DELETE_AMENITY = 'DELETE_AMENITY';
export const UPDATE_AMENITY_STATUS = 'UPDATE_AMENITY_STATUS';


export const FETCH_LANDMARKS = 'FETCH_LANDMARKS';
export const FETCH_LANDMARK = 'FETCH_LANDMARK';
export const ADD_LANDMARK = 'ADD_LANDMARK';
export const DELETE_LANDMARK = 'DELETE_LANDMARK';

export const FETCH_LANDMARK_TYPES = 'FETCH_LANDMARK_TYPES';

export const FETCH_BUILDING_PLAN_AMENITIES = 'FETCH_BUILDING_PLAN_AMENITIES';
export const ADD_BUILDING_PLAN_AMENITIES = 'ADD_BUILDING_PLAN_AMENITIES';






export function fetchCountries(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCountryList`
  });

  return {
    type: FETCH_COUNTRIES,
    payload: request
  };
}

export function fetchCities(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCities`
  });
  return {
    type: FETCH_CITIES,
    payload: request
  };
}

export function fetchCity(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getCityById`
  });
  return {
    type: FETCH_CITY,
    payload: request
  };
}

export function addCity(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addCity`
  });
  return {
    type: ADD_CITY,
    payload: request
  };
}

export function deleteCity(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteCity`
  });
  return {
    type: DELETE_CITY,
    payload: request
  };
}

export function fetchLocalities(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalities`
  });
  return {
    type: FETCH_LOCALITIES,
    payload: request
  };
}

export function fetchLocality(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalityById`
  });
  return {
    type: FETCH_LOCALITY,
    payload: request
  };
}

export function addLocality(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addLocality`
  });
  return {
    type: ADD_LOCALITY,
    payload: request
  };
}

export function deleteLocality(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteLocality`
  });
  return {
    type: DELETE_LOCALITY,
    payload: request
  };
}

export function addBuilding(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuilding`
  });
  return {
    type: ADD_BUILDING,
    payload: request
  };
}

export function fetchBuildings(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildings`
  });
  return {
    type: FETCH_BUILDINGS,
    payload: request
  };
}

export function fetchBuilding(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingById`
  });
  return {
    type: FETCH_BUILDING,
    payload: request
  };
}

export function deleteBuilding(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBuilding`
  });
  return {
    type: DELETE_BUILDING,
    payload: request
  };
}

export function addBlock(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuildingBlock`
  });
  return {
    type: ADD_BLOCK,
    payload: request
  };
}


export function fetchBlocks(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlocks`
  });
  return {
    type: FETCH_BLOCKS,
    payload: request
  };
}

export function fetchBlock(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockById`
  });
  return {
    type: FETCH_BLOCK,
    payload: request
  };
}

export function deleteBlock(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBuildingBlock`
  });
  return {
    type: DELETE_BLOCK,
    payload: request
  };
}

export function fetchBlockTypes(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBlockTypes`
  });
  return {
    type: FETCH_BLOCK_TYPES,
    payload: request
  };
}

export function fetchAvailableFloors(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFloorsAvailable`
  });
  return {
    type: FETCH_AVAILABLE_FLOORS,
    payload: request
  };
}


export function fetchFlats(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFlats`
  });
  return {
    type: FETCH_FLATS,
    payload: request
  };
}

export function addFlat(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuildingBlockFlat`
  });
  return {
    type: ADD_FLAT,
    payload: request
  };
}

export function fetchFlat(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFlatById`
  });
  return {
    type: FETCH_FLAT,
    payload: request
  };
}

export function deleteFlat(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBuildingBlockFlat`
  });
  return {
    type: DELETE_FLAT,
    payload: request
  };
}

export function manageFlatRooms(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuildingBlockFlatRoom`
  });
  return {
    type: MANAGE_FLAT_ROOMS,
    payload: request
  };
}

export function fetchFlatRooms(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFlatRooms`
  });
  return {
    type: FETCH_FLAT_ROOMS,
    payload: request
  };
}

export function deleteRoom(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBuildingBlockFlatRoom`
  });
  return {
    type: DELETE_ROOM,
    payload: request
  };
}

export function fetchRooms(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFlatRooms`
  });
  return {
    type: FETCH_ROOMS,
    payload: request
  };
}

export function fetchBeds(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingBlockFlatRoomBeds`
  });
  return {
    type: FETCH_BEDS,
    payload: request
  };
}

export function deleteBed(data) {
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBuildingBlockFlatRoomBed`
  });
  return {
    type: DELETE_BED,
    payload: request
  };
}

export function fetchPlans(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getPlans`
  });
  return {
    type: FETCH_PLANS,
    payload: request
  };
}

export function addBuildingGalleryImages(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuildingGalleryImages`
  });
  return {
    type: ADD_BUILDING_GALLERY_IMAGES,
    payload: request
  };
}

export function fetchBuildingGalleryImages(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingGalleryImages`
  });
  return {
    type: FETCH_BUILDING_GALLERY_IMAGES,
    payload: request
  };
}

export function fetchFlatTypes(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getFlatTypes`
  });
  return {
    type: FETCH_FLAT_TYPES,
    payload: request
  };
}

export function fetchFlatType(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getFlatTypeById`
  });
  return {
    type: FETCH_FLAT_TYPE,
    payload: request
  };
}

export function addFlatType(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addFlatType`
  });
  return {
    type: ADD_FLAT_TYPE,
    payload: request
  };
}

export function deleteFlatType(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteFlatType`
  });
  return {
    type: DELETE_FLAT_TYPE,
    payload: request
  };
}

export function updateFlatTypeStatus(data) {
  const request = axios({
    method: 'PUT',
    data: data,
    url: `${API_ROOT}/serviceProvider/setFlatTypeStatus`
  });
  return {
    type: UPDATE_FLAT_TYPE_STATUS,
    payload: request
  };
}

export function fetchAmenities(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getAmenities`
  });
  return {
    type: FETCH_AMENITIES,
    payload: request
  };
}

export function fetchAmenity(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getAmenityById`
  });
  return {
    type: FETCH_AMENITY,
    payload: request
  };
}


export function addAmenity(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addAmenity`
  });
  return {
    type: ADD_AMENITY,
    payload: request
  };
}


export function deleteAmenity(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteAmenity`
  });
  return {
    type: DELETE_AMENITY,
    payload: request
  };
}


export function updateAmenityStatus(data) {
  const request = axios({
    method: 'PUT',
    data: data,
    url: `${API_ROOT}/serviceProvider/setAmenityStatus`
  });
  return {
    type: UPDATE_AMENITY_STATUS,
    payload: request
  };
}



export function fetchLandmarks(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalityLandmarks`
  });
  return {
    type: FETCH_LANDMARKS,
    payload: request
  };
}

export function fetchLandmark(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalityLandmarkById`
  });
  return {
    type: FETCH_LANDMARK,
    payload: request
  };
}

export function addLandmark(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addLocalityLandmark`
  });
  return {
    type: ADD_LANDMARK,
    payload: request
  };
}

export function deleteLandmark(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteLocalityLandmark`
  });
  return {
    type: DELETE_LANDMARK,
    payload: request
  };
}

export function fetchLandmarkTypes(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getLocalityLandmarkTypes`
  });
  return {
    type: FETCH_LANDMARK_TYPES,
    payload: request
  };
}

export function fetchBuildingPlanAmenities(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBuildingPlanAmenities`
  });
  return {
    type: FETCH_BUILDING_PLAN_AMENITIES,
    payload: request
  };
}

export function addBuildingPlanAmenities(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBuildingPlanAmenities`
  });
  return {
    type: ADD_BUILDING_PLAN_AMENITIES,
    payload: request
  };
}
