import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderReactSelect from '../FormFields/renderReactSelectForForm';
import renderField from '../FormFields/renderField';
import {Redirect, BrowserRouter, Link} from 'react-router-dom';
import { signInUser, signInUserSuccess } from '../../actions/users';
import { fetchCountries } from '../../actions/common';
import {toast} from 'react-toastify';
import {DEFAULT_COUNTRY_ID, validateEmail} from '../../constants';
import $ from 'jquery';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }else if(!validateEmail(values.email)){
    errors.email = "Enter a valid email";
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }
  return hasErrors && errors;
}

//submit login form
const submitLoginForm = (values, dispatch, props) => {
  values = Object.assign(values, {country: DEFAULT_COUNTRY_ID});

  return dispatch(signInUser(values))
    .then((result) => {
        dispatch(reset("SignInForm"));
        localStorage.setItem('jwtToken', result.value.data.data.user.token);
        dispatch(signInUserSuccess(result.value.data.data.user));
        window.location.href = '/';
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }

      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  };


class SignInForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      is_submitting: false,
      isLoggedIn: typeof props.user.status === 'undefined' ? false : props.user.status,
      //countries: [],
      //defaultCountry: DEFAULT_COUNTRY_ID
    }
  }

  componentDidMount(){
    //this.fetchCountries();


  }

  /*fetchCountries(){
    this.props.fetchCountries().then((response) => {
      this.setState({countries: response.value.data.data});

      this.props.reset();
    }).catch((error)=>{
      if(error.response) {
        toast.error(error.response.data.message);
      }else if (error.request) {
        toast.error("Network error!");
      }else {
        toast.error(error.message);
      }
    });
  }*/

  componentWillReceiveProps(nextProps){
    if(nextProps.user.status === 'authenticated'){
      this.setState({
        isLoggedIn: true
      });
      window.location.href = '/';
    }
  }

  render() {
    /*if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    }*/
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <div className="loginForm">
        <h4 className="mb-1">Welcome</h4>
        <p className="marginB42">Login to your account</p>
        <form onSubmit={handleSubmit(submitLoginForm)} >
          {/* <Field
            label="Country"
            name='country'
            optionLabel='country'
            optionValue='id'
            options={this.state.countries}
            component={renderReactSelect}
            placeholder="Select a country"
            multi={false}
           /> */}
          <Field
            name="email"
            component={renderField}
            type="text"
            label="Email"
            placeholder="Your email address"
          />

          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder="Your password"
            label="Password"
          />


          <button type="submit" disabled={submitting} className={`${"btn btn-primary btn-block hvr-shadow"} ${submitting?'btn-loader':''}`}>Login</button>


        </form>
        <div className="text-center marginT40">
            <Link to="/forgotpassword"  className="btn-link">Forgot Password</Link>
         </div>

        </div>
    )
  }
}

SignInForm = reduxForm({
  form: 'SignInForm',
  validate
})(SignInForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountries: (data) => {
      return dispatch(fetchCountries(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
