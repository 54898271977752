import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {normalizeAmountField} from '../../../constants';
import {addCityPlans, addLocalityPlans, fetchCityPlans, fetchLocalityPlans} from '../../../actions/plans';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';

import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;

  return hasErrors && errors;
}

class LocationWiseManagePlans extends Component {
	constructor(props){
    super(props);
    var queryParams = queryString.parse(props.location.search);
		let entityName = queryParams.name || "";
    this.state = {
      entityName: entityName,
      id: props.match.params.id,
      type: props.match.params.type,
      planDetail: null,
    }
    this.renderPlans = this.renderPlans.bind(this);
  }

  submitForm(values, dispatch, props){

    let updatedData = [];
    if(values && values.plans && values.plans.length > 0){
      values.plans.forEach((obj, index) => {
        let temp = {};
        if(obj.id){
          temp = {id: obj.id, perPersonMonthlyPrice: obj.perPersonMonthlyPrice, extraBalconyCost: obj.extraBalconyCost, extraBathroomCost: obj.extraBathroomCost, planId: obj.plan.id, threeDImageUrl: obj.threeDImageUrl};
        }else{
          temp = {perPersonMonthlyPrice: obj.perPersonMonthlyPrice, extraBalconyCost: obj.extraBalconyCost, extraBathroomCost: obj.extraBathroomCost, planId: obj.plan.id, threeDImageUrl: obj.threeDImageUrl};
        }
        updatedData.push(temp);
      })
    }
    var data = {};
    if(this.state.type == 'locality'){
      data = {localityId: parseInt(this.state.id), plans: updatedData};
      this.addLocalityPlans(data);
    }else{
      data = {cityId: parseInt(this.state.id), plans: updatedData};
      this.addCityPlans(data);
    }
  }

  addLocalityPlans(values){
    return this.props.addLocalityPlans(values)
    .then((response) => {
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }


  addCityPlans(values){
    return this.props.addCityPlans(values)
    .then((response) => {
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.type == 'locality'){
      this.fetchLocalityPlans(this.state.id);
    }else{
      this.fetchCityPlans(this.state.id);
    }
  }

  fetchCityPlans(cityId){
		this.props.fetchCityPlans({cityId}).then((response) => {
      let plans = response.value.data.data;
      this.props.initialize({plans: plans});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchLocalityPlans(localityId){
		this.props.fetchLocalityPlans({localityId}).then((response) => {
      let plans = response.value.data.data;
      this.props.initialize({plans: plans});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  componentWillReceiveProps(nextProps){
  }

  renderPlans({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;
    return (
      <>{
        fields.map((member, index) =>{
          var planName = fields.get(index).plan.name;
          //var addOns = fields.get(index).addOns;
          return(
            <div className="row" key = {index}>
              <div className='col-md-1'>
                <label style = {{marginTop: index == 0?"38px":'14px'}}>{planName}</label>
              </div>
              <div className='col-md-2'>
                <Field name={`${member}.perPersonMonthlyPrice`}  component={renderField} label={index == 0?"Per Person Price":""}   normalize = {normalizeAmountField}/>
              </div>
              <div className='col-md-3'>
                <Field name={`${member}.extraBathroomCost`}  component={renderField} label={index == 0?"Attached bathroom cost":""}   normalize = {normalizeAmountField}/>
              </div>
              <div className='col-md-3'>
                <Field name={`${member}.extraBalconyCost`}  component={renderField} label={index == 0?"Attached balcony cost":""}   normalize = {normalizeAmountField}/>
              </div>
              <div className='col-md-3'>
                <Field name={`${member}.threeDImageUrl`}  component={renderField} label={index == 0?"3D Image URL":""} />
              </div>
            </div>
        )}

        )
      }
    </>)
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item">
          <Link to={this.state.type == 'locality'?"/admin/space-management/localities/list?pageNumber=1":"/admin/space-management/cities/list?pageNumber=1"}>
          {this.state.type == 'locality'?"Localities":"Cities"}
          </Link>
        </li>
        <li className="breadcrumb-item active">Manage Plans</li>
      </ol>
        <h5 className="h5 mb-4">Manage Plans {this.state.entityName?"("+this.state.entityName+")":''}</h5>
        <section className="formBg" >
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <FieldArray component={this.renderPlans} name="plans"/>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${this.state.submitting?" btn-loader ":"  "}` } disabled = {this.state.submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {

    fetchCityPlans: (data) => {
      return dispatch(fetchCityPlans(data));
    },
    fetchLocalityPlans: (data) => {
      return dispatch(fetchLocalityPlans(data));
    },
    addLocalityPlans: (data) => {
      return dispatch(addLocalityPlans(data));
    },
    addCityPlans: (data) => {
      return dispatch(addCityPlans(data));
    }
  }
}

LocationWiseManagePlans = connect(null, mapDispatchToProps)(LocationWiseManagePlans);

LocationWiseManagePlans = reduxForm({
  form: 'LocationWiseManagePlans',
  validate,
  //enableReinitialize: true
})(LocationWiseManagePlans);

export default LocationWiseManagePlans;
