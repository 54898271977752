import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {fetchSettings, updateSettings} from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeAmountField, normalizeNumber, isUrlValid} from '../../../constants';
import {uploadFile} from '../../../actions/common';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (values.facebookPageLink && values.facebookPageLink.trim() != '') {
    if(!isUrlValid(values.facebookPageLink)){
      errors.facebookPageLink = "Enter a valid url";
      hasErrors = true;
    }
  }



  if (values.instagramPageLink && values.instagramPageLink.trim() != '') {
    if(!isUrlValid(values.instagramPageLink)){
      errors.instagramPageLink = "Enter a valid url";
      hasErrors = true;
    }
  }





  return hasErrors && errors;
}

class WebsiteWideSettings extends Component {
	constructor(props){
    super(props);
    this.state = {
      arrayKnowMoreImages: []
    }
    this.onDrop = this.onDrop.bind(this);
  }

  submitForm(values, dispatch, props){
    return this.props.updateSettings(values)
    .then((response) => {
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchSettings();
  }

  fetchSettings(){
		this.setState({fetchingRecords: true});
    this.props.fetchSettings().then((response) => {
      let settings = response.value.data.data;
      let arrayKnowMoreImages = [];
      if(settings){
        if(settings.kycKnowMoreImage && settings.kycKnowMoreImage.id){
          arrayKnowMoreImages.push(settings.kycKnowMoreImage);
          settings = Object.assign(settings, {kycKnowMoreImage: settings.kycKnowMoreImage.id});
        }
        this.props.initialize(settings);
      }
      this.setState({fetchingRecords: false, settings: settings, arrayKnowMoreImages});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture, type) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({[type]: true});
      form_data.append("file", file_data);
      this.props.uploadFile(form_data, {upload_type:type}).then((response) => {
        this.setState({[type]: false, arrayKnowMoreImages: []});
        var payload = response.value.data.data;
        var arrayKnowMoreImages = [];
        if(payload && payload.id){
          if(type == "KYC_KNOW_MORE_IMAGE"){
            arrayKnowMoreImages.push(payload);
            this.setState({arrayKnowMoreImages: arrayKnowMoreImages});
  					this.props.change('kycKnowMoreImage', payload.id);
          }

        }
      }).catch((error)=>{
        this.setState({[type]: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/manage-settings/configuration">Settings</Link></li>
        <li className="breadcrumb-item active">Manage Settings</li>
      </ol>
        <h5 className="h5 mb-4">Manage Settings</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  name="refundableSecurity"
                  component={renderField}
                  type="text"
                  label="Security Deposit (Percentage of 1 month rent)"
                  normalize={normalizeNumber}
                />
              </div>

              <div className=" col-lg-6">
                <Field
                  name="extendButtonVisible"
                  component={renderField}
                  type="text"
                  label="Days before extend booking button visible"
                  normalize={normalizeNumber}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="bookingCancel"
                  component={renderField}
                  type="text"
                  label="Days before booking can be cancelled with full refund"
                  normalize={normalizeNumber}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="availableDatesDuration"
                  component={renderField}
                  type="text"
                  label="Days from today, within which user can select check-in date"
                  normalize={normalizeNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="contactUsEmail"
                  component={renderField}
                  label="Contact us email"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="contactUsPhone"
                  component={renderField}
                  label="Contact us phone"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="callUsAtPhone"
                  component={renderField}
                  label="Call us at phone"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="facebookPageLink"
                  component={renderField}
                  label="Facebook link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="instagramPageLink"
                  component={renderField}
                  label="Instagram link"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="androidAppLink"
                  component={renderField}
                  label="Android app link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="iosAppLink"
                  component={renderField}
                  label="iOS app link"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6">
                <ImageUploader
  								className={this.state.imageUploading?"loading":""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText='Home Page KYC Image'
                  onChange={(e) => this.onDrop(e, "KYC_KNOW_MORE_IMAGE")}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
  							/>
                <Field
                  name="kycKnowMoreImage"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayKnowMoreImages.length > 0 &&
                  this.state.arrayKnowMoreImages.map((obj, index) => {
                    return(
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.original} alt=""/></figure>
    									</div>
                    )
                  })
                }
              </div>
            </div>
            <div className="form-btn-group">
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: (data) => {
      return dispatch(fetchSettings(data));
    },
    updateSettings: (data) => {
      return dispatch(updateSettings(data));
    },
    uploadFile: (data) => {
      return dispatch(uploadFile(data));
    }
  }
}

WebsiteWideSettings = connect(null, mapDispatchToProps)(WebsiteWideSettings);

WebsiteWideSettings = reduxForm({
  form: 'WebsiteWideSettings',
  validate,
  //enableReinitialize: true
})(WebsiteWideSettings);

export default WebsiteWideSettings;
