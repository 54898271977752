import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {fetchPermissions, addRole, fetchRole} from '../../../actions/users';
import renderCheckboxGroup from '../../FormFields/CheckboxGroup2';


import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if(!values.name || values.name.trim() === ''){
    errors.name = "Enter role";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class RolesAdd extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
      roleId: props.match.params.roleId?props.match.params.roleId:null
    }
  }

  submitForm(values, dispatch, props){
    if(!values.permission || values.permission.length <= 0){
      toast.error("Please select at-least one permission");
      return;
    }

    return this.props.addRole(values).then((response) => {
      toast.success(response.value.data.message);
      this.props.history.push("/admin/user-management/roles/list");

		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
  }

  componentDidMount(){
    this.fetchPermissions();
    if(this.state.roleId){
      this.fetchRole(this.state.roleId);
    }
  }

  fetchPermissions(){
		this.setState({fetchingRecords: true});
    this.props.fetchPermissions().then((response) => {
      this.setState({fetchingRecords: false});
      var records = response.value.data.data;
      this.setState({records});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchRole(roleId){
		this.setState({fetchingRecords: true});
    this.props.fetchRole({roleId}).then((response) => {
      this.setState({fetchingRecords: false});
      var record = response.value.data.data;
      this.props.initialize({roleId: record.roleId, name: record.name, permission: record.permission});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/user-management/roles/list">Roles</Link></li>
        <li className="breadcrumb-item active">{this.state.roleId?"Edit":"Add"}</li>
      </ol>
      <h5 className="h5 mb-4">{this.state.roleId?"Edit":"Add"} Role</h5>
      <section className="formBg" style={{maxWidth:"850px"}}>
    		<form onSubmit={ handleSubmit(this.submitForm.bind(this)) } >
          <div className="row">
            <div className=" col-lg-6">
              <Field
                type="text"
                name='name'
                className="form-control"
                label="Role" component={renderField}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-12 col-md-12">
              <div className="basic-details">
                {/*<div className="heading">
                  <h6>Permissions</h6>
                </div>*/}
                {
                  this.state.records && this.state.records.length > 0 &&
                  <>


                      <Field
                        name="permission"
                        component={renderCheckboxGroup}
                        type="checkbox"
                        options={this.state.records}
                        id_field = "id"
                        label_field = 'title'
                        col_class = "col-lg-12"
                      />
                    </>

                }
                {/*<Field
                    name="role_modules"
                    component={renderCheckboxGroup}
                    type="checkbox"
                    options={this.state.records}
                  />*/}
              </div>
            </div>
          </div>
          <div className="form-btn-group">
            <Link to="/admin/user-management/roles/list" type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
            <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;

          </div>
        </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPermissions: (data) => {
      return dispatch(fetchPermissions(data));
    },
    addRole: (data) => {
      return dispatch(addRole(data));
    },
    fetchRole: (data) => {
      return dispatch(fetchRole(data));
    }
  }
}

RolesAdd = connect(null, mapDispatchToProps)(RolesAdd);

RolesAdd = reduxForm({
  form: 'RolesAdd',
  validate

})(RolesAdd);

export default RolesAdd;
