import React, { Component } from 'react';
import { createTextMask, createNumberMask } from 'redux-form-input-masks';
import queryString from 'query-string';
import $ from 'jquery';
export const APP_VERSION = '1';


/*export const SOCKET_URL = 'http://54.176.169.179:3038';
export const API_ROOT = 'http://54.176.169.179:3038';
export const APP_DOMAIN = 'coliv.com';
export const DEV_URL = 'https://localhost:3000';
export const LIVE_URL = 'http://colivadmin.illuminz.com';*/

export const SOCKET_URL = 'https://api.coliv.today';
export const API_ROOT = 'https://api.coliv.today';
export const APP_DOMAIN = 'coliv.com';
export const DEV_URL = 'https://localhost:3000';
export const LIVE_URL = 'https://admin.coliv.today';

export const WEBSITE_TITLE = "Coliv";
export const GOOGLE_MAP_API_KEY = "AIzaSyD7f9oPC3ISpPjasAB3BxxjyIS2d38rQVg";
//export const GOOGLE_MAP_API_KEY = "AIzaSyC9k14t4QDfaou_l7oGqUsO_4n32KLim5I";

export const CURRENCY_SYMBOL = "AED";
export const CURRENCY = "AED";
export const DEFAULT_DIAL_CODE = '971';
export const DEFAULT_COUNTRY_ID = 229;

export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('persist:root');
  window.location = LIVE_URL;
}


export function verifyAndUpdateAppVersion(){
  // Display App Version
  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version !== APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export function normalizeCouponField (value){
  var v = value.replace(/[^a-z0-9\s]/i, '');
  return v.toUpperCase();

}

export const AMENITY_TYPES = [
  {id: 1, name: 'Coliv'},
  {id: 2, name: 'Flat'},
  {id: 3, name: 'Room'},
];

export const BOOKINGS_STATUSES = [
  {id: -1, name: 'Payment pending'},
  {id: 0, name: 'Bed is not assigned'},
  {id: 1, name: 'Bed assigned'},
  {id: 3, name: 'Active'},
  {id: 4, name: 'Completed'},
  {id: 5, name: 'Canceled by user'},
  {id: 7, name: 'Canceled by Coliv'},
  {id: 11, name: 'Payment failed'},
  {id: 13, name: 'Recurring failed'}
];

export function normalizeFloorsField (value){
  var v = value.replace(/[^0-9\,\s]/g, '');
  return v;

}

export function normalizeAmountField(value){
  var v = value.replace(/[^0-9.\s]/g, '');
  if(isNaN(v)){
    v = parseFloat(v);
  }
  return v;
}

export function normalizeNumber(value){
  var v = value.replace(/[^0-9\s]/i, '');
  return v;
}


export function validateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function objToQueryParams(obj) {
  var str = [];
  for (var p in obj)
    //if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    //}
  return str.join("&");
}



export const ADD_ON_OPTIONS = {
  en:[{id:0, name: "No"}, {id:1, name: "Yes"}],
  ar:[{id:0, name: "No ar"}, {id:1, name: "Yes ar"}]
};

export const VARIATION_OPTIONS = {
  en:[{id:0, name: "No"}, {id:1, name: "Yes"}],
  ar:[{id:0, name: "No ar"}, {id:1, name: "Yes ar"}]
};

export const ATTRIBUTE_TYPE_OPTIONS = {
  en:[{id:1, name: "Text"}, {id:2, name: "Multi Selectable"}],
  ar:[{id:1, name: "Text ar"}, {id:2, name: "Multi Selectable ar"}]
};


export const BANNER_TYPES = {
  en:[{id:1, name: "Category"}, {id:2, name: "Product"}, {id:3, name: "Brand"}],
  ar:[{id:1, name: "Category"}, {id:2, name: "Product"}, {id:3, name: "Brand"}]
};


export const BANNER_DEVICE_TYPES = {
  en:[{id:1, name: "Mobile"}, {id:2, name: "Web"}],
  ar:[{id:1, name: "Mobile"}, {id:2, name: "Web"}]
};

export const BANNER_POSITIONS = {
  en:[{id:1, name: "Top"}, {id:2, name: "Bottom"}],
  ar:[{id:1, name: "Top"}, {id:2, name: "Bottom"}]
};

export const COUPON_DISCOUNT_TYPES = {
  en:[{id:1, name: "Flat"}, {id:2, name: "Percentage"}],
  ar:[{id:1, name: "Flat"}, {id:2, name: "Percentage"}],
};

export const COUPON_CODE_FORMATS = {
  en:[{id:1, name: "Aphanumeric"}, {id:2, name: "Alphabets"}, {id:3, name: "Numeric"}],
  ar:[{id:1, name: "Aphanumeric"}, {id:2, name: "Alphabets"}, {id:3, name: "Numeric"}]
};

export const COUPON_TYPES = {
  en:[{id:1, name: "Specific Coupon"}, {id:2, name: "Coupon without code"}, {id:3, name: "Coupon with code"} ],
  ar:[{id:1, name: "Specific Coupon"}, {id:2, name: "Coupon without code"}, {id:3, name: "Coupon with code"}]
};

export const COUPON_STATUS = {
  en:[{id:1, name: "Active"}, {id:2, name: "Inactive"}],
  ar:[{id:1, name: "Active"}, {id:2, name: "Inactive"}]
};

export const USER_STATUS = {
  en:[{id:1, name: "Active"}, {id:2, name: "Inactive"}],
  ar:[{id:1, name: "Active"}, {id:2, name: "Inactive"}]
};

export const WEEK_DAYS = {
  en:[{id:1, name: "Sunday"}, {id:2, name: "Monday"}, {id:3, name: "Tuesday"}, {id:4, name: "Wednesday"}, {id:5, name: "Thursday"}, {id:6, name: "Friday"}, {id:7, name: "Saturday"}],
  ar:[{id:1, name: "Sunday"}, {id:2, name: "Monday"}, {id:3, name: "Tuesday"}, {id:4, name: "Wednesday"}, {id:5, name: "Thursday"}, {id:6, name: "Friday"}, {id:7, name: "Saturday"}],
};

export const COUPON_FOR = {
  en:[{id:1, name: "Cart"},{id:2, name: "Category"}, {id:3, name: "Brand"}, {id:4, name: "Product"}],
  ar:[{id:1, name: "Cart"},{id:2, name: "Category"}, {id:3, name: "Brand"}, {id:4, name: "Product"}]
}


export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export const monthsArray = [
  {
    id: 1,
    name: 'Jan',
    fullName: 'January'
  }, {
    id: 2,
    name: 'Feb',
    fullName: 'February'
  }, {
    id: 3,
    name: 'Mar',
    fullName: 'March'
  }, {
    id: 4,
    name: 'Apr',
    fullName: 'April'
  }, {
    id: 5,
    name: 'May',
    fullName: 'May'
  }, {
    id: 6,
    name: 'Jun',
    fullName: 'June'
  }, {
    id: 7,
    name: 'Jul',
    fullName: 'July'
  }, {
    id: 8,
    name: 'Aug',
    fullName: 'August'
  }, {
    id: 9,
    name: 'Sep',
    fullName: 'September'
  }, {
    id: 10,
    name: 'Oct',
    fullName: 'October'
  }, {
    id: 11,
    name: 'Nov',
    fullName: 'November'
  }, {
    id: 12,
    name: 'Dec',
    fullName: 'December'
  }
];
export const daysArray = [
  {
    id: 1,
    name: 'Mon',
    fullName: 'Monday'
  }, {
    id: 2,
    name: 'Tue',
    fullName: 'Tuesday'
  }, {
    id: 3,
    name: 'Wed',
    fullName: 'Wednesday'
  }, {
    id: 4,
    name: 'Thu',
    fullName: 'Thursday'
  }, {
    id: 5,
    name: 'Fri',
    fullName: 'Friday'
  }, {
    id: 6,
    name: 'Sat',
    fullName: 'Saturday'
  }, {
    id: 7,
    name: 'Sun',
    fullName: 'Sunday'
  }
];
export function formatDate(milliseconds, formatType) {
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  var today = new Date(milliseconds);
  var cDate = today.getDate();
  var cMonth = today.getMonth();
  var cYear = today.getFullYear();

  var cHour = today.getHours();
  var ampm = cHour >= 12
    ? 'pm'
    : 'am';
  cHour = cHour % 12;
  cHour = cHour
    ? cHour
    : 12; // the hour '0' should be '12'
  if (cHour < 10) {
    cHour = "0" + cHour;
  }
  var cMin = today.getMinutes();
  if (cMin < 10) {
    cMin = "0" + cMin;
  }
  var cSec = today.getSeconds();
  if (cSec < 10) {
    cSec = "0" + cSec;
  }
  if (formatType === 'date') {
    return monthNames[cMonth] + " " + cDate + ", " + cYear;
  } else if (formatType === 'time') {
    return cHour + ":" + cMin + ":" + cSec + ' ' + ampm;
  } else {
    return monthNames[cMonth] + " " + cDate + ", " + cYear + " " + cHour + ":" + cMin + ":" + cSec + ' ' + ampm;
  }

}

export function convertMS(milliseconds) {
  var day,
    hour,
    minute,
    seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return {day: day, hour: hour, minute: minute, seconds: seconds};
}





export const MONTHS_ARRAY = [
  {
    id: '01',
    value: 'JAN'
  }, {
    id: '02',
    value: 'FEB'
  }, {
    id: '03',
    value: 'MAR'
  }, {
    id: '04',
    value: 'APR'
  }, {
    id: '05',
    value: 'MAY'
  }, {
    id: '06',
    value: 'JUN'
  }, {
    id: '07',
    value: 'JUL'
  }, {
    id: '08',
    value: 'AUG'
  }, {
    id: '09',
    value: 'SEP'
  }, {
    id: '10',
    value: 'OCT'
  }, {
    id: '11',
    value: 'NOV'
  }, {
    id: '12',
    value: 'DEC'
  }
];


export function FromatValidationErrors(errors, error_keys) {
  Object.keys(errors).map(function(key) {
    error_keys[key] = errors[key][0];
    return key;
  });
  return error_keys;
}

export function jsUcfirst(string) {
  if(!string){
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}



export function currentYear() {
  var date = new Date();
  var year = date.getFullYear();
  year = year.toString();
  var lastCharYear = year.slice(-2);
  return lastCharYear;
}

export function currentMonth() {
  var d = new Date();
  var n = d.getMonth();
  n = n + 1;
  if (n < 10) {
    n = "0" + n.toString();
  }
  n = n.toString();

  return n;
}




export function nameFieldValidation(value) {
  var error = false;
  if (!value || value.trim() === '') {
    error = 'Enter a name';
  } else if (value.trim().length < 2) {
    error = 'Name must be at least 2 characters long';
  } else if (value.trim().length > 24) {
    error = 'Name can not have more than 24 characters';
  }
  return error;
}






export function _dispatch(nextState, rerender = false) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender
  }
}


export function removeDuplicates(arr){
    let unique_array = []
    for(let i = 0;i < arr.length; i++){
        if(unique_array.indexOf(arr[i]) === -1){
            unique_array.push(arr[i])
        }
    }
    return unique_array
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function mysqlDateToJavascript(mysqlDate){
  var dateParts = mysqlDate.split("-");
  var jsDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2));
  return jsDate;
}


export function yyyymmdd(date){
  var monthsArray = ["Jan","Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var x = date;
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  var monthName = monthsArray[x.getMonth()];
  (d.length === 1) && (d = '0' + d);
  (m.length === 1) && (m = '0' + m);
  var yyyymmdd = y +"-"+ m +"-"+ d;
  var format2 = d+" "+monthName+", "+y;
  var format3 = monthName+", "+y;
  var format4 = d+" "+monthName;
  return [yyyymmdd, format2, format3, format4];
}


export function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && n >= 0;
}

export function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}


export function sortByField(e, field, queryParams){
  e.preventDefault();
  var order = 'asc';
  if($(e.target).parent().hasClass('desc')){
    $(e.target).parent().removeClass('desc').addClass('asc');
    order = 'asc';
  }else if($(e.target).parent().hasClass('asc')){
    $(e.target).parent().removeClass('asc').addClass('desc');
    order = 'desc';
  }else{
    $(e.target).parent().addClass('asc');
    order = 'asc'
  }
  $("th.orderable").each(function(){
    if(!$(this).hasClass(field)){
      $(this).removeClass('desc').removeClass('asc');
    }
  })
  var params = queryString.parse(queryParams);
  params = Object.assign(params, {sortBy: field, order: order});
  return params = queryString.stringify(params);
}

export const myCustomMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: char => char.toUpperCase(),
  },
  a: {
    regExp: /[A-Za-z]/,
    transform: char => char.toLowerCase(),
  },
  "*": {
    regExp: /[0-9A-Za-z]/
  },
  "P": {
    regExp: /[0-9A-Za-z]/,
    transform: char => char.toUpperCase(),
  },
  "Z": {
    regExp: /[.]/

  }
};

export const currencyMask = createNumberMask({
  prefix: '₦',
  decimalPlaces: 2,
  locale: 'en-NG',
});
export const numberMask = createNumberMask({

});

export const priceMask = createTextMask({
  pattern: '9999Z99',
  maskDefinitions: myCustomMaskDefinitions,
  guide: true
})

export const maxQuantity = createTextMask({
  pattern: '999',
  guide: false
});

export const couponCodeMask = createTextMask({
  pattern: 'PPPPPPPPPPPPPPPPPPPP',
  maskDefinitions: myCustomMaskDefinitions,
  guide: false
});

export const phoneMask = createTextMask({
  pattern: '9999999999',
});

export const otpMask = createTextMask({
  pattern: '9999',
  placeholder: ' '
});

export const zipMask = createTextMask({
  pattern: '99999',
});


export const DOCUMENT_TYPES = {
  PROFILE_IMAGE:0,
  ID_PROOF:1,
  CURRENT_UTILITY_BILL:2,
  PROFESSIONAL_TRADE_CERTIFICATE:3,
  CAC:4
};



export const DOC_MIME_TYPES = [
  'image/*',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export function timeToAmPm(time){
  var timeString = "18:00";
  var H = +timeString.substr(0, 2);
  var h = (H % 12) || 12;
  var ampm = H < 12 ? "AM" : "PM";
  timeString = h + timeString.substr(2, 3) + " "+ampm;
  return timeString;
}

export function isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null){
      return false;
    }else{
      return true;
    }
}

export function getCategoryLevelDashes(level){
  var dashes = "";
  if(parseInt(level) > 0){
    for(var i=0; i<level-1; i++){
      dashes += '--';
    }
  }
  return dashes;
}

export function getQueryParam(queryParams, param){
  var val = '';
  var params = queryString.parse(queryParams);
  if(params[param]){
    val = params[param];
  }
  return val;
}

export function renderPrice(obj){
  return(
    <>
    {
      obj && obj.length > 0 &&
      obj.map((member, memberIndex) => {
        return(
          <li key = {memberIndex} className="nowrap">{member.currencyCode} {parseFloat(member.value).toFixed(2)}</li>
        )
      })
    }
    </>
  )
}

export function currencyFormat(num){
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}



export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const LOCAL_ERROR = 'LOCAL_ERROR';

export const COUNTRIES = [
    {
      name: 'Afghanistan',
      label: '+93',
      code: '93',
      countryCode: 'AF'
    },
    {
      name: 'Albania',
      label: '+355',
      code: '355',
      countryCode: 'AL'
    },
    {
      name: 'Algeria',
      label: '+213',
      code: '213',
      countryCode: 'DZ'
    },
    {
      name: 'American Samoa',
      label: '+1-684',
      code: '1-684',
      countryCode: 'AS'
    },
    {
      name: 'Andorra',
      label: '+376',
      code: '376',
      countryCode: 'AD'
    },
    {
        name: 'Angola',
        label: '+244',
        code: '244',
        countryCode: 'AO'
    },
    {
        name: 'Anguilla',
        label: '+1-264',
        code: '1-264',
        countryCode: 'AI'
    },
    {
        name: 'Antarctica',
        label: '+672',
        code: '672',
        countryCode: 'AQ'
    },
    {
        name: 'Antigua and Barbuda',
        label: '+1-268',
        code: '1-268',
        countryCode: 'AG'
    },
    {
        name: 'Argentina',
        label: '+54',
        code: '54',
        countryCode: 'AR'
    },
    {
        name: 'Armenia',
        label: '+374',
        code: '374',
        countryCode: 'AM'
    },
    {
        name: 'Aruba',
        label: '+297',
        code: '297',
        countryCode: 'AW'
    },
    {
        name: 'Australia',
        label: '+61',
        code: '61',
        countryCode: 'AU'
    },
    {
        name: 'Austria',
        label: '+43',
        code: '43',
        countryCode: 'AT'
    },
    {
        name: 'Azerbaijan',
        label: '+994',
        code: '994',
        countryCode: 'AZ'
    },
    {
        name: 'Bahamas',
        label: '+1-242',
        code: '1-242',
        countryCode: 'BS'
    },
    {
        name: 'Bahrain',
        label: '+973',
        code: '973',
        countryCode: 'BH'
    },
    {
        name: 'Bangladesh',
        label: '+880',
        code: '880',
        countryCode: 'BD'
    },
    {
        name: 'Barbados',
        label: '+1-246',
        code: '1-246',
        countryCode: 'BB'
    },
    {
        name: 'Belarus',
        label: '+375',
        code: '375',
        countryCode: 'BY'
    },
    {
        name: 'Belgium',
        label: '+32',
        code: '32',
        countryCode: 'BE'
    },
    {
        name: 'Belize',
        label: '+501',
        code: '501',
        countryCode: 'BZ'
    },
    {
        name: 'Benin',
        label: '+229',
        code: '229',
        countryCode: 'BJ'
    },
    {
        name: 'Bermuda',
        label: '+1-441',
        code: '1-441',
        countryCode: 'BM'
    },
    {
        name: 'Bhutan',
        label: '+975',
        code: '975',
        countryCode: 'BT'
    },
    {
        name: 'Bolivia',
        label: '+591',
        code: '591',
        countryCode: 'BO'
    },
    {
        name: 'Bosnia and Herzegovina',
        label: '+387',
        code: '387',
        countryCode: 'BA'
    },
    {
        name: 'Botswana',
        label: '+267',
        code: '267',
        countryCode: 'BW'
    },
    {
        name: 'Brazil',
        label: '+55',
        code: '55',
        countryCode: 'BR'
    },
    {
        name: 'British Indian Ocean Territory',
        label: '+246',
        code: '246',
        countryCode: 'IO'
    },
    {
        name: 'British Virgin Islands',
        label: '+1-284',
        code: '1-284',
        countryCode: 'VG'
    },
    {
        name: 'Brunei',
        label: '+673',
        code: '673',
        countryCode: 'BN'
    },
    {
        name: 'Bulgaria',
        label: '+359',
        code: '359',
        countryCode: 'BG'
    },
    {
        name: 'Burkina Faso',
        label: '+226',
        code: '226',
        countryCode: 'BF'
    },
    {
        name: 'Burundi',
        label: '+257',
        code: '257',
        countryCode: 'BI'
    },
    {
        name: 'Cambodia',
        label: '+855',
        code: '855',
        countryCode: 'KH'
    },
    {
        name: 'Cameroon',
        label: '+237',
        code: '237',
        countryCode: 'CM'
    },
    {
        name: 'Canada',
        label: '+1',
        code: '1',
        countryCode: 'CA'
    },
    {
        name: 'Cape Verde',
        label: '+238',
        code: '238',
        countryCode: 'CV'
    },
    {
      name: 'Cayman Islands',
      label: '+1-345',
      code: '1-345',
      countryCode: 'KY'
    },
    {
      name: 'Central African Republic',
      label: '+236',
      code: '236',
      countryCode: 'CF'
    },
    {
      name: 'Chad',
      label: '+235',
      code: '235',
      countryCode: 'TD'
    },
    {
      name: 'Chile',
      label: '+56',
      code: '56',
      countryCode: 'CL'
    },
    {
      name: 'China',
      label: '+86',
      code: '86',
      countryCode: 'CN'
    },
    {
      name: 'Christmas Island',
      label: '+61',
      code: '61',
      countryCode: 'CX'
    },
    {
      name: 'Cocos Islands',
      label: '+61',
      code: '61',
      countryCode: 'CC'
    },
    {
      name: 'Colombia',
      label: '+57',
      code: '57',
      countryCode: 'CO'
    },
    {
      name: 'Comoros',
      label: '+269',
      code: '269',
      countryCode: 'KM'
    },
    {
      name: 'Cook Islands',
      label: '+682',
      code: '682',
      countryCode: 'CK'
    },
    {
      name: 'Costa Rica',
      label: '+506',
      code: '506',
      countryCode: 'CR'
    },
    {
      name: 'Croatia',
      label: '+385',
      code: '385',
      countryCode: 'HR'
    },
    {
      name: 'Cuba',
      label: '+53',
      code: '53',
      countryCode: 'CU'
    },
    {
      name: 'Curacao',
      label: '+599',
      code: '599',
      countryCode: 'CW'
    },
    {
      name: 'Cyprus',
      label: '+357',
      code: '357',
      countryCode: 'CY'
    },
    {
      name: 'Czech Republic',
      label: '+420',
      code: '420',
      countryCode: 'CZ'
    },
    {
      name: 'Democratic Republic of the Congo',
      label: '+243',
      code: '243',
      countryCode: 'CD'
    },
    {
      name: 'Denmark',
      label: '+45',
      code: '45',
      countryCode: 'DK'
    },
    {
      name: 'Djibouti',
      label: '+253',
      code: '253',
      countryCode: 'DJ'
    },
    {
      name: 'Dominica',
      label: '+1-767',
      code: '1-767',
      countryCode: 'DM'
    },
    {
      name: 'Dominican Republic',
      label: '+1-809, 1-829, 1-849',
      code: '1-809, 1-829, 1-849',
      countryCode: 'DO'
    },
    {
      name: 'East Timor',
      label: '+670',
      code: '670',
      countryCode: 'TL'
    },
    {
      name: 'Ecuador',
      label: '+593',
      code: '593',
      countryCode: 'EC'
    },
    {
      name: 'Egypt',
      label: '+20',
      code: '20',
      countryCode: 'EG'
    },
    {
      name: 'El Salvador',
      label: '+503',
      code: '503',
      countryCode: 'SV'
    },
    {
      name: 'Equatorial Guinea',
      label: '+240',
      code: '240',
      countryCode: 'GQ'
    },
    {
      name: 'Eritrea',
      label: '+291',
      code: '291',
      countryCode: 'ER'
    },
    {
      name: 'Estonia',
      label: '+372',
      code: '372',
      countryCode: 'EE'
    },
    {
      name: 'Ethiopia',
      label: '+251',
      code: '251',
      countryCode: 'ET'
    },
    {
      name: 'Falkland Islands',
      label: '+500',
      code: '500',
      countryCode: 'FK'
    },
    {
      name: 'Faroe Islands',
      label: '+298',
      code: '298',
      countryCode: 'FO'
    },
    {
      name: 'Fiji',
      label: '+679',
      code: '679',
      countryCode: 'FJ'
    },
    {
      name: 'Finland',
      label: '+358',
      code: '358',
      countryCode: 'FI'
    },
    {
      name: 'France',
      label: '+33',
      code: '33',
      countryCode: 'FR'
    },
    {
      name: 'French Polynesia',
      label: '+689',
      code: '689',
      countryCode: 'PF'
    },
    {
      name: 'Gabon',
      label: '+241',
      code: '241',
      countryCode: 'GA'
    },
    {
      name: 'Gambia',
      label: '+220',
      code: '220',
      countryCode: 'GM'
    },
    {
      name: 'Georgia',
      label: '+995',
      code: '995',
      countryCode: 'GE'
    },
    {
      name: 'Germany',
      label: '+49',
      code: '49',
      countryCode: 'DE'
    },
    {
      name: 'Ghana',
      label: '+233',
      code: '233',
      countryCode: 'GH'
    },
    {
      name: 'Gibraltar',
      label: '+350',
      code: '350',
      countryCode: 'GI'
    },
    {
      name: 'Greece',
      label: '+30',
      code: '30',
      countryCode: 'GR'
    },
    {
      name: 'Greenland',
      label: '+299',
      code: '299',
      countryCode: 'GL'
    },
    {
      name: 'Grenada',
      label: '+1-473',
      code: '1-473',
      countryCode: 'GD'
    },
    {
      name: 'Guam',
      label: '+1-671',
      code: '1-671',
      countryCode: 'GU'
    },
    {
      name: 'Guatemala',
      label: '+502',
      code: '502',
      countryCode: 'GT'
    },
    {
      name: 'Guernsey',
      label: '+44-1481',
      code: '44-1481',
      countryCode: 'GG'
    },
    {
      name: 'Guinea',
      label: '+224',
      code: '224',
      countryCode: 'GN'
    },
    {
      name: 'Guinea-Bissau',
      label: '+245',
      code: '245',
      countryCode: 'GW'
    },
    {
      name: 'Guyana',
      label: '+592',
      code: '592',
      countryCode: 'GY'
    },
    {
      name: 'Haiti',
      label: '+509',
      code: '509',
      countryCode: 'HT'
    },
    {
      name: 'Honduras',
      label: '+504',
      code: '504',
      countryCode: 'HN'
    },
    {
      name: 'Hong Kong',
      label: '+852',
      code: '852',
      countryCode: 'HK'
    },
    {
      name: 'Hungary',
      label: '+36',
      code: '36',
      countryCode: 'HU'
    },
    {
      name: 'Iceland',
      label: '+354',
      code: '354',
      countryCode: 'IS'
    },
    {
      name: 'India',
      label: '+91',
      code: '91',
      countryCode: 'IN'
    },
    {
      name: 'Indonesia',
      label: '+62',
      code: '62',
      countryCode: 'ID'
    },
    {
      name: 'Iran',
      label: '+98',
      code: '98',
      countryCode: 'IR'
    },
    {
      name: 'Iraq',
      label: '+964',
      code: '964',
      countryCode: 'IQ'
    },
    {
      name: 'Ireland',
      label: '+353',
      code: '353',
      countryCode: 'IE'
    },
    {
      name: 'Isle of Man',
      label: '+44-1624',
      code: '44-1624',
      countryCode: 'IM'
    },
    {
      name: 'Israel',
      label: '+972',
      code: '972',
      countryCode: 'IL'
    },
    {
      name: 'Italy',
      label: '+39',
      code: '39',
      countryCode: 'IT'
    },
    {
      name: 'Ivory Coast',
      label: '+225',
      code: '225',
      countryCode: 'CI'
    },
    {
      name: 'Jamaica',
      label: '+1-876',
      code: '1-876',
      countryCode: 'JM'
    },
    {
      name: 'Japan',
      label: '+81',
      code: '81',
      countryCode: 'JP'
    },
    {
      name: 'Jersey',
      label: '+44-1534',
      code: '44-1534',
      countryCode: 'JE'
    },
    {
      name: 'Jordan',
      label: '+962',
      code: '962',
      countryCode: 'JO'
    },
    {
      name: 'Kazakhstan',
      label: '+7',
      code: '7',
      countryCode: 'KZ'
    },
    {
      name: 'Kenya',
      label: '+254',
      code: '254',
      countryCode: 'KE'
    },
    {
      name: 'Kiribati',
      label: '+686',
      code: '686',
      countryCode: 'KI'
    },
    {
      name: 'Kosovo',
      label: '+383',
      code: '383',
      countryCode: 'XK'
    },
    {
      name: 'Kuwait',
      label: '+965',
      code: '965',
      countryCode: 'KW'
    },
    {
      name: 'Kyrgyzstan',
      label: '+996',
      code: '996',
      countryCode: 'KG'
    },
    {
      name: 'Laos',
      label: '+856',
      code: '856',
      countryCode: 'LA'
    },
    {
      name: 'Latvia',
      label: '+371',
      code: '371',
      countryCode: 'LV'
    },
    {
      name: 'Lebanon',
      label: '+961',
      code: '961',
      countryCode: 'LB'
    },
    {
      name: 'Lesotho',
      label: '+266',
      code: '266',
      countryCode: 'LS'
    },
    {
      name: 'Liberia',
      label: '+231',
      code: '231',
      countryCode: 'LR'
    },
    {
      name: 'Libya',
      label: '+218',
      code: '218',
      countryCode: 'LY'
    },
    {
      name: 'Liechtenstein',
      label: '+423',
      code: '423',
      countryCode: 'LI'
    },
    {
      name: 'Lithuania',
      label: '+370',
      code: '370',
      countryCode: 'LT'
    },
    {
      name: 'Luxembourg',
      label: '+352',
      code: '352',
      countryCode: 'LU'
    },
    {
      name: 'Macau',
      label: '+853',
      code: '853',
      countryCode: 'MO'
    },
    {
      name: 'Macedonia',
      label: '+389',
      code: '389',
      countryCode: 'MK'
    },
    {
      name: 'Madagascar',
      label: '+261',
      code: '261',
      countryCode: 'MG'
    },
    {
      name: 'Malawi',
      label: '+265',
      code: '265',
      countryCode: 'MW'
    },
    {
      name: 'Malaysia',
      label: '+60',
      code: '60',
      countryCode: 'MY'
    },
    {
      name: 'Maldives',
      label: '+960',
      code: '960',
      countryCode: 'MV'
    },
    {
      name: 'Mali',
      label: '+223',
      code: '223',
      countryCode: 'ML'
    },
    {
      name: 'Malta',
      label: '+356',
      code: '356',
      countryCode: 'MT'
    },
    {
      name: 'Marshall Islands',
      label: '+692',
      code: '692',
      countryCode: 'MH'
    },
    {
      name: 'Mauritania',
      label: '+222',
      code: '222',
      countryCode: 'MR'
    },
    {
      name: 'Mauritius',
      label: '+230',
      code: '230',
      countryCode: 'MU'
    },
    {
      name: 'Mayotte',
      label: '+262',
      code: '262',
      countryCode: 'YT'
    },
    {
      name: 'Mexico',
      label: '+52',
      code: '52',
      countryCode: 'MX'
    },
    {
      name: 'Micronesia',
      label: '+691',
      code: '691',
      countryCode: 'FM'
    },
    {
      name: 'Moldova',
      label: '+373',
      code: '373',
      countryCode: 'MD'
    },
    {
      name: 'Monaco',
      label: '+377',
      code: '377',
      countryCode: 'MC'
    },
    {
      name: 'Mongolia',
      label: '+976',
      code: '976',
      countryCode: 'MN'
    },
    {
      name: 'Montenegro',
      label: '+382',
      code: '382',
      countryCode: 'ME'
    },
    {
      name: 'Montserrat',
      label: '+1-664',
      code: '1-664',
      countryCode: 'MS'
    },
    {
      name: 'Morocco',
      label: '+212',
      code: '212',
      countryCode: 'MA'
    },
    {
      name: 'Mozambique',
      label: '+258',
      code: '258',
      countryCode: 'MZ'
    },
    {
      name: 'Myanmar',
      label: '+95',
      code: '95',
      countryCode: 'MM'
    },
    {
      name: 'Namibia',
      label: '+264',
      code: '264',
      countryCode: 'NA'
    },
    {
      name: 'Nauru',
      label: '+674',
      code: '674',
      countryCode: 'NR'
    },
    {
      name: 'Nepal',
      label: '+977',
      code: '977',
      countryCode: 'NP'
    },
    {
      name: 'Netherlands',
      label: '+31',
      code: '31',
      countryCode: 'NL'
    },
    {
      name: 'Netherlands Antilles',
      label: '+599',
      code: '599',
      countryCode: 'AN'
    },
    {
      name: 'New Caledonia',
      label: '+687',
      code: '687',
      countryCode: 'NC'
    },
    {
      name: 'New Zealand',
      label: '+64',
      code: '64',
      countryCode: 'NZ'
    },
    {
      name: 'Nicaragua',
      label: '+505',
      code: '505',
      countryCode: 'NI'
    },
    {
      name: 'Niger',
      label: '+227',
      code: '227',
      countryCode: 'NE'
    },
    {
      name: 'Nigeria',
      label: '+234',
      code: '234',
      countryCode: 'NG'
    },
    {
      name: 'Niue',
      label: '+683',
      code: '683',
      countryCode: 'NU'
    },
    {
      name: 'North Korea',
      label: '+850',
      code: '850',
      countryCode: 'KP'
    },
    {
      name: 'Northern Mariana Islands',
      label: '+1-670',
      code: '1-670',
      countryCode: 'MP'
    },
    {
      name: 'Norway',
      label: '+47',
      code: '47',
      countryCode: 'NO'
    },
    {
      name: 'Oman',
      label: '+968',
      code: '968',
      countryCode: 'OM'
    },
    {
      name: 'Pakistan',
      label: '+92',
      code: '92',
      countryCode: 'PK'
    },
    {
      name: 'Palau',
      label: '+680',
      code: '680',
      countryCode: 'PW'
    },
    {
      name: 'Palestine',
      label: '+970',
      code: '970',
      countryCode: 'PS'
    },
    {
      name: 'Panama',
      label: '+507',
      code: '507',
      countryCode: 'PA'
    },
    {
      name: 'Papua New Guinea',
      label: '+675',
      code: '675',
      countryCode: 'PG'
    },
    {
      name: 'Paraguay',
      label: '+595',
      code: '595',
      countryCode: 'PY'
    },
    {
      name: 'Peru',
      label: '+51',
      code: '51',
      countryCode: 'PE'
    },
    {
      name: 'Philippines',
      label: '+63',
      code: '63',
      countryCode: 'PH'
    },
    {
      name: 'Pitcairn',
      label: '+64',
      code: '64',
      countryCode: 'PN'
    },
    {
      name: 'Poland',
      label: '+48',
      code: '48',
      countryCode: 'PL'
    },
    {
      name: 'Portugal',
      label: '+351',
      code: '351',
      countryCode: 'PT'
    },
    {
      name: 'Puerto Rico',
      label: '+1-787, 1-939',
      code: '1-787, 1-939',
      countryCode: 'PR'
    },
    {
      name: 'Qatar',
      label: '+974',
      code: '974',
      countryCode: 'QA'
    },
    {
      name: 'Republic of the Congo',
      label: '+242',
      code: '242',
      countryCode: 'CG'
    },
    {
      name: 'Reunion',
      label: '+262',
      code: '262',
      countryCode: 'RE'
    },
    {
      name: 'Romania',
      label: '+40',
      code: '40',
      countryCode: 'RO'
    },
    {
      name: 'Russia',
      label: '+7',
      code: '7',
      countryCode: 'RU'
    },
    {
      name: 'Rwanda',
      label: '+250',
      code: '250',
      countryCode: 'RW'
    },
    {
      name: 'Saint Barthelemy',
      label: '+590',
      code: '590',
      countryCode: 'BL'
    },
    {
      name: 'Saint Helena',
      label: '+290',
      code: '290',
      countryCode: 'SH'
    },
    {
      name: 'Saint Kitts and Nevis',
      label: '+1-869',
      code: '1-869',
      countryCode: 'KN'
    },
    {
      name: 'Saint Lucia',
      label: '+1-758',
      code: '1-758',
      countryCode: 'LC'
    },
    {
      name: 'Saint Martin',
      label: '+590',
      code: '590',
      countryCode: 'MF'
    },
    {
      name: 'Saint Pierre and Miquelon',
      label: '+508',
      code: '508',
      countryCode: 'PM'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      label: '+1-784',
      code: '1-784',
      countryCode: 'VC'
    },
    {
      name: 'Samoa',
      label: '+685',
      code: '685',
      countryCode: 'WS'
    },
    {
      name: 'San Marino',
      label: '+378',
      code: '378',
      countryCode: 'SM'
    },
    {
      name: 'Sao Tome and Principe',
      label: '+239',
      code: '239',
      countryCode: 'ST'
    },
    {
      name: 'Saudi Arabia',
      label: '+966',
      code: '966',
      countryCode: 'SA'
    },
    {
      name: 'Senegal',
      label: '+221',
      code: '221',
      countryCode: 'SN'
    },
    {
      name: 'Serbia',
      label: '+381',
      code: '381',
      countryCode: 'RS'
    },
    {
      name: 'Seychelles',
      label: '+248',
      code: '248',
      countryCode: 'SC'
    },
    {
      name: 'Sierra Leone',
      label: '+232',
      code: '232',
      countryCode: 'SL'
    },
    {
      name: 'Singapore',
      label: '+65',
      code: '65',
      countryCode: 'SG'
    },
    {
      name: 'Sint Maarten',
      label: '+1-721',
      code: '1-721',
      countryCode: 'SX'
    },
    {
      name: 'Slovakia',
      label: '+421',
      code: '421',
      countryCode: 'SK'
    },
    {
      name: 'Slovenia',
      label: '+386',
      code: '386',
      countryCode: 'SI'
    },
    {
      name: 'Solomon Islands',
      label: '+677',
      code: '677',
      countryCode: 'SB'
    },
    {
      name: 'Somalia',
      label: '+252',
      code: '252',
      countryCode: 'SO'
    },
    {
      name: 'South Africa',
      label: '+27',
      code: '27',
      countryCode: 'ZA'
    },
    {
      name: 'South Korea',
      label: '+82',
      code: '82',
      countryCode: 'KR'
    },
    {
      name: 'South Sudan',
      label: '+211',
      code: '211',
      countryCode: 'SS'
    },
    {
      name: 'Spain',
      label: '+34',
      code: '34',
      countryCode: 'ES'
    },
    {
      name: 'Sri Lanka',
      label: '+94',
      code: '94',
      countryCode: 'LK'
  },
  {
      name: 'Sudan',
      label: '+249',
      code: '249',
      countryCode: 'SD'
  },
  {
      name: 'Suriname',
      label: '+597',
      code: '597',
      countryCode: 'SR'
  },
  {
      name: 'Svalbard and Jan Mayen',
      label: '+47',
      code: '47',
      countryCode: 'SJ'
  },
  {
      name: 'Swaziland',
      label: '+268',
      code: '268',
      countryCode: 'SZ'
  },
  {
      name: 'Sweden',
      label: '+46',
      code: '46',
      countryCode: 'SE'
  },
  {
      name: 'Switzerland',
      label: '+41',
      code: '41',
      countryCode: 'CH'
  },
  {
      name: 'Syria',
      label: '+963',
      code: '963',
      countryCode: 'SY'
  },
  {
      name: 'Taiwan',
      label: '+886',
      code: '886',
      countryCode: 'TW'
  },
  {
      name: 'Tajikistan',
      label: '+992',
      code: '992',
      countryCode: 'TJ'
  },
  {
      name: 'Tanzania',
      label: '+255',
      code: '255',
      countryCode: 'TZ'
  },
  {
      name: 'Thailand',
      label: '+66',
      code: '66',
      countryCode: 'TH'
  },
  {
      name: 'Togo',
      label: '+228',
      code: '228',
      countryCode: 'TG'
  },
  {
      name: 'Tokelau',
      label: '+690',
      code: '690',
      countryCode: 'TK'
  },
  {
      name: 'Tonga',
      label: '+676',
      code: '676',
      countryCode: 'TO'
  },
  {
      name: 'Trinidad and Tobago',
      label: '+1-868',
      code: '1-868',
      countryCode: 'TT'
  },
  {
      name: 'Tunisia',
      label: '+216',
      code: '216',
      countryCode: 'TN'
  },
  {
      name: 'Turkey',
      label: '+90',
      code: '90',
      countryCode: 'TR'
  },
  {
      name: 'Turkmenistan',
      label: '+993',
      code: '993',
      countryCode: 'TM'
  },
  {
      name: 'Turks and Caicos Islands',
      label: '+1-649',
      code: '1-649',
      countryCode: 'TC'
  },
  {
      name: 'Tuvalu',
      label: '+688',
      code: '688',
      countryCode: 'TV'
  },
  {
      name: 'U.S. Virgin Islands',
      label: '+1-340',
      code: '1-340',
      countryCode: 'VI'
  },
  {
      name: 'UG',
      label: '+256',
      code: '256',
      countryCode: 'UG'
  },
  {
      name: 'Ukraine',
      label: '+380',
      code: '380',
      countryCode: 'UA'
  },
  {
      name: 'United Arab Emirates',
      label: '+971',
      code: '971',
      countryCode: 'AE'
  },
  {
      name: 'United Kingdom',
      label: '+44',
      code: '44',
      countryCode: 'GB'
  },
  {
      name: 'United States',
      label: '+1',
      code: '1',
      countryCode: 'US'
  },
  {
      name: 'Uruguay',
      label: '+598',
      code: '598',
      countryCode: 'UY'
  },
  {
      name: 'Uzbekistan',
      label: '+998',
      code: '998',
      countryCode: 'UZ'
  },
  {
      name: 'Vanuatu',
      label: '+678',
      code: '678',
      countryCode: 'VU'
  },
  {
      name: 'Vatican',
      label: '+379',
      code: '379',
      countryCode: 'VA'
  },
  {
      name: 'Venezuela',
      label: '+58',
      code: '58',
      countryCode: 'VE'
  },
  {
      name: 'Vietnam',
      label: '+84',
      code: '84',
      countryCode: 'VN'
  },
  {
      name: 'Wallis and Futuna',
      label: '+681',
      code: '681',
      countryCode: 'WF'
  },
  {
      name: 'Western Sahara',
      label: '+212',
      code: '212',
      countryCode: 'EH'
  },
  {
      name: 'Yemen',
      label: '+967',
      code: '967',
      countryCode: 'YE'
  },
  {
      name: 'Zambia',
      label: '+260',
      code: '260',
      countryCode: 'ZM'
  },
  {
      name: 'Zimbabwe',
      label: '+263',
      code: '263',
      countryCode: 'ZW'
  }
];
