import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderField from '../FormFields/renderField';
import {Redirect, BrowserRouter, Link, withRouter} from 'react-router-dom';
import { NotificationManager} from 'react-notifications';
import {toast} from 'react-toastify';
import { validateEmail} from '../../constants';
import { resetPassword } from '../../actions/users';



//Client side validation
function validate(values) {
  var errors = {};
  var compare_passwords = false;
  var hasErrors = false;
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }else if(values.password.length < 6){
    errors.password = "Password must be at-least 6 characters long";
    hasErrors = true;
  }







  return hasErrors && errors;
}

//submit login form
const submitForm = (values, dispatch, props) => {

  return dispatch(resetPassword(values))
    .then((response) => {
        dispatch(reset("ResetPasswordForm"));

        props.history.push("/signin");
        toast.success(response.value.data.message);
      }).catch(function(error){
        if (error.response) {
          toast.error(error.response.data.message);
          if(error.response.data.errors){
            throw new SubmissionError(error.response.data.errors);
          }
        } else if (error.request) {
          toast.error("Network error!");
        } else {
          toast.error(error.message);
        }
      });

  }



class ResetPasswordForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoggedIn: (props.user && props.user.status)?  props.user.status : false,
      token: props.match.params.token
    }
  }

  //http://localhost:3000/resetpassword/893RJ3BKJjBHqAjWJByzvdQEyAmYPAF3

  componentDidMount(){
    this.props.initialize({token: this.state.token});
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.user.status === 'authenticated'){
      this.setState({
        isLoggedIn: true
      });
    }
  }

  render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    }
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <div className="loginForm">
        <h4 className="mb-1">Reset Password</h4>
        <p className="marginB42"></p>
        <form onSubmit={handleSubmit(submitForm)}>
          <Field
            name="password"
            component={renderField}
            type="password"
            label="New Password"
          />

          <button type="submit" disabled={submitting} className={`${"btn btn-primary btn-block hvr-shadow"} ${submitting?'btn-loader':''}`}>Submit</button>

        </form>
        <div className="text-center marginT40">
          <Link to="/signin" className="btn-link">Sign in</Link>
        </div>
      </div>
    )
  }
}

ResetPasswordForm = reduxForm({
  form: 'ResetPasswordForm',
  validate
})(ResetPasswordForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}
ResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);

export default withRouter(ResetPasswordForm);
