import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderTextarea from '../../FormFields/renderTextarea';
import {fetchReview, updateReview} from '../../../actions/bookings';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.rating || values.rating === '') {
    errors.rating = "Select rating";
    hasErrors = true;
  }

  if (!values.review || values.review.trim() === '') {
    errors.review = "Enter review";
    hasErrors = true;
  }



  return hasErrors && errors;
}

class EditReview extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.reviewId
    }
  }

  submitForm(values, dispatch, props){
    return this.props.updateReview(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    if(this.state.id){
      this.fetchReview(this.state.id);
    }
  }






  fetchReview(id){
		this.props.fetchReview({id}).then((response) => {
      let reviewDetail = response.value.data.data;
      this.props.initialize({
        id: reviewDetail.id,
        rating: reviewDetail.rating,
        review: reviewDetail.review

      });
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }







  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/landmarks/list?pageNumber=1">Rating & Reviews</Link></li>
        <li className="breadcrumb-item active">Edit</li>
      </ol>
        <h5 className="h5 mb-4">Edit Review</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-12">
                <Field
                  label="Rating"
                  name='rating'
                  optionLabel='name'
                  optionValue='id'
                  options={[{id: 1, name: 1},{id: 2, name: 2}, {id: 3, name: 3}, {id: 4, name: 4}, {id: 5, name: 5}]}
                  component={renderReactSelect}
                  placeholder=""
                  multi={false}
                />
              </div>
              <div className=" col-lg-12">
                <Field
                  name="review"
                  component={renderTextarea}
                  label="Review"
                />
              </div>




            </div>

            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReview: (data) => {
      return dispatch(fetchReview(data));
    },
    updateReview: (data) => {
      return dispatch(updateReview(data));
    }
  }
}

EditReview = connect(null, mapDispatchToProps)(EditReview);

EditReview = reduxForm({
  form: 'EditReview',
  validate,
  //enableReinitialize: true
})(EditReview);

export default EditReview;
