import React, { Component, Fragment } from 'react'
    import CKEditor from '@ckeditor/ckeditor5-react'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
    import { API_ROOT } from '../../constants'
    //import { getToken } from "../services/auth"
    
    var config = {
        extraPlugins: [],
        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable',
                  'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo'],
        
        heading: {
          options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
              { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        }
        /*toolbarGroups: [
            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
            { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
            { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
            { name: 'forms', groups: [ 'forms' ] },
            '/',
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
            { name: 'links', groups: [ 'links' ] },
            { name: 'insert', groups: [ 'insert' ] },
            '/',
            { name: 'styles', groups: [ 'styles' ] },
            { name: 'colors', groups: [ 'colors' ] },
            { name: 'tools', groups: [ 'tools' ] },
            { name: 'others', groups: [ 'others' ] },
            { name: 'about', groups: [ 'about' ] }
        ]*/
      
      
      }
      const renderCKEditor = ({ input, id, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, imageUpload,  meta: { touched, error, invalid, warning } }) => {
      if(imageUpload){
        config.toolbar.push('imageUpload');
        config.extraPlugins.push(MyCustomUploadAdapterPlugin);
      }
      return (
           <div className={parentDivClass ? parentDivClass : 'form-group'} >
             {type != "hidden" && (<label>{label}</label>)}
             <CKEditor
                {...input}  content={input.value} activeClass={ ` ${fieldClass ? fieldClass:' ckeditor-container  '} ${touched && error ? '  is-invalid ':''}` }
                
                config={config}
                editor={ ClassicEditor }
                data={input.value}
                onInit={ editor => {
                    // You can store the "editor" and use when it is needed.
                    //console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    //const data = editor.getData();
                    input.onChange(editor.getData());
                } }
                onBlur={ editor => {
                  //input.onBlur(editor.getData());
                  //console.log( 'Blur.', editor.getData() );
                } }
                onFocus={ editor => {
                    //console.log( 'Focus.', editor );
                } }
            />
            {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
            {
             notes && (<div className="help-notes"><small>{notes}</small></div>)
            }
           </div>
      
        )
      }

      export default renderCKEditor;
      
    

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
      }
    }

    class MyUploadAdapter {
        constructor(props) {
            // CKEditor 5's FileLoader instance.
          this.loader = props;
          // URL where to send files.
          this.url = `${API_ROOT}/common/uploadFile`;
        }

        // Starts the upload process.
        upload() {
            return new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject);
                this._sendRequest();
            } );
        }

        // Aborts the upload process.
        abort() {
            if ( this.xhr ) {
                this.xhr.abort();
            }
        }

        // Example implementation using XMLHttpRequest.
        _initRequest() {
            const xhr = this.xhr = new XMLHttpRequest();

            xhr.open('POST', this.url, true);
            xhr.responseType = 'json';
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
            //xhr.setRequestHeader('Authorization', getToken())
        }

        // Initializes XMLHttpRequest listeners.
        _initListeners( resolve, reject ) {
            const xhr = this.xhr;
            const loader = this.loader;
            const genericErrorText = 'Couldn\'t upload file:' + ` ${ loader.file.name }.`;

            xhr.addEventListener( 'error', () => reject( genericErrorText ) );
            xhr.addEventListener( 'abort', () => reject() );
            xhr.addEventListener( 'load', () => {
                const response = xhr.response;
                if ( !response || response.error ) {
                    return reject( response && response.error ? response.error.message : genericErrorText );
                }

                // If the upload is successful, resolve the upload promise with an object containing
                // at least the "default" URL, pointing to the image on the server.
                
                resolve({
                    default: response.data.original
                });
            } );

            if ( xhr.upload ) {
                xhr.upload.addEventListener( 'progress', evt => {
                    if ( evt.lengthComputable ) {
                        loader.uploadTotal = evt.total;
                        loader.uploaded = evt.loaded;
                    }
                } );
            }
        }

        // Prepares the data and sends the request.
        _sendRequest() {
            const data = new FormData();

            this.loader.file.then(result => {
              data.append('file', result);
              this.xhr.send(data);
              }
            )
        }

    }

    