import React, {Component} from 'react';
import {Redirect, Link, Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {currencyFormat, BOOKINGS_STATUSES, CURRENCY} from '../../../constants';
import Confirm from 'react-confirm-bootstrap';
import OfflinePaymentPopup from './OfflinePaymentPopup';

import {
  Field,
  reduxForm,
  change,
  SubmissionError,
  FormSection,
  FieldArray
} from 'redux-form';
import queryString from 'query-string';
import {fetchBookingById, fetchBookingSubscriptionDetails, fetchBookingSubscriptionSchedule} from '../../../actions/bookings';
import {toast} from 'react-toastify';
import moment from 'moment';

class BookingDetail extends Component {
  constructor(props) {
    super(props);
    var params = queryString.parse(props.location.search);
    this.state = {
      dataLoaded: false,
      openOfflinePaymentPopup: false,
      bookingId: props.match.params.bookingId
        ? props.match.params.bookingId
        : null,
      bookingData: null,
      invoice_id: null
    }
    this.openOfflinePaymentPopup = this.openOfflinePaymentPopup.bind(this);
    this.closeOfflinePaymentPopup = this.closeOfflinePaymentPopup.bind(this);
    this.reloadBookingSubscriptionSchedule = this.reloadBookingSubscriptionSchedule.bind(this);
  }

  openOfflinePaymentPopup(invoice_id){
    this.setState({openOfflinePaymentPopup: true, invoice_id});
  }

  closeOfflinePaymentPopup(){
    this.setState({openOfflinePaymentPopup: false, bookingData: null});
  }





  componentDidMount() {
    this.fetchBookingById(this.state.bookingId);
    this.fetchBookingSubscriptionSchedule(this.state.bookingId);

  }

  reloadBookingSubscriptionSchedule(){
    this.fetchBookingSubscriptionSchedule(this.state.bookingId);
  }

  fetchBookingById(bookingId) {
    this.setState({fetchingRecords: true});
    this.props.fetchBookingById({orderId: bookingId}).then((response) => {
      let bookingData = response.value.data.data;
      this.setState({fetchingRecords: false, bookingData});
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  fetchBookingSubscriptionSchedule(bookingId) {
    this.props.fetchBookingSubscriptionSchedule({orderId: bookingId}).then((response) => {
      let subscriptionSchedule = response.value.data.data;
      if(subscriptionSchedule && subscriptionSchedule.object && subscriptionSchedule.object.id){
        this.fetchBookingSubscriptionDetails(subscriptionSchedule.object.id);
      }
      this.setState({subscriptionSchedule});

    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  fetchBookingSubscriptionDetails(bookingId) {
    this.props.fetchBookingSubscriptionDetails({scheduleId: bookingId}).then((response) => {
      let subscriptionDetails = response.value.data.data;
      this.setState({subscriptionDetails});
    }).catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.match.params.bookingId != nextProps.match.params.bookingId){
      this.fetchBookingById(nextProps.match.params.bookingId);
      this.setState({bookingId: nextProps.match.params.bookingId});
    }
  }

  getStatusOption(status){
		for(var i = 0; i < BOOKINGS_STATUSES.length; i++){
			if(status == BOOKINGS_STATUSES[i].id){
				return BOOKINGS_STATUSES[i].name;
			}
		}
	}





  render() {
    const {handleSubmit, pristine, reset, submitting, change} = this.props;
    const {bookingData} = {...this.state};

    return (
      <>
        <div className = "back-ui" > <a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1">
          <li className="breadcrumb-item">
            <Link to="/admin/product-management/products/list">Bookings</Link>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ol>
        <h5 className="h5 mb-4">Booking Detail</h5>
        <div className = "job-info order-info">
          <h6 className="h6">Booking ID: {bookingData && bookingData.orderId} {bookingData && <>({this.getStatusOption(bookingData.order.status)})</>}</h6>
          <div className="p-4">
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Name</strong></label>
                  <span className="data-span">{bookingData && bookingData.customer.fullName}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group"><label><strong>Email</strong></label><span className="data-span">{bookingData && bookingData.customer.email}</span></div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group"><label><strong>Phone</strong></label><span className="data-span">{bookingData && <>{"+"+bookingData.customer.countryCode}</>} {bookingData && bookingData.customer.phoneNumber}</span></div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Gender</strong></label>
                  <span className="data-span">{bookingData && <>{bookingData.customer.gender == 1?"Male":"Femail"}</>}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Date of birth</strong></label>
                  <span className="data-span">{bookingData && <>{moment(bookingData.customer.dateOfBirth).format("DD MMM, Y")}</>}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Plan</strong></label>
                  <span className="data-span">{bookingData && <>{bookingData.planDetails && bookingData.planDetails.title}</>}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>GOVT. ID (Front)</strong></label>
                  <span className="data-span">
                  {
                    bookingData && bookingData.customer.govermentIdProofImages && bookingData.customer.govermentIdProofImages.front && bookingData.customer.govermentIdProofImages.front.id
                    ?
                    <a href = {bookingData.customer.govermentIdProofImages.front.original} target = "_blank"><img src = {bookingData.customer.govermentIdProofImages.front.thumbnail} style = {{width: "100px"}} /></a>
                    :
                    <></>
                  }
                  </span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>GOVT. ID (Back)</strong></label>
                  <span className="data-span">
                  {
                    bookingData && bookingData.customer.govermentIdProofImages && bookingData.customer.govermentIdProofImages.back && bookingData.customer.govermentIdProofImages.back.id
                    ?
                    <a href = {bookingData.customer.govermentIdProofImages.back.original} target = "_blank"><img src = {bookingData.customer.govermentIdProofImages.back.thumbnail} style = {{width: "100px"}} /></a>
                    :
                    <></>
                  }
                  </span>
                </div>
              </div>


            </div>


            {
              bookingData && bookingData.buddyDetails &&
              <>
                <h5 className="h5 mb-4">Buddy Details</h5>
                <div className="row">
                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>Name</strong></label>
                      <span className="data-span">{bookingData.buddyDetails.fullName}</span>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="form-group"><label><strong>Email</strong></label><span className="data-span">{bookingData.buddyDetails.email}</span></div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="form-group"><label><strong>Phone</strong></label><span className="data-span">{<>{"+"+bookingData.buddyDetails.countryCode}</>} {bookingData.buddyDetails.phoneNumber}</span></div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>Gender</strong></label>
                      <span className="data-span">{<>{bookingData.buddyDetails.gender == 1?"Male":"Female"}</>}</span>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>Gender</strong></label>
                      <span className="data-span">{<>{moment(bookingData.buddyDetails.dateOfBirth).format("DD MMM, Y")}</>}</span>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                  </div>

                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>GOVT. ID (Front)</strong></label>
                      <span className="data-span">
                      {
                        bookingData.buddyDetails.govermentIdProofImages && bookingData.buddyDetails.govermentIdProofImages.front && bookingData.buddyDetails.govermentIdProofImages.front.id
                        ?
                        <a href = {bookingData.buddyDetails.govermentIdProofImages.front.original} target = "_blank"><img src = {bookingData.buddyDetails.govermentIdProofImages.front.thumbnail} style = {{width: "100px"}} /></a>
                        :
                        <></>
                      }
                      </span>
                    </div>
                  </div>
                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>GOVT. ID (Back)</strong></label>
                      <span className="data-span">
                      {
                        bookingData.buddyDetails.govermentIdProofImages && bookingData.buddyDetails.govermentIdProofImages.back && bookingData.buddyDetails.govermentIdProofImages.back.id
                        ?
                        <a href = {bookingData.buddyDetails.govermentIdProofImages.back.original} target = "_blank"><img src = {bookingData.buddyDetails.govermentIdProofImages.back.thumbnail} style = {{width: "100px"}} /></a>
                        :
                        <></>
                      }
                      </span>
                    </div>
                  </div>


                </div>
              </>
            }



            <div className="row">
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>City</strong></label>
                  <span className="data-span">{bookingData && bookingData.order.city.name}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Location</strong></label>
                  <span className="data-span">{bookingData && bookingData.order.locality.name}</span>
                </div>
              </div>
              {
                bookingData && bookingData.order.building && bookingData.order.building.name &&
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Building</strong></label>
                    <span className="data-span">{bookingData.order.building.name}</span>
                  </div>
                </div>
              }

              {
                bookingData && bookingData.order.buildingBlock && bookingData.order.buildingBlock.name &&
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Block</strong></label>
                    <span className="data-span">{bookingData.order.buildingBlock.name}</span>
                  </div>
                </div>
              }
              {
                bookingData && bookingData.order.flat && bookingData.order.flat.number &&
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Flat #</strong></label>
                    <span className="data-span">{bookingData.order.flat.number}</span>
                  </div>
                </div>
              }
              {
                bookingData && bookingData.order.roomId &&
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Room ID</strong></label>
                    <span className="data-span">{bookingData.order.roomId}</span>
                  </div>
                </div>
              }
              {
                bookingData && bookingData.order.beds &&
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Bed ID(s)</strong></label>
                    <span className="data-span">{bookingData.order.beds.length > 0 && bookingData.order.beds.toString(", ")}</span>
                  </div>
                </div>
              }

              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Duration</strong></label>
                  <span className="data-span">{bookingData && bookingData.order.duration?bookingData.order.duration+" month(s)":''}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Check In</strong></label>
                  <span className="data-span">{bookingData && moment(bookingData.order.checkinDate).format("DD MMM, Y")}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Checkout</strong></label>
                  <span className="data-span">{bookingData && moment(bookingData.order.checkoutDate).format("DD MMM, Y")}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Attach. Bathroom Required</strong></label>
                  <span className="data-span">{bookingData && bookingData.order.isAttachedBathroomRequired?"Yes":"No"}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Attach. Balcony Required</strong></label>
                  <span className="data-span">{bookingData && bookingData.order.isAttachedBalconyRequired?"Yes":"No"}</span>
                </div>
              </div>
              <div className=" col-lg-3">
                <div className="form-group">
                  <label><strong>Total Amount</strong></label>
                  <span className="data-span">{bookingData && CURRENCY+" "+currencyFormat(parseFloat(bookingData.order.totalPrice).toFixed(2))}</span>
                </div>
              </div>

            </div>

            {
              bookingData && bookingData.paymentInfo &&
              <>
                <h5 className="h5 mb-4">Payment Info</h5>
                <div className="row">
                  <div className=" col-lg-3">
                    <div className="form-group">
                      <label><strong>Charge ID</strong></label>
                      <span className="data-span">{bookingData && bookingData.paymentInfo.data && bookingData.paymentInfo.data.object && bookingData.paymentInfo.data.object.id}</span>
                    </div>
                  </div>
                </div>
              </>
            }

            {
              bookingData && bookingData.order &&  bookingData.order.security &&
              <>
                <h5 className="h5 mb-4">Security Refund</h5>
                <div className="row">
                  <div className=" col-lg-3">
                    <div className="form-group">
                      <label><strong>Is security refunded</strong></label>
                      {
                        <span className="data-span">{bookingData.order.security.isRefunded?"Yes":"No"}</span>
                      }

                    </div>
                  </div>
                  {
                    bookingData.order.security.isRefunded
                    ?
                    <>
                      <div className=" col-lg-3">
                        <div className="form-group">
                          <label><strong>Refunded Amount</strong></label>
                          {
                            <span className="data-span">{CURRENCY+" "+currencyFormat(parseFloat(bookingData.order.security.refundAmount).toFixed(2))}</span>
                          }

                        </div>
                      </div>
                      <div className=" col-lg-6">
                        <div className="form-group">
                          <label><strong>Reason</strong></label>
                          {
                            <span className="data-span">{bookingData.order.security.refundReason}</span>
                          }

                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                </div>
              </>
            }



          </div>
        </div>
        {
          this.state.subscriptionSchedule &&
          <div className = "job-info order-info">
            <h6 className="h6">Subscription Schedule</h6>
            <div className="p-4">
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Schedule ID</strong></label>
                    <span className="data-span">{this.state.subscriptionSchedule && this.state.subscriptionSchedule.object && this.state.subscriptionSchedule.object.id}</span>
                  </div>
                </div>

                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Phases</strong></label>

                    {
                      this.state.subscriptionSchedule && this.state.subscriptionSchedule.object && this.state.subscriptionSchedule.object.phases && this.state.subscriptionSchedule.object.phases.length > 0 &&
                      <ul style = {{paddingLeft: 0, listStyle: "none"}}>
                        {
                          this.state.subscriptionSchedule.object.phases.map((obj, index) => {
                            return(
                              <li key = {index}>{moment.unix(obj.start_date).format("DD MMM, YYYY")} - {moment.unix(obj.end_date).format("DD MMM, YYYY")}</li>
                            )
                          })
                        }
                      </ul>
                    }
                  </div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Status</strong></label>
                    <span className="data-span">{this.state.subscriptionSchedule && this.state.subscriptionSchedule.object && this.state.subscriptionSchedule.object.status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.state.subscriptionDetails &&
          <div className = "job-info order-info">
            <h6 className="h6">Subscription</h6>
            <div className="p-4">
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Subscription ID</strong></label>
                    <span className="data-span">{this.state.subscriptionDetails && this.state.subscriptionDetails.object && this.state.subscriptionDetails.object.id}</span>
                  </div>
                </div>


                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Current Period Start</strong></label>
                    <span className="data-span">{ this.state.subscriptionDetails && this.state.subscriptionDetails.object && moment.unix(this.state.subscriptionDetails.object.current_period_start).format("DD MMM, YYYY")}</span>
                  </div>
                </div>
                <div className=" col-lg-3">
                  <div className="form-group">
                    <label><strong>Current Period End</strong></label>
                    <span className="data-span">{this.state.subscriptionDetails && this.state.subscriptionDetails.object && moment.unix(this.state.subscriptionDetails.object.current_period_end).format("DD MMM, YYYY")}</span>
                  </div>
                </div>

                <div className=" col-lg-2">
                  <div className="form-group">
                    <label><strong>Status</strong></label>
                    <span className="data-span">{this.state.subscriptionDetails && this.state.subscriptionDetails.object && this.state.subscriptionDetails.object.status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.state.subscriptionDetails &&
          <div className = "job-info order-info">
            <h6 className="h6">Invoices</h6>
            <div className="p-4">
              <div className="row">
                <div className=" col-lg-12">
                {
                  this.state.subscriptionDetails.invoices && this.state.subscriptionDetails.invoices.length > 0 &&
                  <table className="table mb-0" >
                    <thead>
      								<tr>
                        <th>Invoice ID</th>
                        <th>Amount Due</th>
                        <th>Amount Paid</th>
                        <th>Amount Remaining</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.subscriptionDetails.invoices.map((obj, index) => {
                          return(
                            <tr>
                              <td><a href = {obj.object.hosted_invoice_url} target = "_blank">{obj.object.id}</a></td>
                              <td style = {{textTransform: "uppercase"}}>{obj.object.currency} {currencyFormat(parseFloat(obj.object.amount_due).toFixed(2))}</td>
                              <td style = {{textTransform: "uppercase"}}>{obj.object.currency} {currencyFormat(parseFloat(obj.object.amount_paid).toFixed(2))}</td>
                              <td style = {{textTransform: "uppercase"}}>{obj.object.currency} {currencyFormat(parseFloat(obj.object.amount_remaining).toFixed(2))}</td>
                              <td>{moment.unix(obj.object.period_start).format("DD MMM, YYYY")}</td>
                              <td>{moment.unix(obj.object.period_end).format("DD MMM, YYYY")}</td>
                              <td>{obj.object.status} {obj.object.status == 'open' || obj.object.status == 'uncollectible'? <button className="btn-add ml-1  hvr-shadow" type = "button" onClick = {() => this.openOfflinePaymentPopup(obj.object.id)}>Mark Payment</button>:''}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                }
                </div>

              </div>
            </div>
          </div>
        }

        {
  				this.state.openOfflinePaymentPopup &&
  				<OfflinePaymentPopup
  					invoice_id={this.state.invoice_id}
  					openPopup={this.state.openOfflinePaymentPopup}
  					closePopup = {this.closeOfflinePaymentPopup}
  					callback = {this.reloadBookingSubscriptionSchedule}
  				/>
  			}


      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBookingById: (data) => {
      return dispatch(fetchBookingById(data));
    },
    fetchBookingSubscriptionSchedule: (data) => {
      return dispatch(fetchBookingSubscriptionSchedule(data));
    },
    fetchBookingSubscriptionDetails: (data) => {
      return dispatch(fetchBookingSubscriptionDetails(data));
    }

  }
}

BookingDetail = connect(null, mapDispatchToProps)(BookingDetail);

export default withRouter(BookingDetail);
