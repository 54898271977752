import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_NEWS_BY_ID = 'FETCH_NEWS_BY_ID';
export const ADD_NEWS = 'ADD_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';

export function fetchNews(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getNews`
  });

  return {
    type: FETCH_NEWS,
    payload: request
  };
}
export function fetchNewsById(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getNewsById`
  });

  return {
    type: FETCH_NEWS_BY_ID,
    payload: request
  };
}

export function addNews(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addNews`
  });
  return {
    type: ADD_NEWS,
    payload: request
  };
}

export function deleteNews(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteNews`
  });
  return {
    type: DELETE_NEWS,
    payload: request
  };
}
