import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderCKEditor from '../../FormFields/renderCKEditor';
//import renderCKEditorFileUpload from '../../FormFields/renderCKEditorFileUpload';
import {fetchPageDetail, updatePage} from '../../../actions/pages';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeAmountField, normalizeNumber, stripHtmlTags} from '../../../constants';

import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if(!values.title || values.title.trim() === '') {
    errors.title = "Enter title";
    hasErrors = true;
  }

  if(!values.content || values.content.trim() === '') {
    errors.content = "Enter content";
    hasErrors = true;
  }else if(stripHtmlTags(values.content) == ''){
    errors.content = "Enter content";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class ManagePage extends Component {
	constructor(props){
    super(props);
    this.state = {
      slug: props.match.params.slug
    }
  }

  submitForm(values, dispatch, props){
    return this.props.updatePage(values)
    .then((response) => {
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){

    this.fetchPageDetail(this.state.slug);
  }

  fetchPageDetail(slug){
		this.setState({fetchingRecords: true});
    this.props.fetchPageDetail({slug: slug}).then((response) => {
      let pageDetail = response.value.data.data;
      this.props.initialize({title: pageDetail.title, content: pageDetail.content, id: pageDetail.id});
      this.setState({fetchingRecords: false, pageDetail: pageDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
    if(this.props.match.params.slug != nextProps.match.params.slug){
      this.fetchPageDetail(nextProps.match.params.slug);
      this.setState({slug: nextProps.match.params.slug})
    }
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/settings">Settings</Link></li>
        <li className="breadcrumb-item active">Manage Page</li>
      </ol>
        <h5 className="h5 mb-4">Manage Page</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
          {
            this.state.fetchingRecords == false && this.state.pageDetail &&
            <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
              <div className="row">
                <div className=" col-lg-6">
                  <Field
                    name="title"
                    component={renderField}
                    type="text"
                    label="Title"
                  />
                </div>
              </div>
              <div className="row">
              <div className=" col-lg-12">
                  <Field
                    imageUpload={true}
                    name="content"
                    component={renderCKEditor}
                    label="Content"
                  />
                </div>
              </div>
              <div className="form-btn-group">
                <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
              </div>
            </form>
          }
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPageDetail: (data) => {
      return dispatch(fetchPageDetail(data));
    },
    updatePage: (data) => {
      return dispatch(updatePage(data));
    }
  }
}

ManagePage = connect(null, mapDispatchToProps)(ManagePage);

ManagePage = reduxForm({
  form: 'ManagePage',
  validate,
  //enableReinitialize: true,
})(ManagePage);

export default ManagePage;
