import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {addUser, fetchRoles, fetchUser} from '../../../actions/users';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_DIAL_CODE, COUNTRIES, validateEmail} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter name";
    hasErrors = true;
  }
  if (!values.contactNumber || values.contactNumber.trim() === '') {
    errors.contactNumber = "Enter contact number";
    hasErrors = true;
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = "Enter email";
    hasErrors = true;
  }else if(!validateEmail(values.email)){
    errors.email = "Enter a valid email";
    hasErrors = true;
  }



  if(values.resetPassword || values.resetPassword == true){
    if(!values.password || values.password.trim() === ''){
      errors.password = "Enter password";
      hasErrors = true;
    }else if(values.password && values.password.length < 6){
      errors.password = "Password must be at-least 6 characters long";
      hasErrors = true;
    }
  }

  if(!values.id){
    if(values.password && values.password.length < 6){
      errors.password = "Password must be at-least 6 characters long";
      hasErrors = true;
    }
  }


  if (!values.role) {
    errors.role = "Select role";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class UsersCreate extends Component {
	constructor(props){
    super(props);


    this.state = {
      user_id: props.match.params.user_id,
      dataLoaded: false,
      roles: [],
      countryCode: DEFAULT_DIAL_CODE,
      userDetail: null,
      resetPassword: false
    }
  }

  submitForm(values, dispatch, props){
    if(this.state.user_id){
      values = Object.assign(values, {resetPassword: this.state.resetPassword});
    }
    return this.props.addUser(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back()
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.props.change('countryCode', this.state.countryCode);
    this.fetchRoles();
    if(this.state.user_id){
      this.fetchUser(this.state.user_id);
    }
  }

  fetchRoles(){
		this.setState({fetchingRecords: true});
    this.props.fetchRoles().then((response) => {
      let roles = response.value.data.data;
      this.setState({fetchingRecords: false, roles: roles});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchUser(user_id){
		this.props.fetchUser({staffId: user_id}).then((response) => {
      let userDetail = response.value.data.data;
      if(userDetail){

        let data = {
          contactNumber: userDetail.contactNumber,
          name: userDetail.name,
          email: userDetail.email,
          address: userDetail.address,
          role: userDetail.role,
          id: userDetail.id,
          resetPassword: false
        };

        this.props.initialize(data);

      }
      this.setState({userDetail: userDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  updateResetPasswordFlag(status){
    //this.props.change('resetPassword', status);
    this.setState({resetPassword: status});
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/user-management/users/list">Users</Link></li>
        <li className="breadcrumb-item active">{this.state.user_id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.user_id?"Edit":"Add"} User</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
            <div className=" col-lg-6">
              {
                this.state.user_id &&
                <Field name = "id" component = "input" type = "hidden" />
              }
              <Field
                name="name"
                component={renderField}
                type="text"
                label="Name"
              />
            </div>
            <div className=" col-lg-6">
              <Field
                name="contactNumber"
                component={renderField}
                type="text"
                label="Contact Number"
              />
            </div>
            <div className=" col-lg-6">
              <Field
                name="email"
                component={renderField}
                label="Email"
              />
            </div>
            <div className=" col-lg-6">
              {
                this.state.user_id
                ?
                <div className="row">
                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label>Reset Password</label>
                      <label className="switch">
  											<Field type="checkbox" name = "resetPassword"  component = "input" checked = {this.state.resetPassword?true:false} onChange = {() => this.updateResetPasswordFlag(this.state.resetPassword?false:true,)} />
  										  <span className="slider round"></span>
  										</label>
                    </div>
                  </div>
                  {
                    this.state.resetPassword == true &&
                    <div className=" col-lg-8">
                      <Field
                        name="password"
                        component={renderField}
                        label="Password"

                      />
                    </div>
                  }
                </div>
                :
                <Field
                  name="password"
                  component={renderField}
                  label="Password"
                />
              }

            </div>
          </div>
          <div className = 'row'>
            <div className=" col-lg-6">
              <Field
                name="address"
                component={renderTextarea}
                type="text"
                label="Address"
              />
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-6">
              <Field
                label="Role"
                name='role'
                optionLabel='name'
                optionValue='roleId'
                options={this.state.roles}
                component={renderReactSelect}
                placeholder="Select a role"
                multi={true}
               />
             </div>
            </div>
            <div className="form-btn-group">
              <Link to="/admin/user-management/users/list?page=1" type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => {
      return dispatch(addUser(data));
    },
    fetchRoles: (data) => {
      return dispatch(fetchRoles(data));
    },
    fetchUser: (data) => {
      return dispatch(fetchUser(data));
    }
  }
}

UsersCreate = connect(null, mapDispatchToProps)(UsersCreate);

UsersCreate = reduxForm({
  form: 'UsersCreate',
  validate,
  //enableReinitialize: true
})(UsersCreate);

export default UsersCreate;
