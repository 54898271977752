import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, formValueSelector, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderCheckboxGroup from '../../FormFields/CheckboxGroup2';
import {fetchBuildingPlanAmenities, addBuildingPlanAmenities} from '../../../actions/space_management';
import {fetchPlans} from '../../../actions/plans';

import renderReactSelect from '../../FormFields/renderReactSelectForForm';


import { toast } from 'react-toastify';


class BuildingManageAmenities extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		let buildingName = queryParams.buildingName || "";

    this.state = {
			buildingName: buildingName,
			id: props.match.params.buildingId,
      dataLoaded: false,
			plans: []
    }
		this.renderPlans = this.renderPlans.bind(this);
  }

  submitForm(values, dispatch, props){
		this.setState({submitting: true});
		values = Object.assign(values, {buildingId: parseInt(this.state.id)});
		this.props.addBuildingPlanAmenities(values).then((response) => {
      this.setState({submitting: false});
			toast.success(response.value.data.message);
			window.history.back();
		}).catch((error) => {
			this.setState({submitting: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});

  }

  componentDidMount(){
		this.fetchBuildingPlanAmenities();
	}

	fetchBuildingPlanAmenities(params){
		this.props.fetchBuildingPlanAmenities({buildingId: this.state.id}).then((response) => {
      var plans = response.value.data.data && response.value.data.data.buildingPlanAmenities?response.value.data.data.buildingPlanAmenities:[];
			this.props.change('buildingPlanAmenities', plans);
			//this.setState({plans});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}





  componentWillReceiveProps(nextProps){
  }


	renderPlans({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;
    return (
      <>
				{
        	fields.map((member, index) =>{
          	var data = fields.get(index);
						console.log('ddddddddd', data.amenities);
          	return(
							<div className=" col-lg-4">
								<fieldset key = {index}>
		              <legend>{fields.get(index).name}</legend>
		              <Field name={`${member}.id`}  type = "hidden" component={"input"}  />
									<Field
										name={`${member}.selectedAmenities`}
										component={renderCheckboxGroup}
										type="checkbox"
										options={data.amenities}
										id_field = "id"
										label_field = 'name'
										col_class = "col-lg-12"
									/>
		            </fieldset>
							</div>
        	)}
				)
      }
    </>)
  }



	render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
			<div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1">
        <li className="breadcrumb-item"><Link to="/admin/space-management/buildings/list?pageNumber=1">Buildings</Link></li>
        <li className="breadcrumb-item active">Manage Amenities {this.state.buildingName?"("+this.state.buildingName+")":""}</li>
      </ol>
        <h5 className="h5 mb-4">Manage Amenities</h5>
        <section className="formBg">
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="row">
						<FieldArray component={this.renderPlans} name="buildingPlanAmenities"/>
					</div>
					<div className="form-btn-group">
            <button className="btn btn-secondary hvr-shadow" onClick = {(e) => {e.preventDefault(); window.history.back();}}>Cancel</button>
            <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
          </div>
        </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
		fetchPlans: (data) => {
			return dispatch(fetchPlans(data));
		},
		fetchBuildingPlanAmenities: (data) => {
			return dispatch(fetchBuildingPlanAmenities(data));
		},
		addBuildingPlanAmenities: (data) => {
			return dispatch(addBuildingPlanAmenities(data));
		}

  }
}
BuildingManageAmenities = reduxForm({
  form: 'BuildingManageAmenities',
  //validate,
  //enableReinitialize: true
})(BuildingManageAmenities);

BuildingManageAmenities = connect(null, mapDispatchToProps)(BuildingManageAmenities);



export default withRouter(BuildingManageAmenities);
