import React, {Component} from 'react';
import {Route, Link, withRouter} from 'react-router-dom';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderTextarea from '../../FormFields/renderTextarea';
import renderField from '../../FormFields/renderField';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import {refundSecurity} from '../../../actions/bookings';
import {toast} from 'react-toastify';
import {normalizeAmountField} from '../../../constants';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.refundAmount || values.refundAmount === '') {
    errors.refundAmount = "Enter amount";
    hasErrors = true;
  }
  return hasErrors && errors;
}



class RefundPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingData: props.bookingData,
      modal: props.openPopup,
    };
    this.toggle = this.toggle.bind(this);
  }


  toggle(){
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }

  componentDidMount(){
    if(this.state.bookingData && this.state.bookingData.order && this.state.bookingData.order.security && this.state.bookingData.order.security.amount){
      this.props.change('refundAmount', this.state.bookingData.order.security.amount);
    }
  }


  submitForm(values, dispatch, props){
    values = Object.assign(values, {orderId: this.state.bookingData.orderId});
    return this.props.refundSecurity(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      this.props.closePopup();
      if(this.props.callback){
        this.props.callback();
      }

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  render() {
    const {bookingData} = {...this.state};
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="sof-ui-block coupon-preview-popup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Refund Security (Booking ID: {this.state.bookingData.orderId})</ModalHeader>
        <div className="modal-body">
          <div className="scope-heading">
          </div>
          <div className="card-content-ui">
            <section className="" style={{maxWidth:"672px"}}>
              <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                <Field
                  name="refundAmount"
                  component={renderField}
                  type="text"
                  label="Refund Amount"
                  normalize={normalizeAmountField}
                />
                <Field
                  name="refundReason"
                  component={renderTextarea}
                  label="Reason"
                />

                <div className="form-btn-group">
                  <button onClick = {this.toggle} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
                  <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Submit</button>&nbsp;
                </div>
              </form>
            </section>
          </div>
        </div>

      </Modal>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {

  };
}


const mapDispatchToProps = (dispatch) => {
  return {
    refundSecurity: (data) => {
      return dispatch(refundSecurity(data));
    }
  }
}

RefundPopup = connect(null, mapDispatchToProps)(RefundPopup);

RefundPopup = reduxForm({
  form: 'RefundPopup',
  validate,
  //enableReinitialize: true
})(RefundPopup);




export default withRouter(connect(null, mapDispatchToProps)(RefundPopup));
