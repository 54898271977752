import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchCities, deleteCity} from '../../../actions/space_management';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Confirm from 'react-confirm-bootstrap';
import $ from 'jquery';

class CitiesListing extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/space-management/cities/list',
			records: null,
			roles: [],
			filters: [
				'name',
				'email',
				'mobile',
				'role'
			]
		};
  }

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		//this.props.initialize(this.state.params);
		this.fetchCities(this.state.params);
		//this.updateFilterForm(this.state.params);
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});
		this.props.initialize(formValues);
	}



	fetchCities(params){
		params = Object.assign(params, {isAll: 0});
		this.setState({fetchingRecords: true});
    this.props.fetchCities(params).then((response) => {
      this.setState({fetchingRecords: false});
      var records = response.value.data.data;
			console.log('rrrrr', records);
      this.setState({records});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCities(params);
		}
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){

		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}



	deleteRecord(recordid){
    this.props.deleteCity({id: recordid}).then((response) => {
			toast.success(response.value.data.message);
      this.fetchCities(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
			<>

        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/space-management/cities/list?pageNumber=1">Cities</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Cities</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              {/* <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a> */}
							<Link to="/admin/space-management/cities/list/add" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add City</Link>
            </div>
          </div>
        </div>
				{/* <div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="email"
										component={renderField}
										label="Email Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="mobile"
										component={renderField}
										label="Mobile"
									/>
								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Role"
		                name='role'
		                optionLabel='name'
		                optionValue='roleId'
		                options={this.state.roles}
		                component={renderReactSelect}
		                placeholder="Select a role"
		                multi={false}
		               />
		          	</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div> */}
				<div class="custom-table mt-0">
					<div class="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table class="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>City</th>
									<th>Country</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.cities && records.cities.length > 0 &&
							records.cities.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.name}</td>
										<td>{obj.country.name}</td>

										<td>
											<span class="dropdown threeDots">
                        <a href="javascript://" class="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/space-management/cities/list/edit/"+obj.id} >Edit</Link></li>
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/space-management/cities/list/manage-plans/city/"+obj.id+"?name="+obj.name} >Manage Plans</Link></li>
													<li><Confirm className="confirmPopup"
															onConfirm={() => this.deleteRecord(obj.id)}
															body="Are you sure you want to delete this?"
															confirmText="Confirm Delete"
															title="Delete!">
															<button className="dropdown-item">Delete</button>
													</Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.cities && records.cities.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

CitiesListing = reduxForm({
  form: 'CitiesListingFilter'
})(CitiesListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCities: (data) => {
      return dispatch(fetchCities(data));
    },
    deleteCity: (data) => {
      return dispatch(deleteCity(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(CitiesListing));
