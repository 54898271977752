import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchCustomers, updateCustomerStatus} from '../../../actions/users';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat, USER_STATUS} from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';

import $ from 'jquery';

class CustomersList extends Component {
	constructor(props){
    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}
		if(!queryParams.sortBy){
			queryParams.sortBy = 'id';
		}
		if(!queryParams.sortOrder){
			queryParams.sortOrder = 'desc';
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/customers/list',
			records: null,



			filters: [
				'id',
        'fullName',
        'phoneNumber'
			]
		};
  }



	componentDidMount(){
    this.fetchCustomers(this.state.params);
  }

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchCustomers(params){
		this.setState({fetchingRecords: true});

		this.props.fetchCustomers(params).then((response) => {
			this.setState({records: response.value.data.data, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCustomers(params);
		}
	}



	submitFilterForm(values, dispatch, props){
		var params = values;
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	sortResults(e, sortBy, sortOrder){
		if(e){
			e.preventDefault();
		}
		let params = this.state.params;
		params = Object.assign(params, {sortBy: sortBy, sortOrder: sortOrder});
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}

  updateCustomerStatus(id, status){
    this.setState({fetchingRecords: true});
    this.props.updateCustomerStatus({id, status}).then((response) => {
      this.setState({fetchingRecords: false});
      this.fetchCustomers(this.state.params);
			toast.success(response.value.data.message);
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records, params} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/orders/list?pageNumber=1">Customers</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Customers</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="id"
										component={renderField}
										label="ID"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="fullName"
										component={renderField}
										label="Name"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="phoneNumber"
										component={renderField}
										label="Phone Number"
									/>
                </div>

              </div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th><a href = "javascript:;" className = {params.sortBy == 'id'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'id', params.sortOrder == 'desc'?'asc':'desc')}>ID</a></th>
                  <th><a href = "javascript:;" className = {params.sortBy == 'fullName'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'fullName', params.sortOrder == 'desc'?'asc':'desc')}>Name</a></th>
                  <th>Phone</th>
                  <th><a href = "javascript:;" className = {params.sortBy == 'email'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'email', params.sortOrder == 'desc'?'asc':'desc')}>Email</a></th>

									<th>Gender</th>
									<th>DOB</th>
									<th><a href = "javascript:;" className = {params.sortBy == 'totalOrders'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'totalOrders', params.sortOrder == 'desc'?'asc':'desc')}>Bookings</a></th>
                  <th>Status</th>
                  <th>Registered on</th>
									{/* <th>Actions</th> */}
								</tr>
							</thead>
						<tbody>
						{
							records && records.user && records.user.length > 0 &&
							records.user.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td className="nowrap">{obj.id}</td>
                    <td>{obj.fullName}</td>
                    <td className="nowrap">+{obj.countryCode} {obj.phoneNumber}</td>
                    <td className="nowrap">{obj.email}</td>
										<td className="nowrap">{obj.gender == 1?"Male":"Female"}</td>
										<td className="nowrap">{moment(obj.dateOfBirth).format("DD MMM, Y")}</td>
										<td>{obj.totalOrders}</td>
                    <td>
											<label className="switch">
												<input type="checkbox" checked = {obj.status == 1?true:false} onChange = {() => this.updateCustomerStatus(obj.id, obj.status == 1?2:1, index)} />
											  <span className="slider round"></span>
											</label>
										</td>
                    <td className="nowrap">{moment(obj.registeredAt).format('DD MMM, Y')}</td>
                    {/* <td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
                          <li><Link className="dropdown-item" title="View orders" to = {"/admin/orders/list?userId="+obj.id+"&uName="+obj.name}>Orders</Link></li>
                        </ul>
                      </span>
										</td> */}
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.user && this.state.records.user.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}

CustomersList = reduxForm({
  form: 'CustomersListFilter'
})(CustomersList);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (data) => {
      return dispatch(fetchCustomers(data));
    },
    updateCustomerStatus: (data) => {
			return dispatch(updateCustomerStatus(data));
		},
  }
}


export default withRouter(connect(null, mapDispatchToProps)(CustomersList));
