import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addCity, fetchCountries, fetchCity} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_COUNTRY_ID} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter city name";
    hasErrors = true;
  }
  if (!values.countryId) {
    errors.countryId = "Select country";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class AddCity extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.cityId,
      defaultCountryId: DEFAULT_COUNTRY_ID,
      cityDetail: null,
      countries: []
    }
  }

  submitForm(values, dispatch, props){

    return this.props.addCity(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      if(this.state.id){
        window.history.back();
      }

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(!this.state.id){
      this.props.change('countryId', this.state.defaultCountryId);
    }
    this.fetchCountries();
    if(this.state.id){
      this.fetchCity(this.state.id);
    }
  }

  fetchCountries(){
		this.setState({fetchingRecords: true});
    this.props.fetchCountries().then((response) => {
      let countries = response.value.data.data;
      this.setState({fetchingRecords: false, countries: countries});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchCity(cityId){
		this.props.fetchCity({id: cityId}).then((response) => {
      let cityDetail = response.value.data.data;
      this.props.initialize({id: cityDetail.id, name: cityDetail.name, countryId: cityDetail.country.id});
      this.setState({cityDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }



  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/space-management/cities/list">Cities</Link></li>
        <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} City</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-8">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="City"
                />
              </div>
              <div className=" col-lg-8">
                <Field
                  label="Country"
                  name='countryId'
                  optionLabel='country'
                  optionValue='id'
                  options={this.state.countries}
                  component={renderReactSelect}
                  placeholder="Select country"
                  multi={false}
                 />
               </div>
             </div>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCity: (data) => {
      return dispatch(addCity(data));
    },
    fetchCity: (data) => {
      return dispatch(fetchCity(data));
    },
    fetchCountries: (data) => {
      return dispatch(fetchCountries(data));
    }
  }
}

AddCity = connect(null, mapDispatchToProps)(AddCity);

AddCity = reduxForm({
  form: 'AddCity',
  validate,
  //enableReinitialize: true
})(AddCity);

export default AddCity;
