import axios from 'axios';
import { API_ROOT } from '../constants';

export const FETCH_BANNERS = "FETCH_BANNERS";
export const FETCH_BANNER = 'FETCH_BANNER';
export const ADD_BANNER = 'ADD_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';

export function fetchBanners(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBanners`
  });

  return {
    type: FETCH_BANNERS,
    payload: request
  };
}
export function fetchBanner(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getBannerById`
  });

  return {
    type: FETCH_BANNER,
    payload: request
  };
}

export function addBanner(data) {
  const request = axios({
    method: 'POST',
    data: data,
    url: `${API_ROOT}/serviceProvider/addBanner`
  });
  return {
    type: ADD_BANNER,
    payload: request
  };
}

export function deleteBanner(data){
  const request = axios({
    method: 'DELETE',
    data: data,
    url: `${API_ROOT}/serviceProvider/deleteBanner`
  });
  return {
    type: DELETE_BANNER,
    payload: request
  };
}
