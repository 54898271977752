import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_PAGE_DETAIL = 'FETCH_PAGE_DETAIL';
export const UPDATE_PAGE = 'UPDATE_PAGE';


export function fetchPageDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/serviceProvider/getStaticPageByIdOrSlug`
  });
  return {
    type: FETCH_PAGE_DETAIL,
    payload: request
  };
}

export function updatePage(formValues) {
  const request = axios({
    method: 'PUT',
    data: formValues,
    url: `${API_ROOT}/serviceProvider/editStaticPage`
  });
  return {
    type: UPDATE_PAGE,
    payload: request
  };
}
