import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchBeds, deleteBed} from '../../../actions/space_management';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import Confirm from 'react-confirm-bootstrap';
import moment from 'moment';
import $ from 'jquery';

class BedsListing extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/space-management/beds/list',
			records: null,
			roles: [],
			filters: [
				'buildingName',
				'localityName',
				'cityName',
				'cityName',
				'buildingBlockName',
				'buildingBlockFlatNumber',
				'flatId',
				'roomId',
				'bedId',
				'balcony',
				'bathroomAttached',
				'status'
			]
		};
  }

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		//this.props.initialize(this.state.params);
		this.fetchBeds(this.state.params);
		this.updateFilterForm(this.state.params);
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];

			}
		});
		this.props.initialize(formValues);
	}



	fetchBeds(params){
		params = Object.assign(params, {getAll: 0});
		this.setState({fetchingRecords: true});
    this.props.fetchBeds(params).then((response) => {
      var records = response.value.data.data;
      this.setState({records, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchBeds(params);
		}
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){

		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}



	deleteRecord(recordid){
    this.props.deleteBed({id: recordid}).then((response) => {
			toast.success(response.value.data.message);
      this.fetchBeds(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	renderBookingDates(data){
		return(
			<ul>
				{
					data.map((obj, index) => {
						return(
							<li key = {index}>{moment(obj.checkinDate).format("DD MMM, Y")} - {moment(obj.checkoutDate).format("DD MMM, Y")}</li>
						)
					})
				}
			</ul>
		)
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
			<>

        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/space-management/beds/list?pageNumber=1">Beds</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Beds</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
							{/* <Link to="/admin/space-management/beds/list/add" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Bed</Link> */}
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingName"
										component={renderField}
										label="Building"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="localityName"
										component={renderField}
										label="Locality"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="cityName"
										component={renderField}
										label="City"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingBlockName"
										component={renderField}
										label="Block"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="buildingBlockFlatNumber"
										component={renderField}
										label="Flat #"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="flatId"
										component={renderField}
										label="Flat ID"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="roomId"
										component={renderField}
										label="Room ID"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="bedId"
										component={renderField}
										label="Bed ID"
									/>
								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Balcony"
		                name='balcony'
		                optionLabel='name'
		                optionValue='id'
		                options={[{id: '0', name: 'No'}, {id: '1', name: 'Yes'}]}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Attached Bathroom"
		                name='bathroomAttached'
		                optionLabel='name'
		                optionValue='id'
		                options={[{id: '0', name: 'No'}, {id: '1', name: 'Yes'}]}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Status"
		                name='status'
		                optionLabel='name'
		                optionValue='id'
		                options={[{id: '0', name: 'Unassigned'}, {id: '1', name: 'Assigned'}]}
		                component={renderReactSelect}
		                placeholder="Select.."
		                multi={false}
		               />
		          	</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div class="custom-table mt-0">
					<div class="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table class="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Flat#</th>
									<th>Room ID</th>
									<th>Building</th>
									<th>Status</th>
									<th>Plan</th>
									<th>Booking Dates</th>
									<th>Locality</th>
									<th>City</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.beds && records.beds.length > 0 &&
							records.beds.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.flat.number}</td>
										<td>{obj.room.id}</td>
										<td>{obj.building.name}</td>
										<td>{obj.status == 1?"Assigned":"Unassigned"}</td>
										<td>{obj.plan.name}</td>
										<td className="nowrap">{obj.bedBookings && obj.bedBookings.length > 0 ?<>{this.renderBookingDates(obj.bedBookings)}</>:"N.A."}</td>
										<td>{obj.locality.name}</td>
										<td>{obj.city.name}</td>
										<td>
											<span class="dropdown threeDots">
                        <a href="javascript://" class="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Confirm className="confirmPopup"
															onConfirm={() => this.deleteRecord(obj.id)}
															body="Are you sure you want to delete this?"
															confirmText="Confirm Delete"
															title="Delete!">
															<button className="dropdown-item">Delete</button>
													</Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
      {
				records && records.beds && records.beds.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

BedsListing = reduxForm({
  form: 'BedsListingFilter'
})(BedsListing);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBeds: (data) => {
      return dispatch(fetchBeds(data));
    },
    deleteBed: (data) => {
      return dispatch(deleteBed(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BedsListing));
