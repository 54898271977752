import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addFlat, fetchBuildings, fetchBlocks, fetchFlat, fetchAvailableFloors, fetchFlatTypes} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter flat name";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class EditFlat extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.flatId,
      flatDetail: null,
      flatTypes: []
    }
  }

  submitForm(data, dispatch, props){

    var updatedData = {buildingBlockId: data.buildingBlockId,floors: [{floor: data.floor, flats: [{id: data.id, number: data.number, flatTypeId: data.flatTypeId, rooms: data.rooms}]}]};
    //console.log(updatedData);
    //return;
    return this.props.addFlat(updatedData).then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchFlatTypes();
    if(this.state.id){
      this.fetchFlat(this.state.id);
    }
  }

  fetchFlatTypes(){
		this.setState({fetchingRecords: true});
    let data = {isAll:1};
    this.props.fetchFlatTypes(data).then((response) => {
      let flatTypes = response.value.data.data.flatTypes;
      this.setState({fetchingRecords: false, flatTypes: flatTypes});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchFlat(flatId){
    this.props.fetchFlat({id: flatId}).then((response) => {
      let flatDetail = response.value.data.data;

      this.props.initialize({id: flatDetail.id, number: flatDetail.number, floor: flatDetail.floor, flatTypeId: flatDetail.flatType.id, rooms: flatDetail.rooms, buildingBlockId: flatDetail.buildingBlock.id});
      this.setState({flatDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }


  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/space-management/flats/list">Flats</Link></li>
          <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
        </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Flat</h5>
        <section className="formBg" style={{maxWidth:"672px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className='row'>
              <div className='col-md-4'>
                <Field name='number'  component={renderField} label="Flat no." />
              </div>
              <div className="col-md-4">
                <Field
                  label="Select flat type"
                  name="flatTypeId"
                  optionLabel='name'
                  optionValue='id'
                  options={this.state.flatTypes}
                  component={renderReactSelect}
                  placeholder="Flat type"
                  multi={false}
                />
              </div>
              <div className='col-md-4'>
                <Field name="rooms"   component={renderField} label="No. of rooms" />
              </div>
            </div>

            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFlat: (data) => {
      return dispatch(addFlat(data));
    },
    fetchFlat: (data) => {
      return dispatch(fetchFlat(data));
    },
    fetchFlatTypes: (data) => {
      return dispatch(fetchFlatTypes(data));
    }

  }
}

EditFlat = connect(null, mapDispatchToProps)(EditFlat);
EditFlat = reduxForm({
  form: 'EditFlat',
  validate,
  //enableReinitialize: true
})(EditFlat);


export default EditFlat;
