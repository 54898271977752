import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderDatePicker from '../../FormFields/renderDatePicker';
import { CSVLink, CSVDownload } from "react-csv";
import {bedsReport} from '../../../actions/reports';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat, API_ROOT} from '../../../constants';
import axios from 'axios';

import $ from 'jquery';


class BedsReport extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.pageNumber){
			queryParams.pageNumber = 1;
		}
		var startDate = new Date();
		var sDate = startDate.setDate(startDate.getDate()-30);

		this.state = {
			startDate: new Date(sDate),
			endDate: new Date(),
			params: queryParams,
			pageUrl: '/admin/reports/beds/list',
			records: null,
			csvData: null,
			filters: [
				'startDate',
				'endDate',
				//'title'
			]
		};
		this.fetchCsvData = this.fetchCsvData.bind(this);
		this.csvLink = React.createRef();
		this.updateDate = this.updateDate.bind(this);
  }



	componentDidMount(){
		this.props.change('startDate', this.state.startDate);
		this.props.change('endDate', this.state.endDate);
    this.bedsReport(this.state.params);
		setTimeout(() => {
			$('.react-datepicker__input-container input').keydown(function(e) {
			   e.preventDefault();
			   return false;
			});
		}, 500);

  }

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	bedsReport(params){
		this.setState({fetchingRecords: true});
		params = Object.assign(params, {isCsv: false, startDate: moment(this.state.startDate).format("MM/DD/YYYY"), endDate: moment(this.state.endDate).format("MM/DD/YYYY")});
    this.props.bedsReport(params).then((response) => {
			this.setState({records: response.value.data.data, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			var queryParams = queryString.parse(nextProps.location.search);
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.bedsReport(params);
		}
	}






	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.pageNumber = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?pageNumber=1');
	}

	fetchCsvData(){
		this.setState({generatingCsv: true});
		let params = {startDate: moment(this.state.startDate).format("MM/DD/YYYY"), endDate: moment(this.state.endDate).format("MM/DD/YYYY"), isCsv: true};
    this.props.bedsReport(params).then((response) => {
			this.setState({csvData: response.value.data.data, generatingCsv: false});
			setTimeout((e) => {
				this.csvLink.current.link.click();
			},500);

		}).catch(function(error){
			this.setState({generatingCsv: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	updateDate(e, field){
		this.setState({[field]: e});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
		  <>
				<ol className="breadcrumb breadcrumb-v1 marginB10">
					<li className="breadcrumb-item"><Link to="/admin/reports/beds/list?pageNumber=1">Reports</Link></li>
	        <li className="breadcrumb-item active">Beds</li>
	      </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Beds</h5>
          </div>
					<div className="col-md-8">
            <div className="jobs-act-btns">
							<a className="btn-filter mr-1   hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
							<button onClick = {() => this.fetchCsvData()} className={`${"btn-add ml-1  hvr-shadow"} ${this.state.generatingCsv?"btn-loader":""}`} type = 'button'><span className="icon-ic_plus"></span>Export CSV</button>
							<CSVLink
								headers={this.state.csvData && this.state.csvData.headers?this.state.csvData.headers:[]}
								data={this.state.csvData && this.state.csvData.data?this.state.csvData.data:[]}
								filename={'beds '+moment(this.state.startDate).format('MM-DD-YYYY')+" - "+moment(this.state.endDate).format('MM-DD-YYYY')+'.csv'}
         				className='hidden'
         				ref={this.csvLink}
         				target='_blank'
							/>


            </div>
          </div>


        </div>
				<div className="collapse show" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="startDate"
										component={renderDatePicker}
										label="Start Date"
										maxDate={this.state.endDate}
										callback={(e) => this.updateDate(e, 'startDate')}
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="endDate"
										component={renderDatePicker}
										label="End Date"
										maxDate={new Date()}
										callback={(e) => this.updateDate(e, 'endDate')}
									/>
                </div>
								{/* <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="title"
										component={renderField}
										label="Title"
									/>
								</div> */}
							</div>
              <div className="form-btn-group mt-3 text-right">
                {/* <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button> */}
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>Bed ID</th>
									<th>Booking ID</th>
									<th>Building</th>
									<th>Plan</th>
									<th>Flat #</th>

									<th>Check In</th>
									<th>Check Out</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.beds && records.beds.length > 0 &&
							records.beds.map((obj, index) => {
                return(
									<tr key={obj._id}>
										<td>{obj.bedId}</td>
										<td><a href={"/admin/bookings/list/detail/"+obj.orderId} target = "_blank">{obj.orderId}</a></td>
										<td>{obj.buildingName}</td>
										<td>{obj.planName}</td>
										<td>{obj.flatNo}</td>

										<td>{moment(obj.checkinDate).format("DD MMM, YYYY")}</td>
										<td>{moment(obj.checkoutDate).format("DD MMM, YYYY")}</td>

									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.beds && this.state.records.beds.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.pageNumber}
						totalRecords={records.total}
						pageSize={records.limit}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

BedsReport = reduxForm({
  form: 'BedsReportFilter'
})(BedsReport);

const mapDispatchToProps = (dispatch) => {
  return {
    bedsReport: (data) => {
      return dispatch(bedsReport(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BedsReport));
