import React, {Component} from 'react';
import {Route, Link, withRouter} from 'react-router-dom';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderTextarea from '../../FormFields/renderTextarea';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import {cancelBooking} from '../../../actions/bookings';
import {toast} from 'react-toastify';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.cancelReason || values.cancelReason.trim() === '') {
    errors.cancelReason = "Enter reason for cancellation";
    hasErrors = true;
  }


  return hasErrors && errors;
}



class CancelBookingPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingData: props.bookingData,
      modal: props.openPopup,
    };
    this.toggle = this.toggle.bind(this);
  }


  toggle(){
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }

  componentDidMount(){

  }







  submitForm(values, dispatch, props){
    values = Object.assign(values, {orderId: this.state.bookingData.orderId});
    return this.props.cancelBooking(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      this.props.closePopup();
      if(this.props.callback){
        this.props.callback();
      }

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }





  render() {
    const {bookingData} = {...this.state};
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="sof-ui-block coupon-preview-popup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Cancel Booking (Booking ID: {this.state.bookingData.orderId})</ModalHeader>
        <div className="modal-body">
          <div className="scope-heading">
          </div>
          <div className="card-content-ui">
            <section className="" style={{maxWidth:"672px"}}>
              <form onSubmit={handleSubmit(this.submitForm.bind(this))} >

                    <Field
                      name="cancelReason"
                      component={renderTextarea}
                      type="text"
                      label="Cancellation Reason"
                    />

                <div className="form-btn-group">
                  <button onClick = {this.toggle} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
                  <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Submit</button>&nbsp;
                </div>
              </form>
            </section>
          </div>
        </div>

      </Modal>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {

  };
}


const mapDispatchToProps = (dispatch) => {
  return {
    cancelBooking: (data) => {
      return dispatch(cancelBooking(data));
    }
  }
}

CancelBookingPopup = connect(null, mapDispatchToProps)(CancelBookingPopup);

CancelBookingPopup = reduxForm({
  form: 'CancelBookingPopup',
  validate,
  //enableReinitialize: true
})(CancelBookingPopup);




export default withRouter(connect(null, mapDispatchToProps)(CancelBookingPopup));
