import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchRoles, fetchPermissions, deleteRole} from '../../../actions/users';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';

class RolesList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		this.state = {
			params: queryParams,
			records: null
		};
  }



	componentDidMount(){
		this.fetchRoles(this.state.params);
	}

	fetchRoles(params){
		this.setState({fetchingRecords: true});
    this.props.fetchRoles(params).then((response) => {
			this.setState({records: response.value.data.data, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		/*if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.fetchUsers(params);
		}*/
	}

	deleteRecord(recordid){
    this.props.deleteRole({roleId: recordid}).then((response) => {
			toast.success(response.value.data.message);
      this.fetchRoles();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	render() {
		const {records} = {...this.state}
    return (
			<>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/user-management/users/list">Roles</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Roles</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              {/* <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a> */}
							<Link to="/admin/user-management/roles/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Role</Link>
            </div>
          </div>
        </div>

				<div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Role</th>
									<th>Permissions</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.length > 0 &&
							records.map((obj, index) => {
								return(
									<tr key={obj.roleId}>
										<td>{obj.roleId}</td>
										<td>{obj.name}</td>
										<td>{obj.permission}</td>
										<td>


												<span className="dropdown threeDots">
	                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
	                         	<span></span> <span></span> <span></span>
	                        </a>
	                        <ul className="dropdown-menu" role="menu">
														<li><Link className="dropdown-item" title="Edit" to = {"/admin/user-management/roles/list/edit/"+obj.roleId} >Edit</Link></li>
														<li><Confirm className="confirmPopup"
                                onConfirm={() => this.deleteRecord(obj.roleId)}
                                body="Are you sure you want to delete this?"
                                confirmText="Confirm Delete"
                                title="Delete!">
                                <button className="dropdown-item">Delete</button>
                            </Confirm></li>
													</ul>
	                      </span>

										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>

			</>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: (data) => {
      return dispatch(fetchRoles(data));
    },
		deleteRole: (data) => {
			return dispatch(deleteRole(data));
		}
  }
}


export default withRouter(connect(null, mapDispatchToProps)(RolesList));
