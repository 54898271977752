import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addFlat, fetchBuildings, fetchBlocks, fetchFlat, fetchAvailableFloors, fetchFlatTypes, fetchFlats} from '../../../actions/space_management';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeNumber} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter flat name";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class AddFlat extends Component {
	constructor(props){
    super(props);
    this.state = {
      id: props.match.params.flatId,
      buildingId: props.buildingId?props.buildingId:null,
      flatDetail: null,
      buildings: [],
      blocks: [],
      arrayFloors: [],
      flatTypes: []

    }
    this.fetchBlocks = this.fetchBlocks.bind(this);
    this.callbackFetchBlocks = this.callbackFetchBlocks.bind(this);
    this.manageFloorFlats = this.manageFloorFlats.bind(this);
    this.renderFloors = this.renderFloors.bind(this);
    this.renderFloorFlats = this.renderFloorFlats.bind(this);
    this.fetchFlats = this.fetchFlats.bind(this);
  }

  submitForm(data, dispatch, props){

    return this.props.addFlat(data).then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.props.initialize({});
    this.fetchBuildings();
    this.fetchFlatTypes();
    if(this.state.buildingId){
      this.fetchBlocks({id: this.state.buildingId});
    }
    if(this.state.id){
      this.fetchFlat(this.state.id);
    }
  }


  fetchBuildings(){
		this.setState({fetchingRecords: true});
    this.props.fetchBuildings({isAll: 1}).then((response) => {
      let buildings = response.value.data.data.buildings;
      buildings.forEach((obj, index) => {
        buildings[index] = Object.assign(buildings[index], {label: buildings[index].name+" ("+ buildings[index].locality.name +")"})
      })
      this.setState({fetchingRecords: false, buildings: buildings});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchBlocks(obj){

		this.setState({fetchingRecords: true});
    let data = {buildingId: obj.id, isAll:1};
    this.props.fetchBlocks(data).then((response) => {
      let blocks = response.value.data.data.buildingBlocks;

      this.setState({fetchingRecords: false, blocks: blocks});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  callbackFetchBlocks(obj){
    this.props.change('buildingBlockId', null);
    this.props.change('floors', []);
    this.fetchBlocks(obj);
  }



  fetchFlatTypes(){
		this.setState({fetchingRecords: true});
    let data = {isAll:1};
    this.props.fetchFlatTypes(data).then((response) => {
      let flatTypes = response.value.data.data.flatTypes;
      this.setState({fetchingRecords: false, flatTypes: flatTypes});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  fetchFlat(flatId){
    this.props.fetchFlat({id: flatId}).then((response) => {
      let flatDetail = response.value.data.data;
      this.props.initialize({id: flatDetail.id, number: flatDetail.number, rooms: flatDetail.rooms});
      this.setState({flatDetail});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }

  fetchFlats(obj){

    this.props.fetchFlats({buildingBlockId: obj.id, isAll:1, sortBy: 'floor', sortOrder: 'asc'}).then((response) => {
      let flats = response.value.data.data.flats;
      this.props.change('floors', []);
      let floors = obj.floors.split(',');
      let arrayFloors = floors.map(octet => parseInt(octet, 10));
      let newArray = [];
      arrayFloors.forEach((obj, index) => {
        newArray.push({floor:obj, flats: []});
      });
      let updatedFloorsArray = [];
      newArray.forEach((obj, index) => {
        flats.forEach((obj1, index1) => {
          if(obj.floor == obj1.floor){
            //newArray[index].push({id: obj1.id, floor: obj1.floor, rooms: obj1.rooms, number: obj1.number});
            newArray[index].flats.push({id: obj1.id, floor: obj1.floor, rooms: obj1.rooms, number: obj1.number, flatTypeId: obj1.flatType.id});
          }
        });
      });
      console.log('jjjjj', newArray);
      this.setState({newArray});
      this.props.change('floors', newArray);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});

  }

  manageFloorFlats(obj){
    this.props.change('floors', []);
    let floors = obj.floors.split(',');
    let arrayFloors = floors.map(octet => parseInt(octet, 10));
    let newArray = [];
    arrayFloors.forEach((obj, index) => {
      newArray.push({floor:obj});
    });
    this.setState({newArray});
    this.props.change('floors', newArray);
  }

  renderFloors({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;
    return (
      <div>{
        fields.map((member, index) =>{
          var isVariation = fields.get(index).isVariation;
          //var addOns = fields.get(index).addOns;
          return(
            <fieldset key = {index}>
              <legend>Floor {fields.get(index).floor}</legend>
              <Field name={`${member}.floor`}  type = "hidden" component={"input"}  />
              <FieldArray component = {this.renderFloorFlats} name={`${member}.flats`} />
            </fieldset>
        )}

        )
      }
    </div>)
  }

  renderFloorFlats({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;

    return (
      <div>
      {
        fields.map((member, index) =>{
          return(
            <div className='row' key={index}>
              <div className='col-md-3'>
                <Field name={`${member}.number`}  component={renderField} label={index == 0?"Flat no.":""} />
              </div>
              <div className="col-md-3">
                <Field
                  label={index == 0?"Select flat type":''}
                  name={`${member}.flatTypeId`}
                  optionLabel='name'
                  optionValue='id'
                  options={this.state.flatTypes}
                  component={renderReactSelect}
                  placeholder="Flat type"
                  multi={false}
                />
              </div>
              <div className='col-md-3'>
                <Field name={`${member}.rooms`}   component={renderField} label={index == 0?"No. of rooms":""} normalize = {normalizeNumber}/>
              </div>
              <div className="col-md-3">
                <button style = {{marginTop: index == 0?"25px":0}} type="button" className="btn btn-secondary hvr-shadow" onClick={() => fields.remove(index)}>Remove</button>
              </div>
            </div>
          )}
        )
      }
      <div className='row'>
        <div className='col-md-3'>
          <button type="button" className="btn btn-secondary hvr-shadow" onClick={() => fields.push({floor: fields.get(0) && fields.get(0).floor?fields.get(0).floor:'', rooms: fields.get(0) && fields.get(0).rooms?fields.get(0).rooms:'', number: fields.get(0) && fields.get(0).number?fields.get(0).number:'', flatTypeId: fields.get(0) && fields.get(0).flatTypeId?fields.get(0).flatTypeId:''})}>Add</button>
        </div>
      </div>
      </div>
    )
  }





  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/space-management/flats/list">Flats</Link></li>
          <li className="breadcrumb-item active">{this.state.id?"Edit":"Add"}</li>
        </ol>
        <h5 className="h5 mb-4">{this.state.id?"Edit":"Add"} Flat</h5>
        <section className="formBg" style={{maxWidth:"872px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  label="Select building"
                  name='buildingId'
                  optionLabel='label'
                  optionValue='id'
                  options={this.state.buildings}
                  component={renderReactSelect}
                  placeholder="Select building"
                  parentCallback={this.callbackFetchBlocks}
                  multi={false}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Select block"
                  name='buildingBlockId'
                  optionLabel='name'
                  optionValue='id'
                  options={this.state.blocks}
                  component={renderReactSelect}
                  placeholder="Select block"
                  multi={false}
                  // parentCallback={this.manageFloorFlats}
                  parentCallback={this.fetchFlats}
                />
              </div>
            </div>
            <FieldArray component={this.renderFloors} name="floors"/>
            <div className="form-btn-group">
              <button onClick = {(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFlat: (data) => {
      return dispatch(addFlat(data));
    },
    fetchFlat: (data) => {
      return dispatch(fetchFlat(data));
    },
    fetchBuildings: (data) => {
      return dispatch(fetchBuildings(data));
    },
    fetchBlocks: (data) => {
      return dispatch(fetchBlocks(data));
    },
    fetchFlatTypes: (data) => {
      return dispatch(fetchFlatTypes(data));
    },
    fetchFlats: (data) => {
      return dispatch(fetchFlats(data));
    }

  }
}

AddFlat = connect(null, mapDispatchToProps)(AddFlat);
AddFlat = reduxForm({
  form: 'AddFlat',
  validate,
  //enableReinitialize: true
})(AddFlat);

const selector = formValueSelector('AddFlat');
AddFlat = connect(state => {
  // can select values individually
  const buildingId = selector(state, 'buildingId');
  return {
    buildingId,
  }
})(AddFlat)



export default AddFlat;
